import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { IContentManagerState, IContactInformation, IFaqs } from './ContentManagerModel';
import ApiService from '../../services/ApiService';
import { clientManagerEndpoints } from '../../constants/apiConstants';

const initialState: IContentManagerState = {
  status: {
    updateContactInformation: 'idle',
    getContactInformation: 'idle',
    getFaqs: 'idle',
    updateFaqs: 'idle',
    deleteFaqs: 'idle'
  },
  error: '',
  contactInformation: {} as IContactInformation,
  faqs: [] as IFaqs[],
};

export const fetchContactInformation = createAsyncThunk(
  'ContentManager/GetContactInformation',
  async () => {
    const response = await ApiService.getData(clientManagerEndpoints.getContactInformation, {});
    return response.data;
  },
);

export const fetchFaqs = createAsyncThunk(
  'ContentManager/GetFaqs',
  async () => {
    const response = await ApiService.getData(clientManagerEndpoints.getFaqs, {});
    return response.data;
  },
);

export const saveContactInformation = createAsyncThunk(
  'ContentManager/UpdateContactInformation',
  async (payload: IContactInformation) => {
    const response = await ApiService.postData(clientManagerEndpoints.updateContactInformation, {}, payload);
    return response.data;
  },
);

export const saveFaqs = createAsyncThunk(
  'ContentManager/UpdateFaqs',
  async (payload: IFaqs[]) => {
    const response = await ApiService.postData(clientManagerEndpoints.updateFaqs, {}, payload);
    return response.data;
  },
);

export const deleteFaqs = createAsyncThunk(
  'ContentManager/DeleteFaqs',
  async (payload: IFaqs) => {
    const response = await ApiService.deleteData(clientManagerEndpoints.deleteFaqs, {}, payload);
    return response.data;
  },
);


export const ContentManagerSlice = createSlice({
  name: 'contentManager',
  initialState,
  reducers: {
    updateContactInformation: (state, action) => {
      state.contactInformation = action.payload
    },
    resetStatus: (state) => {
      state.status = {
        ...state.status, ...{
          updateContactInformation: 'idle',
          updateFaqs: 'idle',
          deleteFaqs: 'idle'
        }
      }
    }
  },
  extraReducers(builder) {
    builder
      .addCase(fetchContactInformation.pending, (state) => {
        state.status.getContactInformation = 'loading';
      })
      .addCase(fetchContactInformation.fulfilled, (state, action) => {
        state.status.getContactInformation = 'succeeded';
        state.contactInformation = action.payload.data;
      })
      .addCase(fetchContactInformation.rejected, (state, action) => {
        state.status.getContactInformation = 'failed';
        state.error = action?.error?.message || 'Something went wrong, please try again later';
      })
      .addCase(saveContactInformation.pending, (state) => {
        state.status.updateContactInformation = 'loading';
      })
      .addCase(saveContactInformation.fulfilled, (state) => {
        state.status.updateContactInformation = 'succeeded';
      })
      .addCase(saveContactInformation.rejected, (state, action) => {
        state.status.updateContactInformation = 'failed';
        state.error = action?.error?.message || 'Something went wrong, please try again later';
      })
      .addCase(fetchFaqs.pending, (state) => {
        state.status.getFaqs = 'loading';
      })
      .addCase(fetchFaqs.fulfilled, (state, action) => {
        state.status.getFaqs = 'succeeded';
        state.status.updateFaqs = 'idle';
        state.faqs = action.payload.data;
      })
      .addCase(fetchFaqs.rejected, (state, action) => {
        state.status.getFaqs = 'failed';
        state.error = action?.error?.message || 'Something went wrong, please try again later';
      })
      .addCase(saveFaqs.pending, (state, { meta: { arg } }) => {
        state.status.updateFaqs = `loading__${arg[0].id}`;
      })
      .addCase(saveFaqs.fulfilled, (state, { meta: { arg } }) => {
        state.status.updateFaqs = `succeeded__${arg[0].id}`;
      })
      .addCase(saveFaqs.rejected, (state, { meta: { arg }, error }) => {
        state.status.updateFaqs = `failed__${arg[0].id}`;
        state.error = error?.message || 'Something went wrong, please try again later';
      })
      .addCase(deleteFaqs.pending, (state) => {
        state.status.deleteFaqs = 'loading';
      })
      .addCase(deleteFaqs.fulfilled, (state) => {
        state.status.deleteFaqs = 'succeeded';
      })
      .addCase(deleteFaqs.rejected, (state, action) => {
        state.status.deleteFaqs = 'failed';
        state.error = action?.error?.message || 'Something went wrong, please try again later';
      })
  },
});

export const { updateContactInformation, resetStatus } = ContentManagerSlice.actions;
export default ContentManagerSlice.reducer;