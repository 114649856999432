import React, { useEffect, useState } from 'react';
import { ILink } from '../../types';

import Navbar from '../Nav/Nav';
import { PATHS } from '../../routes/Routes';
import Footer from '../Footer/Footer';

// Styles
import { StyledContainer } from './LayoutStyles';
import { getUserInformation, isUserAuthenticated } from '../../utils/authUtils';
import { useSelector } from 'react-redux';
import { RootState } from '../../redux/Store';
import { IUserDetails } from '../../redux/auth/AuthModels';

interface IProps {
  children: React.ReactNode;
  className?: string;
}

const Layout = ({ children, className = '' }: IProps) => {
  const [userDetails, setUserDetails] = useState<IUserDetails | null>(null);
  const [authStatus, setAuthStatus] = useState<boolean>(false);
  const { accessTokenStatus } = useSelector((state: RootState) => state.authData);

  const headerLinks: ILink[] = [
    {
      label: 'Transactions',
      link: PATHS.TRANSACTIONS,
    },
    {
      label: 'Clients',
      link: PATHS.CLIENTS,
    },
    {
      label: 'People',
      link: PATHS.PEOPLE,
    },
    {
      label: 'Adportal',
      link: PATHS.AD_PORTAL,
    },
    {
      label: 'Content Manager',
      link: PATHS.CLIENT_MANAGER,
    },
  ];

  useEffect(() => {
    setUserDetails(getUserInformation());
    setAuthStatus(!!isUserAuthenticated());
  }, [accessTokenStatus]);

  return (
    <>
      <Navbar
        links={headerLinks}
        isAuthenticated={authStatus}
        user={userDetails}
        productTitle='Client Admin Portal'
      />
      <StyledContainer className={className}>{children}</StyledContainer>
      <Footer></Footer>
    </>
  );
};

export default Layout;
