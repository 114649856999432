import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { Alert, Box, Typography, useTheme } from '@mui/material';
import WarningAmberIcon from '@mui/icons-material/WarningAmber';

import { DATE_FORMAT } from '../../constants/common';
import { PATHS } from '../../routes/Routes';
import { formatAmount, formatDate } from '../../utils/common';
import { RootState, StoreDispatch } from '../../redux/Store';
import {
  cancelClientRecurringPayments,
  fetchClientRecurringPayments,
  fetchPeopleRecurringPayments,
  resetError,
  updateFilters,
} from '../../redux/recurringPayments/RecurringPaymentsSlice';
import { IRecurringPaymentData } from '../../redux/recurringPayments/RecurringPaymentsModel';

import SpectrumTable, { IColumn, Order } from '../../components/Spectrum/Table/SpectrumTable';
import ConfirmationDialog from '../../components/ConfirmationDialog';
import KebabMenu from '../../components/Spectrum/Table/KebabMenu';
import { useFilterHandlers } from '../../utils/customUtilHooks';

const ScheduleAutoPaymentsTable = () => {
  const theme = useTheme();
  const [cancelPayload, setCancelPayload] = useState<{
    recurringPaymentId: number;
    ssoId: string;
  } | null>(null);
  const dispatch: StoreDispatch = useDispatch();
  const {
    cancellingRecurringPaymentStatus,
    data,
    status,
    error,
    filters,
    filters: { sortDirection, sortColumn, length },
  } = useSelector((state: RootState) => state.recurringPaymentsData);
  const { pathname } = useLocation();
  const isClientDetailPage = pathname.search(PATHS.CLIENTS) > -1;

  const handleCancelPayment = () => {
    dispatch(cancelClientRecurringPayments(cancelPayload!));
  };

  const handleClose = () => {
    dispatch(resetError());
    setCancelPayload(null);
  };

  useEffect(() => {
    if (cancellingRecurringPaymentStatus === 'succeeded') {
      setCancelPayload(null);
      dispatch(
        isClientDetailPage
          ? fetchClientRecurringPayments(filters)
          : fetchPeopleRecurringPayments(filters),
      );
    }
  }, [cancellingRecurringPaymentStatus]);

  const columns = [
    {
      label: 'Next Charge',
      sortKey: 'NextCharge',
      accessorKey: 'nextCharge',
      render: (data: IRecurringPaymentData) => formatDate(data.nextCharge, DATE_FORMAT),
    },
    isClientDetailPage && {
      label: 'Paid By',
      sortKey: 'paidByFullName',
      accessorKey: 'paidBy',
      render: (data: IRecurringPaymentData) => {
        return (
          <div>
            <strong>{data.paidByFullName}</strong>
            <Typography color='primary' variant='underline'>
              <strong>@{data.paidBy}</strong>
            </Typography>
          </div>
        );
      },
    },
    {
      label: 'Amount',
      sortKey: 'Amount',
      accessorKey: 'amount',
      render: (data: IRecurringPaymentData) => (
        <div>
          <strong>{formatAmount(data.amount)}</strong>
        </div>
      ),
    },
    {
      label: 'Method',
      sortKey: 'Method',
      accessorKey: 'method',
    },
    {
      label: '',
      sortKey: '',
      accessorKey: '',
      render: (data: IRecurringPaymentData) => (
        <KebabMenu
          options={[
            {
              label: 'Cancel Payment',
              onClick: () =>
                setCancelPayload({
                  recurringPaymentId: data.recurringPaymentId,
                  ssoId: data.ssoId,
                }),
            },
          ]}
        />
      ),
      width: '10px',
    },
  ];
  const updateFiltersAction = (updateFields: Partial<typeof filters>) => {
    dispatch(updateFilters({ ...filters, ...updateFields }));
  };

  const { handleSort, handlePageChange, handleChangeItemsPerPage } = useFilterHandlers<
    typeof filters
  >(filters, updateFiltersAction);

  useEffect(() => {
    if (filters.userId) {
      if (isClientDetailPage) {
        dispatch(fetchClientRecurringPayments(filters));
      } else {
        dispatch(fetchPeopleRecurringPayments(filters));
      }
    }
  }, [filters, pathname]);

  const isLoading = status === 'loading';

  return (
    <>
      {status === 'failed' && (
        <Box mb={theme.spacing(12)}>
          <Alert severity='error' variant='outlined'>
            <Typography variant='paragraph2' component='span' color='error'>
              {error}
            </Typography>
          </Alert>
        </Box>
      )}
      <SpectrumTable
        columns={columns as Array<IColumn>}
        data={data?.data}
        totalItems={data?.totalRecords}
        onSort={handleSort}
        orderBy={sortColumn}
        order={sortDirection as Order}
        itemsPerPage={length}
        currentPage={filters.start / filters.length}
        loading={isLoading}
        onPageChange={handlePageChange}
        onItemsPerPageChange={handleChangeItemsPerPage}
      />
      <ConfirmationDialog
        loading={cancellingRecurringPaymentStatus === 'loading'}
        open={!!cancelPayload?.recurringPaymentId}
        {...(cancellingRecurringPaymentStatus === 'failed'
          ? {
              title: '',
              heading: (
                <Box display='flex' alignItems='center' gap={theme.spacing(4)}>
                  <WarningAmberIcon color='error' fontSize='large' />
                  Autopayment Could Not Be Cancelled
                </Box>
              ),
              subHeading: (
                <>
                  We ran into an issue cancelling this autopayment. For more assistance, please
                  contact:
                  <Typography color='primary' variant='underline'>
                    dl-reach-mediadevelopmentsupport@charter.com
                  </Typography>
                </>
              ),
              cancelButtonText: 'Close',
              confirmButtonText: '',
            }
          : {
              title: 'Cancel Autopayment',
              heading: 'Confirm Cancel Autopayment',
              subHeading:
                'Are you sure you want to cancel this autopayment? If the user would like to resume autopayments in the future, the user will have to schedule a new autopayment from within the Client Portal.',
              confirmButtonText: 'Cancel Autopayment',
              showCancelIcon: true,
            })}
        onClose={handleClose}
        onConfirm={handleCancelPayment}
      />
    </>
  );
};

export default ScheduleAutoPaymentsTable;
