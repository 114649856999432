import { styled } from '@mui/material/styles';
import { ColorPartial } from '@mui/material/styles/createPalette';

export const StyledContainer = styled('div')(({ theme }) => {
  const grey: ColorPartial = theme.palette.grey as ColorPartial;
  return {
    display: 'flex',
    alignItems: 'center',
    background: grey[50],
    width: '100%',
    borderRadius: theme.spacing(12),
    padding: theme.spacing(2, 2),
    border: `1px solid ${grey[400]}`,
    '.link-label': {
      margin: theme.spacing(0, 5),
    },
    '.link-icon': {
      fontSize: '1rem',
    },
  };
});
