import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { RootState, StoreDispatch } from '../../../redux/Store';
import {
  Box,
  Button,
  Card,
  Typography,
  FormControlLabel,
  Radio,
  RadioGroup,
  Divider,
  LinearProgress,
  CircularProgress,
} from '@mui/material';
import {
  ClientTypes,
  ClientSearchTypes,
  ClientSearchPlaceholders,
} from '../../../redux/clients/ClientModel';
import { StyledOutlinedBox } from './LinkUserComponent';
import SpectrumDialog from '../../../components/Spectrum/Dialog/SpectrumDialog';
import SpectrumSearch from '../../../components/Spectrum/Search/SpectrumSearch';
import {
  postFetchAgencies,
  postFetchAdvertisers,
  resetAgenciesList,
  resetAdvertisersList,
  resetAdvertiserError,
  resetAgencyError,
} from '../../../redux/user/UserSlice';
import RenderClientList from './RenderClientList';
import { IReviewUser, IUpdateReviewUserClients } from '../../../redux/user/UserModel';

export interface ISubmitPayloadDetails {
  clientName: string;
  clientId: string;
  clientType: string;
  ssoId: string;
}

interface IClientSelectionModalProps {
  open: boolean;
  handleClose: () => void;
  reviewUserDetails: IReviewUser;
  updatePayload: (payload: ISubmitPayloadDetails) => void;
  payload: IUpdateReviewUserClients;
  confirmUserPayload: ISubmitPayloadDetails;
  setConfirmUserPayload: (payload: ISubmitPayloadDetails) => void;
  setPayload: React.Dispatch<React.SetStateAction<IUpdateReviewUserClients>>;
  isGetSelectedDetailsLoading?: boolean;
  handleEditUserChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
}

const ClientSelectionModal = ({
  open,
  handleClose,
  reviewUserDetails,
  updatePayload,
  payload,
  confirmUserPayload,
  setConfirmUserPayload,
  setPayload,
  isGetSelectedDetailsLoading,
  handleEditUserChange,
}: IClientSelectionModalProps) => {
  const dispatch: StoreDispatch = useDispatch();
  const {
    advertiserList,
    agencyList,
    agencyListError,
    agencyListStatus,
    advertiserListError,
    advertiserListStatus,
    selectedClientDetailsStatus,
  } = useSelector((state: RootState) => state.userData);

  const [selectedClient, setSelectedClient] = useState('');

  useEffect(() => {
    setSelectedClient('');
  }, [open]);

  const [isLoadingSubmit] = useState(false);
  const isLoading = agencyListStatus === 'loading' || advertiserListStatus === 'loading';

  const handleConfirmUser = async () => {
    await updatePayload(confirmUserPayload);
    !handleEditUserChange && handleClose();
  };

  useEffect(() => {
    selectedClientDetailsStatus === 'succeeded' && handleClose();
  }, [selectedClientDetailsStatus]);

  const handleUpdatePayload = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSelectedClient('');
    const { name, value } = event.target;
    setPayload((prevPayload: IUpdateReviewUserClients) => ({ ...prevPayload, [name]: value }));
  };

  const resetAllErrors = () => {
    dispatch(resetAdvertiserError());
    dispatch(resetAgencyError());
  };

  const handleSearchClients = () => {
    resetAllErrors();
    resetAdvertisersList();
    resetAgenciesList();
    if (payload.clientType === ClientTypes.Agency) {
      dispatch(postFetchAgencies(payload));
    } else {
      dispatch(postFetchAdvertisers(payload));
    }
  };

  const renderPlaceholder = () => {
    switch (payload.searchType) {
      case ClientSearchTypes.Id:
        return ClientSearchPlaceholders.Id;
      case ClientSearchTypes.Name:
        return ClientSearchPlaceholders.Name;
      default:
        return ClientSearchPlaceholders.Default;
    }
  };

  const renderError = () => {
    if (agencyListError || advertiserListError) {
      return (
        <Box>
          <Typography variant='body1' mt={15} color='error'>
            {agencyListError || advertiserListError}
          </Typography>
        </Box>
      );
    }
    return null;
  };

  const isShowResultsDisabled = !payload.searchValue || !payload.searchType || !payload.clientType;

  return (
    <Box style={{ zIndex: 99999 }}>
      <SpectrumDialog open={open} onClose={handleClose}>
        <Typography variant='eyebrow'>Link to Client</Typography>
        <Typography variant='h1' mb={12}>
          Client Search
        </Typography>
        {isLoading && (
          <Box sx={{ width: '100%' }}>
            <LinearProgress />
          </Box>
        )}
        <StyledOutlinedBox display={'flex'} flexDirection={'column'}>
          <Box display={'flex'}>
            <Box width={'30%'}>
              <Typography variant='h6' mb={6}>
                Client Type *
              </Typography>
              <RadioGroup
                aria-label='clientType'
                name='clientType'
                value={payload.clientType}
                onChange={(event) => {
                  handleUpdatePayload(event);
                }}
              >
                <FormControlLabel value='Agency' control={<Radio />} label='Agency' />
                <FormControlLabel value='Advertiser' control={<Radio />} label='Advertiser' />
              </RadioGroup>
            </Box>
            <Box sx={{ width: '100%' }}>
              <Typography variant='h6' mb={6}>
                Search by *
              </Typography>
              <RadioGroup
                aria-label='searchType'
                name='searchType'
                value={payload.searchType}
                onChange={(event) => {
                  handleUpdatePayload(event);
                }}
                sx={{ display: 'flex', flexDirection: 'row' }}
              >
                <FormControlLabel
                  value={ClientSearchTypes.Id}
                  control={<Radio />}
                  label='Client ID'
                />
                <FormControlLabel
                  value={ClientSearchTypes.Name}
                  control={<Radio />}
                  label='Client Name'
                />
              </RadioGroup>
              <SpectrumSearch
                placeholder={renderPlaceholder()}
                sx={{ width: '100%' }}
                size='small'
                name='searchValue'
                value={payload.searchValue}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                  handleUpdatePayload(event);
                }}
              />
            </Box>
          </Box>
          <Box>
            <Divider sx={{ marginTop: 15 }} />
            <Button
              variant='contained'
              color='primary'
              sx={{ mt: 10, borderRadius: '5px' }}
              disabled={isShowResultsDisabled}
              onClick={handleSearchClients}
            >
              <Typography variant='body1' color='white'>
                Show Results
              </Typography>
            </Button>
          </Box>
        </StyledOutlinedBox>
        {renderError()}
        {payload.clientType === ClientTypes.Agency && agencyList && agencyList.length > 0 && (
          <Box style={{ maxHeight: '400px', overflow: 'auto' }}>
            <Box>
              <Typography variant='body2' mb={6} mt={15} sx={{ fontStyle: 'italic' }}>
                Showing {agencyList?.length} {agencyList?.length > 1 ? 'Agencies' : 'Agency'}
              </Typography>
            </Box>
            <RenderClientList
              type={ClientTypes.Agency}
              clientList={agencyList}
              selectedClient={selectedClient}
              setSelectedClient={setSelectedClient}
              payload={payload}
              setConfirmUserPayload={setConfirmUserPayload}
              confirmUserPayload={confirmUserPayload}
              reviewUserDetails={reviewUserDetails}
              handleEditUserChange={handleEditUserChange}
            />
          </Box>
        )}
        {payload.clientType === ClientTypes.Advertiser &&
          advertiserList &&
          advertiserList.length > 0 && (
            <Box style={{ maxHeight: '400px', overflow: 'auto' }}>
              <Box>
                <Typography variant='body2' mb={6} mt={15} sx={{ fontStyle: 'italic' }}>
                  Showing {advertiserList?.length}{' '}
                  {advertiserList?.length > 1 ? 'Advertisers' : 'Advertiser'}
                </Typography>
              </Box>
              <RenderClientList
                type={ClientTypes.Advertiser}
                clientList={advertiserList}
                selectedClient={selectedClient}
                setSelectedClient={setSelectedClient}
                payload={payload}
                setConfirmUserPayload={setConfirmUserPayload}
                confirmUserPayload={confirmUserPayload}
                reviewUserDetails={reviewUserDetails}
                handleEditUserChange={handleEditUserChange}
              />
            </Box>
          )}
        {isLoadingSubmit && (
          <Box sx={{ width: '100%' }}>
            <LinearProgress />
          </Box>
        )}
        {(agencyList?.length > 0 || advertiserList?.length > 0) && (
          <Button
            type='submit'
            variant='contained'
            color='primary'
            sx={{ mt: 10, borderRadius: '5px' }}
            onClick={handleConfirmUser}
            disabled={!selectedClient || isLoadingSubmit}
          >
            <Typography variant='body1' color='white'>
              Select Client
            </Typography>
            {isGetSelectedDetailsLoading && (
              <>
                <Box ml={5}>
                  <CircularProgress size={24} color='inherit' />
                </Box>
              </>
            )}
          </Button>
        )}
      </SpectrumDialog>
    </Box>
  );
};

export default ClientSelectionModal;
