import { IInvoiceOrderDetails, IReportDownloadPayload } from '../transactions/TransactionModel';
import { IReportUser } from '../user/UserModel';

export enum ClientTypes {
  Agency = 'Agency',
  Advertiser = 'Advertiser',
}

export enum ClientSearchTypes {
  Id = 'I',
  Name = 'N',
}

export enum ClientSearchPlaceholders {
  Id = 'Search by Client ID',
  Name = 'Search by Client Name',
  Default = 'Search by ....',
}

export const ClientOptions = [
  {
    label: 'Agency',
    value: ClientTypes.Agency,
  },
  {
    label: 'Advertiser',
    value: ClientTypes.Advertiser,
  },
];

export interface IClient {
  accountOwner: string;
  accountOwnerUserName: string;
  client: string;
  clientId: string;
  status: string;
  userId: number;
  ssoId: string;
  companyId: number;
  companyType: string;
  createdDate: string;
}

export interface IClientResponse {
  filteredRecords: number;
  totalRecords: number;
  data: IClient[];
}

export interface IClientState {
  status: string;
  data: null | IClientResponse;
  error: any;
  addClientStatus: string;
  addClientError: any;
  filters: IFilterPayload;
  clientStatusList: IClientStatus[];
  notesStatus: string;
  notesError: any;
  //Client Details
  clientDetails: null | IClientDetails;
  clientDetailsStatus: string;
  clientDetailsError: string;
  //New Account Owner
  newAccountOwner: null | IReportUser[];
  newAccountOwnerStatus: string;
  newAccountOwnerError: string;
  //Set new Account Owner
  setNewAccountOwnerError: string;
  setNewAccountOwnerStatus: string;
}

export interface IFilterPayload {
  start: number;
  length: number;
  sortColumn: string;
  sortDirection: string;
  searchValue: string;
  status?: number;
}

export interface IClientStatus {
  id: number;
  name: string;
}

export interface IClientTransactions {
  date: string;
  PaidBy: string;
  PaidFullName: string;
  PaymentID: string;
  SsoId: string;
  ClientId: string;
  ClientName: string;
  Amount: number;
  Type: string;
  Status: string;
  errorMessage: null;
  paymentType: string;
  invoiceOrderDetails: IInvoiceOrderDetails;
  recurringPaymentId: null;
  description: string;
}

export interface IAddUser {
  companyId: number;
  companyType: string;
  email: string;
}

export interface IPaymentTransactionReportDownloadPayload extends IReportDownloadPayload {
  ssoId: string;
}

export interface IClientDetails {
  id: number;
  client: string;
  clientId: number;
  userId: number;
  ssoId: string;
  accountOwner: string;
  accountOwnerUserName: string;
  accountOwnerEmail: string;
  accountOwnerPhone: string;
  status: string;
  role: string;
  createdDate: string;
  clientNotes: string;
  clientType: string;
}
export interface IAPIResponseError {
  success: boolean;
  statusCode: number;
  data: any;
  error: string;
  errors: string[];
  title: string;
}
