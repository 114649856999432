export const DATE_FORMAT = 'MM/dd/yyyy';
export const FILTER_DATE_FORMAT = 'yyyy-MM-dd';
export const SARF = 'http://sarf.corp.chartercom.com/';
export enum PAYMENT_TYPES {
  'Recurring' = 'Recurring',
}
export enum USER_STATUS {
  Inactive = 'Inactive',
  Active = 'Active',
}
