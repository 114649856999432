import React, { useState } from 'react';
import { Close, Edit } from '@mui/icons-material';
import { ColorPartial } from '@mui/material/styles/createPalette';
import { Alert, Box, Button, Card, TextField, Typography, useTheme } from '@mui/material';
import { isValidEmail, isValidPhoneNumber } from '../../utils/validation';
import { formatPhoneNumber } from '../../utils/common';
import { RootState, StoreDispatch } from '../../redux/Store';
import { useDispatch, useSelector } from 'react-redux';
import { saveContactInformation } from '../../redux/contentManager/ContentManagerSlice';
import { ComponentLoader } from '../../components/Loaders/Loader';
import { IContactInformation } from '../../redux/contentManager/ContentManagerModel';

const ContactInformation = () => {
  const theme = useTheme();
  const black: ColorPartial = theme.palette.black as ColorPartial;
  const dispatch: StoreDispatch = useDispatch();
  const { status, error, contactInformation } = useSelector(
    (state: RootState) => state.contentManager,
  );
  const [newForm, setNewForm] = useState<IContactInformation>({} as IContactInformation);
  const [isEditable, setIsEditable] = useState(false);
  const [errorStatus, setErrorStatus] = useState({
    email: false,
    phoneNumber: false,
    availabilityText: false,
  });

  const handleEdit = () => {
    setNewForm(contactInformation as IContactInformation);
    setIsEditable((prvState) => !prvState);
  };
  const handleCancel = () => {
    setIsEditable((prvState) => !prvState);
  };

  const handleSave = () => {
    const localErrorState: { email: boolean; phoneNumber: boolean } = {
      email: false,
      phoneNumber: false,
    };

    localErrorState.email = !isValidEmail(newForm?.email ?? '');
    localErrorState.phoneNumber = !isValidPhoneNumber(newForm?.phoneNumber ?? '');
    setErrorStatus((prvState) => ({ ...prvState, ...localErrorState }));

    if (!localErrorState.email && !localErrorState.phoneNumber) {
      setIsEditable((prvState) => !prvState);
      const payload = { ...newForm } as IContactInformation;
      payload.phoneNumber = payload.phoneNumber?.replaceAll?.('-', '');
      dispatch(saveContactInformation(payload));
    }
  };

  const handleFormChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    setNewForm({
      ...newForm,
      [name]: value,
    });
  };

  const renderEditableElement = () => (
    <>
      <TextField
        sx={{ mt: 8 }}
        type='email'
        label='Email Address'
        value={newForm?.email}
        onChange={handleFormChange}
        helperText={errorStatus.email ? 'Please enter a valid email id' : ''}
        error={errorStatus.email}
        name='email'
        fullWidth
      />
      <TextField
        sx={{ mt: 8 }}
        label='Phone Number'
        value={newForm?.phoneNumber ?? ''}
        onChange={handleFormChange}
        helperText={errorStatus.phoneNumber ? 'Please enter a valid phone number' : ''}
        error={errorStatus.phoneNumber}
        name='phoneNumber'
        fullWidth
      />
      <TextField
        sx={{ mt: 8 }}
        label='Availability Text'
        value={newForm?.availabilityText}
        onChange={handleFormChange}
        error={errorStatus.availabilityText}
        name='availabilityText'
        multiline
        rows={4}
        fullWidth
      />
    </>
  );

  const renderNonEditableElement = () => (
    <>
      <Box display='flex' flexDirection='column' gap={5} mt={8}>
        <Typography variant='body2' sx={{ color: black[50] }}>
          Email Address
        </Typography>
        <Typography variant='body1'>{contactInformation?.email}</Typography>
      </Box>
      <Box display='flex' flexDirection='column' gap={5} mt={8}>
        <Typography variant='body2' sx={{ color: black[50] }}>
          Phone Number
        </Typography>
        <Typography variant='body1'>
          {formatPhoneNumber(contactInformation?.phoneNumber ?? '')}
        </Typography>
      </Box>
      <Box display='flex' flexDirection='column' gap={5} mt={8}>
        <Typography variant='body2' sx={{ color: black[50] }}>
          Availability Text
        </Typography>
        <Typography variant='body1'>
          <pre style={{ fontFamily: 'inherit' }}>{contactInformation?.availabilityText}</pre>
        </Typography>
      </Box>
    </>
  );

  return (
    <>
      <Card
        variant='outlined'
        sx={{
          p: 8,
          mt: 10,
          display: 'flex',
          flexDirection: 'column',
          boxShadow: theme.shadows[2],
        }}
      >
        <Typography variant='h2'>Contact Information</Typography>
        {(status.updateContactInformation === 'failed' ||
          status.getContactInformation === 'failed') && (
          <Box mt={theme.spacing(6)}>
            <Alert severity='error' variant='outlined'>
              <Typography variant='paragraph2' component='span' color='error'>
                {error}
              </Typography>
            </Alert>
          </Box>
        )}
        {status.getContactInformation === 'loading' && <ComponentLoader />}
        {status.getContactInformation === 'succeeded' && (
          <Card
            variant='outlined'
            sx={{
              mt: 10,
              p: 12,
              boxShadow: theme.shadows[2],
            }}
          >
            {status.updateContactInformation === 'loading' ? (
              <ComponentLoader />
            ) : (
              <>
                {isEditable ? renderEditableElement() : renderNonEditableElement()}
                <Box display='flex' gap={theme.spacing(4)}>
                  <Button
                    variant={isEditable ? 'contained' : 'outlined'}
                    startIcon={!isEditable && <Edit />}
                    sx={{ borderRadius: theme.spacing(2), mt: 8 }}
                    onClick={isEditable ? handleSave : handleEdit}
                  >
                    {isEditable ? 'Save' : 'Edit'}
                  </Button>
                  {isEditable && (
                    <Button
                      variant='text'
                      startIcon={<Close />}
                      sx={{ borderRadius: theme.spacing(2), mt: 8, color: black[50] }}
                      onClick={handleCancel}
                    >
                      Cancel
                    </Button>
                  )}
                </Box>
              </>
            )}
          </Card>
        )}
      </Card>
    </>
  );
};

export default ContactInformation;
