import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Alert, Box, Button, Card, Grid, Typography, useTheme } from '@mui/material';
import { Check, Close, Edit, Refresh } from '@mui/icons-material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

import { RootState, StoreDispatch } from '../../redux/Store';
import { updateFilters as updateRecurringPaymentsFilters } from '../../redux/recurringPayments/RecurringPaymentsSlice';
import { updateFilters as updateTransactionsFilters } from '../../redux/transactions/TransactionsSlice';
import { fetchRegisteredUserDetails } from '../../redux/user/UserSlice';
import { formatDate, scrollToTop } from '../../utils/common';
import { DATE_FORMAT, USER_STATUS } from '../../constants/common';

import PageContentContainer from '../../components/PageContentContainer/PageContentContainer';
import StatusCell from '../../components/Spectrum/Table/StatusCell';
import { PageLoader } from '../../components/Loaders/Loader';
import BasicDetails from './BasicDetails';
import ScheduleAutoPayments from '../ScheduleAutoPayments/ScheduleAutoPayments';
import TransactionsHistory from './TransactionHistory';
import EditDetails from './EditDetails/EditDetails';
import { PeopleTableActions } from '../../redux/user/UserModel';
import ResetPassword from '../People/ResetPassword';
import SpectrumDialog from '../../components/Spectrum/Dialog/SpectrumDialog';
import ActivateDeactivateUser from '../People/ActivateDeactivateUser';

const PeopleDetails = () => {
  const theme = useTheme();
  const navigate = useNavigate();
  const dispatch: StoreDispatch = useDispatch();

  const [isEditMode, setIsEditMode] = useState(false);

  const [actionType, selectedActionType] = useState<null | PeopleTableActions>(null);

  const { userDetails, userDetailsStatus, userDetailsStatusError } = useSelector(
    (state: RootState) => state.userData,
  );

  const { userId } = useParams();

  useEffect(() => {
    scrollToTop();
  }, []);

  useEffect(() => {
    if (userId) {
      dispatch(fetchRegisteredUserDetails(userId));
      dispatch(
        updateRecurringPaymentsFilters({
          start: 0,
          length: 10,
          sortColumn: '',
          sortDirection: '',
          searchValue: '',
          userId,
        }),
      );
      dispatch(
        updateTransactionsFilters({
          start: 0,
          length: 10,
          sortColumn: '',
          sortDirection: '',
          searchValue: '',
          userId,
          paymentStatus: 0,
        }),
      );
    }
  }, [userId]);

  const handleEdit = () => {
    setIsEditMode(true);
  };

  const handleDialogClose = () => {
    selectedActionType(null);
  };
  const handleResetPassword = () => {
    selectedActionType(PeopleTableActions.RESET_PASSWORD);
  };
  const handleActivateDeactivateUser = () => {
    selectedActionType(PeopleTableActions.ACTIVATE_DEACTIVATE_USER);
  };

  const displayError = (error: string) => (
    <Box my={theme.spacing(6)}>
      <Alert severity='error' variant='outlined'>
        <Typography variant='paragraph2' component='span' color='error'>
          {error}
        </Typography>
      </Alert>
    </Box>
  );

  const isActive = userDetails?.status === USER_STATUS.Active;
  const isLoading = userDetailsStatus === 'loading';

  return (
    <PageContentContainer>
      <Button variant='text' onClick={() => navigate(-1)} startIcon={<ArrowBackIcon />}>
        Back to Users
      </Button>
      <Card variant='outlined' sx={{ p: theme.spacing(12), mt: theme.spacing(8) }}>
        {isLoading && <PageLoader />}
        {userDetailsStatus === 'failed' && displayError(userDetailsStatusError)}
        {userDetails && !isLoading && (
          <>
            <Box display='flex' justifyContent='space-between'>
              <Box>
                <Typography variant='h1'>
                  {userDetails?.firstName} {userDetails?.lastName}
                </Typography>
                <Box display='flex' gap={theme.spacing(4)}>
                  {userDetails?.userName && (
                    <Typography variant='paragraph2'>@{userDetails.userName}</Typography>
                  )}
                  {userDetails?.clientId && (
                    <Typography variant='paragraph2'>(#{userDetails.clientId})</Typography>
                  )}
                </Box>
              </Box>
              <Box
                display='flex'
                flexDirection='column'
                gap={theme.spacing(8)}
                alignItems='flex-end'
              >
                <StatusCell status={userDetails.status} />
                {userDetails.createdDate && (
                  <Typography fontSize='0.7rem' fontStyle='italic'>
                    Created {formatDate(userDetails.createdDate, DATE_FORMAT)}
                  </Typography>
                )}
              </Box>
            </Box>
            <Box mt={theme.spacing(12)}>
              {isEditMode ? (
                <EditDetails userDetails={userDetails} setIsEditMode={setIsEditMode} />
              ) : (
                <>
                  <Grid container spacing={12} mt={theme.spacing(12)}>
                    <Grid item laptop={10}>
                      <BasicDetails userDetails={userDetails} />
                    </Grid>
                    <Grid item laptop={2}>
                      <Box display='flex' flexDirection='column' gap={theme.spacing(4)}>
                        <Button
                          variant='outlined'
                          startIcon={<Edit />}
                          sx={{ borderRadius: theme.spacing(2) }}
                          onClick={handleEdit}
                          size='small'
                          disabled={!isActive}
                        >
                          Edit User Details
                        </Button>
                        <Button
                          variant='outlined'
                          startIcon={<Refresh />}
                          size='small'
                          sx={{ borderRadius: theme.spacing(2) }}
                          onClick={handleResetPassword}
                          disabled={!isActive}
                        >
                          Reset Password
                        </Button>
                        <Button
                          variant='outlined'
                          size='small'
                          startIcon={isActive ? <Close /> : <Check />}
                          sx={{ borderRadius: theme.spacing(2) }}
                          onClick={handleActivateDeactivateUser}
                        >
                          {isActive ? 'Deactivate User' : 'Activate User'}
                        </Button>
                      </Box>
                    </Grid>
                  </Grid>
                </>
              )}
            </Box>
          </>
        )}
        <ScheduleAutoPayments />
        <TransactionsHistory />
      </Card>
      <SpectrumDialog
        open={actionType === PeopleTableActions.RESET_PASSWORD}
        onClose={handleDialogClose}
      >
        <ResetPassword userSsoId={userDetails?.ssoId || ''} onClose={handleDialogClose} />
      </SpectrumDialog>
      <SpectrumDialog
        open={actionType === PeopleTableActions.ACTIVATE_DEACTIVATE_USER}
        onClose={handleDialogClose}
      >
        <ActivateDeactivateUser
          isActive={isActive}
          userSsoId={userDetails?.ssoId || ''}
          onClose={handleDialogClose}
        />
      </SpectrumDialog>
    </PageContentContainer>
  );
};

export default PeopleDetails;
