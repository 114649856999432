import React, { useState } from 'react';
import { IconButton, Menu, MenuItem } from '@mui/material';
import MoreVertIcon from '@mui/icons-material/MoreVert';

export interface IOption {
  label: string
  onClick?: () => void
}

export default function KebabMenu({ options }: { options: IOption[] }) {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <IconButton
        aria-label='more'
        id='table-menu-button'
        aria-controls={open ? 'table-menu' : undefined}
        aria-expanded={open ? 'true' : undefined}
        aria-haspopup='true'
        onClick={handleClick}
      >
        <MoreVertIcon />
      </IconButton>
      <Menu
        id='table-menu'
        MenuListProps={{
          'aria-labelledby': 'table-menu-button',
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
      >
        {options.map((option: IOption) => (
          <MenuItem key={option.label} onClick={() => {
            option.onClick?.()
            handleClose()
          }}>
            {option.label}
          </MenuItem>
        ))}
      </Menu>
    </>
  );
}
