import React from 'react';
import { StyledOutlinedBox } from './LinkUserComponent';
import { Box, Typography } from '@mui/material';
import LinkIcon from '@mui/icons-material/Link';

interface IRenderClientProps {
  disabled?: boolean;
  unlinkCallback: () => void;
  clientName: string;
  clientId: string;
}

const renderAssocaitedClient = ({ disabled, unlinkCallback, clientName, clientId }: IRenderClientProps) => {
  return (
    <StyledOutlinedBox>
      <Box
        display={'flex'}
        justifyContent={'flex-start'}
        alignItems={'start'}
        flexDirection={'column'}
      >
        <Typography variant='h5'>{clientName}</Typography>
        <Typography variant='subtitle2'>#{clientId}</Typography>
        <Box
          display={'flex'}
          flexDirection={'row'}
          alignItems={'center'}
          mt={5}
          style={{ cursor: disabled ? 'default' : 'pointer' }}
          onClick={disabled ? undefined : unlinkCallback}
        >
          <LinkIcon style={{ fontSize: 16 }} color={disabled ? 'disabled' : 'primary'} />
          <Typography variant='subtitle2' color={disabled ? 'text.disabled' : 'primary'} pl={5}>
            Unlink Client
          </Typography>
        </Box>
      </Box>
    </StyledOutlinedBox>
  );
};

export default renderAssocaitedClient;
