import React from 'react';
import { Button, useTheme } from '@mui/material';
import ApiService from '../services/ApiService';
import { downloadFile } from '../utils/common';

interface IExport {
  label: string;
  endpoint: string;
  filters: any;
  filename: string;
}

export default function Export({ label, endpoint, filters, filename }: IExport) {
  const theme = useTheme()

  const handleExport = async () => {
    const response = await ApiService.postData(endpoint, {}, filters);
    downloadFile(response.data, `${filename}`, 'csv');
  };

  return (
    <Button
      variant='contained'
      sx={{
        borderRadius: 1,
        px: theme.spacing(12)
      }}
      onClick={handleExport}
    >
      {label}
    </Button>
  );
}
