import React, { useEffect, useRef } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { format } from 'date-fns';
import { Alert, Box, Typography, useTheme } from '@mui/material';

import { RootState, StoreDispatch } from '../../redux/Store';
import {
  fetchAdPortalTransactions,
  updateFilters,
} from '../../redux/adPortalTransaction/AdPortalTransactionSlice';
import {
  IAdPortalTransactions,
  IFilterPayload,
} from '../../redux/adPortalTransaction/AdPortalTransactionModel';

import { DATE_FORMAT, FILTER_DATE_FORMAT } from '../../constants/common';
import { formatAmount, formatStringToDate, formatDate } from '../../utils/common';

import SpectrumTable, { Order } from '../../components/Spectrum/Table/SpectrumTable';
import StatusCell from '../../components/Spectrum/Table/StatusCell';
import { useGetCurrentFilters } from '../../utils/customUtilHooks';

const AdPortalTransactions = () => {
  const theme = useTheme();
  const history = useNavigate();
  const [searchParams] = useSearchParams();
  const isInitialMount = useRef(true);

  const {
    data,
    status,
    error,
    filters,
    filters: { sortDirection, sortColumn, start, length },
  } = useSelector((state: RootState) => state.adPortalTransactionsData);

  const dispatch: StoreDispatch = useDispatch();

  useEffect(() => {
    const currentFiltersData = useGetCurrentFilters(searchParams, filters, formatStringToDate);
    // update to store
    dispatch(updateFilters(currentFiltersData));
  }, []);

  useEffect(() => {
    if (isInitialMount.current) {
      isInitialMount.current = false;
      return;
    }
    const updatedFilters: { [x: string]: any } = {
      ...filters,
      startDate: filters?.startDate ? formatDate(filters?.startDate, FILTER_DATE_FORMAT) : null,
      endDate: filters?.endDate ? formatDate(filters?.endDate, FILTER_DATE_FORMAT) : null,
    };
    // Setting applied values to URL
    const params = new URLSearchParams();
    Object.keys(updatedFilters).forEach((filterKey) => {
      if (updatedFilters[filterKey]) {
        params.append(filterKey, updatedFilters[filterKey]);
      }
    });
    history({ search: params.toString() }, { replace: true });
    dispatch(fetchAdPortalTransactions(updatedFilters as IFilterPayload));
  }, [filters, isInitialMount.current]);

  const handleChangeItemsPerPage = (items: number) => {
    dispatch(updateFilters({ ...filters, start: 0, length: items }));
  };
  const handlePageChange = (pageNo: number) => {
    dispatch(updateFilters({ ...filters, start: pageNo * filters.length }));
  };

  const columns = [
    {
      label: 'Payment ID',
      sortKey: 'PaymentId',
      accessorKey: 'paymentId',
      width: '30%',
    },
    {
      label: 'First Name',
      sortKey: 'FirstName',
      accessorKey: 'firstName',
      width: '30px',
    },
    {
      label: 'Last Name',
      sortKey: 'LastName',
      accessorKey: 'lastName',
      width: '30px',
    },
    {
      label: 'Company',
      sortKey: 'Company',
      accessorKey: 'company',
      render: (data: IAdPortalTransactions) => <strong>{data.company}</strong>,
      width: '30px',
    },
    {
      label: 'Invoice',
      sortKey: 'InvoiceNumber',
      accessorKey: 'invoiceNumber',
      width: '30px',
    },
    {
      label: 'Payment Date',
      sortKey: 'PaymentDate',
      accessorKey: 'paymentDate',
      render: (data: IAdPortalTransactions) => format(new Date(data.paymentDate), DATE_FORMAT),
      width: '30px',
    },
    {
      label: 'Amount',
      sortKey: 'Amount',
      accessorKey: 'amount',
      render: (data: IAdPortalTransactions) => <strong>{formatAmount(data.amount)}</strong>,
      width: '30px',
    },
    {
      label: 'Status',
      sortKey: 'Status',
      accessorKey: 'status',
      render: (data: IAdPortalTransactions) => <StatusCell status={data.status} />,
      width: '30px',
    },
    {
      label: 'Address',
      sortKey: 'Address',
      accessorKey: 'address',
      width: '20px',
    },
  ];

  const handleSort = (_: React.MouseEvent<unknown>, property: string) => {
    const isAsc = sortColumn === property && sortDirection === 'asc';
    const changedSortDirection = isAsc ? 'desc' : 'asc';
    dispatch(
      updateFilters({ ...filters, sortDirection: changedSortDirection, sortColumn: property }),
    );
  };

  return (
    <>
      {status === 'failed' && (
        <Box mb={theme.spacing(12)}>
          <Alert severity='error' variant='outlined'>
            <Typography variant='paragraph2' component='span' color='error'>
              {error}
            </Typography>
          </Alert>
        </Box>
      )}
      <SpectrumTable
        columns={columns}
        data={data?.data}
        currentPage={start / length}
        itemsPerPage={length}
        loading={status === 'loading'}
        totalItems={data?.totalRecords ?? 0}
        order={sortDirection as Order}
        orderBy={sortColumn}
        onSort={handleSort}
        onItemsPerPageChange={handleChangeItemsPerPage}
        onPageChange={handlePageChange}
      />
    </>
  );
};

export default AdPortalTransactions;
