import { addSeconds, differenceInMilliseconds } from 'date-fns';
import { ITokenResponse, IUserDetails } from '../redux/auth/AuthModels';
import { authApiEndPoints } from '../constants/apiConstants';

export const constants = {
  ACCESS_TOKEN: 'accessToken',
  REFRESH_TOKEN: 'refreshToken',
  EXPIRY_TIME: 'expiryDateTime',
  ID_TOKEN: 'idToken',
};
export const cognitoGroup = {
  GROUP: 'ClientAdminPortal',
};

export const getAuthItem = (key: string): string => window.localStorage.getItem(key) || '';
export const setAuthItem = (key: string, value: any) => window.localStorage.setItem(key, value);
export const deleteAuthItem = (key: string) => window.localStorage.removeItem(key);

/** Save Access and Refresh Token */
export const saveToken = (data: ITokenResponse) => {
  setAuthItem(constants.ACCESS_TOKEN, data.access_token);
  setAuthItem(constants.ID_TOKEN, data.id_token);
  if (data.refresh_token) {
    setAuthItem(constants.REFRESH_TOKEN, data.refresh_token);
  }
  const expiryTime = addSeconds(new Date(), data.expires_in);
  setAuthItem(constants.EXPIRY_TIME, expiryTime);
};

/** Remove Tokens */
export const removeTokens = () => {
  deleteAuthItem(constants.ACCESS_TOKEN);
  deleteAuthItem(constants.REFRESH_TOKEN);
  deleteAuthItem(constants.ID_TOKEN);
  deleteAuthItem(constants.EXPIRY_TIME);
};

/** check token expired or not * */
export const isTokenExpired = () => {
  const endDate = new Date(getAuthItem(constants.EXPIRY_TIME));
  const currentDate = new Date();
  return differenceInMilliseconds(endDate, currentDate) < 0;
};

/** Is user authenticated */
export const isUserAuthenticated = () => getAuthItem(constants.ACCESS_TOKEN) && !isTokenExpired();
export const isUserHasAccess = () => {
  const accessToken = getAuthItem(constants.ACCESS_TOKEN);
  if (accessToken) {
    const decodedToken = decodeToken(accessToken);
    if (decodedToken['cognito:groups']?.includes(cognitoGroup.GROUP)) {
      return true;
    }
  }
  return false;
};

// Get Code from URL
export const getCodeFromUrl = (): string | null => {
  const urlParams = new URLSearchParams(window.location.search);
  return urlParams.get('code');
};
// Redirect to Auth Page
export const redirectToAuthPage = (): void => {
  window.location.href = authApiEndPoints.loginURL;
};
// Decode Token
export const decodeToken = (token: string) => {
  return JSON.parse(atob(token.split('.')[1]));
};
export const getUserInformation = (): IUserDetails | null => {
  const idToken = getAuthItem(constants.ID_TOKEN);
  if (idToken) {
    const decodedIdToken = decodeToken(idToken);
    if (decodedIdToken) {
      return {
        firstName: decodedIdToken.name,
        lastName: decodedIdToken.family_name,
      };
    }
  }
  return null;
};
