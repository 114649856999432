import { styled } from '@mui/material/styles';
import { ColorPartial } from '@mui/material/styles/createPalette';

export const StyledFooter = styled('footer')(({ theme }) => {
  const black: ColorPartial = theme.palette.black as ColorPartial;
  return {
    height: theme.spacing(38),
    display: 'flex',
    alignItems: 'center',
    '.info-text': {
      color: black[50],
      padding: theme.spacing(0, 16)
    },
  };
});
