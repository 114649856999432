import React, { useEffect, useState } from 'react';
import { Box, Button, Card, TextField, useTheme } from '@mui/material';
import { Add, Close } from '@mui/icons-material';
import { IFaqs } from '../../redux/contentManager/ContentManagerModel';
import { ColorPartial } from '@mui/material/styles/createPalette';
import { saveFaqs } from '../../redux/contentManager/ContentManagerSlice';
import { RootState, StoreDispatch } from '../../redux/Store';
import { useDispatch, useSelector } from 'react-redux';
import { ComponentLoader } from '../../components/Loaders/Loader';

const AddNewQuestion = ({
  isHighlightBox = false,
  priority = 0,
}: {
  isHighlightBox: boolean;
  priority: number;
}) => {
  const dispatch: StoreDispatch = useDispatch();
  const theme = useTheme();
  const black: ColorPartial = theme.palette.black as ColorPartial;
  const [newForm, setNewForm] = useState<IFaqs>({
    question: '',
    answer: '',
    id: 0,
    isFaq: false,
    priority: 0,
  });
  const [addQuestion, setAddQuestion] = useState<boolean>(false);
  const { status } = useSelector((state: RootState) => state.contentManager);
  const isLoading = status.updateFaqs === 'loading__0';
  const handelNewFormSave = () => {
    dispatch(
      saveFaqs([
        {
          ...newForm,
          isFaq: !isHighlightBox,
          priority: isHighlightBox ? 0 : priority + 1,
        },
      ]),
    );
  };

  const handleNewFormRemove = () => {
    setNewForm((prv) => ({ ...prv, question: '', answer: '' }));
    setAddQuestion(false);
  };

  const handleNewFormChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    setNewForm((prv) => ({ ...prv, [name]: value }));
  };
  const handleAdd = () => {
    setAddQuestion(true);
  };
  useEffect(() => {
    if (!isLoading) {
      setNewForm((prv) => ({ ...prv, question: '', answer: '' }));
      setAddQuestion(false);
    }
  }, [isLoading]);

  if (isLoading) {
    return <ComponentLoader />;
  }

  return (
    <>
      {addQuestion && (
        <Card
          variant='outlined'
          sx={{
            mt: 10,
            p: 12,
            boxShadow: theme.shadows[2],
          }}
        >
          <TextField
            sx={{ mt: 8 }}
            type='text'
            label='Question'
            value={newForm?.question}
            onChange={handleNewFormChange}
            name='question'
            fullWidth
          />
          <TextField
            sx={{ mt: 8 }}
            label='Answer'
            value={newForm?.answer}
            onChange={handleNewFormChange}
            name='answer'
            multiline
            rows={4}
            fullWidth
          />
          <Box display='flex' gap={theme.spacing(4)}>
            <Button
              variant='contained'
              disabled={!(newForm?.question && newForm?.answer)}
              sx={{ borderRadius: theme.spacing(2), mt: 8 }}
              onClick={handelNewFormSave}
            >
              Save
            </Button>
            <Button
              variant='text'
              startIcon={<Close />}
              sx={{ borderRadius: theme.spacing(2), mt: 8, color: black[50] }}
              onClick={handleNewFormRemove}
            >
              Remove
            </Button>
          </Box>
        </Card>
      )}
      {!addQuestion && (
        <Button
          variant='outlined'
          startIcon={<Add />}
          disabled={addQuestion}
          sx={{ borderRadius: theme.spacing(2), mt: 12, mr: 'auto' }}
          onClick={handleAdd}
        >
          Add Question
        </Button>
      )}
    </>
  );
};
export default AddNewQuestion;
