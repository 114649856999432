import React, { useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Tab, Tabs, Typography } from '@mui/material';

import { fetchContactInformation, fetchFaqs, resetStatus } from '../../redux/contentManager/ContentManagerSlice';
import { RootState, StoreDispatch } from '../../redux/Store';

import PageContentContainer from '../../components/PageContentContainer/PageContentContainer';
import ContactInformation from './ContactInformation';
import Faqs from './Faqs';

function a11yProps(index: number) {
  return {
    id: `content-tab-${index}`,
    'aria-controls': `content-tabpanel-${index}`,
  };
}

const ContentManager = () => {
  const dispatch: StoreDispatch = useDispatch();
  const { status } = useSelector((state: RootState) => state.contentManager);
  const highlightRef = useRef<HTMLDivElement | null>(null);
  const faqRef = useRef<HTMLDivElement | null>(null);
  const contactRef = useRef<HTMLDivElement | null>(null);
  const [value, setValue] = React.useState(0);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
    switch (newValue) {
      case 0:
        highlightRef.current?.scrollIntoView({
          behavior: 'smooth',
          inline: 'start',
        });
        break;
      case 1:
        faqRef.current?.scrollIntoView({
          behavior: 'smooth',
          inline: 'start',
        });
        break;
      case 2:
        contactRef.current?.scrollIntoView({
          behavior: 'smooth',
          inline: 'start',
        });
        break;
      default:
        break;
    }
  };

  useEffect(() => {
    dispatch(fetchFaqs());
    dispatch(fetchContactInformation());
  }, []);

  useEffect(() => {
    if (status.updateFaqs.includes('succeeded') || status.deleteFaqs === 'succeeded') {
      dispatch(fetchFaqs());
      dispatch(resetStatus());
    }
  }, [status.updateFaqs, status.deleteFaqs]);

  useEffect(() => {
    if (status.updateContactInformation === 'succeeded') {
      dispatch(fetchContactInformation());
      dispatch(resetStatus());
    }
  }, [status.updateContactInformation]);

  return (
    <PageContentContainer>
      <Typography variant='h1'>Content Manager</Typography>
      <Tabs value={value} onChange={handleChange} aria-label='basic tabs example'>
        <Tab label='HIGHLIGHT BOX' {...a11yProps(0)} />
        <Tab label='FAQS' {...a11yProps(1)} />
        <Tab label='CONTACT INFORMATION' {...a11yProps(2)} />
      </Tabs>
      <div ref={highlightRef}>
        <Faqs isHighlightBox />
      </div>
      <div ref={faqRef}>
        <Faqs isHighlightBox={false} />
      </div>
      <div ref={contactRef}>
        <ContactInformation />
      </div>
    </PageContentContainer>
  );
};

export default ContentManager;
