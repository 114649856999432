import { Chip } from '@mui/material';
import React from 'react';

const RoleCell = ({ role }: { role: string }) => {
  const getRoleColor = () => {
    switch (role) {
      case 'Team Member':
        return 'primary';
      case 'Pending':
        return 'warning';
      case 'Account Owner':
        return 'purple';
      case 'Manager':
        return 'teal';
      default:
        return 'default';
    }
  };

  return <Chip label={role} color={getRoleColor()} />;
};

export default RoleCell;
