import React, { useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { Alert, Box, Typography, useTheme } from '@mui/material';
import ReplayIcon from '@mui/icons-material/Replay';

import { formatAmount, formatDate } from '../../utils/common';
import { RootState, StoreDispatch } from '../../redux/Store';
import {
  fetchClientTransactions,
  fetchPaymentStatusList,
  updateFilters,
} from '../../redux/transactions/TransactionsSlice';
import { DATE_FORMAT, FILTER_DATE_FORMAT, PAYMENT_TYPES } from '../../constants/common';
import {
  defaultTransactionFiltersData,
  IFilterPayload,
} from '../../redux/transactions/TransactionModel';
import { IClientTransactions } from '../../redux/clients/ClientModel';
import { PATHS } from '../../routes/Routes';

import SpectrumTable, { IColumn, Order } from '../../components/Spectrum/Table/SpectrumTable';
import StatusCell from '../../components/Spectrum/Table/StatusCell';
import KebabMenu, { IOption } from '../../components/Spectrum/Table/KebabMenu';
import TransactionExpandRowContent from '../Transactions/TransactionExpandRowContent';
import PaymentTypeCell from '../Transactions/PaymentTypeCell';
import { StyledDateCell } from '../Transactions/TransactionsStyles';
import { StatusTypes } from '../../redux/user/UserModel';
import { useFilterHandlers } from '../../utils/customUtilHooks';

const ClientTransactionsTable = () => {
  const theme = useTheme();
  const dispatch: StoreDispatch = useDispatch();
  const navigate = useNavigate();
  const { clientId } = useParams();
  const isInitialMount = useRef(true);
  const {
    data,
    status,
    error,
    filters,
    paymentStatusListError,
    paymentStatusListStatus,
    filters: { sortDirection, sortColumn, length },
  } = useSelector((state: RootState) => state.transactionsData);
  const columns = [
    {
      label: 'Date',
      sortKey: 'date',
      accessorKey: 'date',
      render: (data: IClientTransactions) => {
        return (
          <StyledDateCell>
            <div>{formatDate(data.date, DATE_FORMAT)}</div>
            {data?.paymentType === PAYMENT_TYPES.Recurring && (
              <div className='info'>
                <ReplayIcon className='info-icon' /> <span>RECURRING</span>
              </div>
            )}
          </StyledDateCell>
        );
      },
    },
    {
      label: 'Paid By',
      sortKey: 'PaidFullName',
      accessorKey: 'PaidBy',
      render: (data: IClientTransactions) => {
        return (
          <div>
            <strong>{data.PaidFullName}</strong>
            <Typography color='primary' variant='underline'>
              <strong>@{data.PaidBy}</strong>
            </Typography>
          </div>
        );
      },
    },
    {
      label: 'Payment ID',
      sortKey: 'PaymentID',
      accessorKey: 'PaymentID',
    },
    {
      label: 'Client',
      sortKey: 'Client',
      accessorKey: 'client',
      render: (data: IClientTransactions) => (
        <div>
          <strong>{data.ClientName}</strong>
          <Typography variant='subtitle2'>#{data.ClientId}</Typography>
        </div>
      ),
    },
    {
      label: 'Amount',
      sortKey: 'Amount',
      accessorKey: 'amount',
      render: (data: IClientTransactions) => (
        <div>
          <strong>{formatAmount(data.Amount)}</strong>
        </div>
      ),
    },
    {
      label: 'Type',
      sortKey: 'PaymentType',
      accessorKey: 'paymentType',
      render: (data: IClientTransactions) => <PaymentTypeCell type={data.paymentType} />,
    },
    {
      label: 'Status',
      sortKey: 'Status',
      accessorKey: 'status',
      render: (data: IClientTransactions) => <StatusCell status={data.Status} />,
    },
    {
      label: '',
      sortKey: '',
      accessorKey: '',
      collapseRowTrigger: true,
      width: '10px',
    },
    {
      label: '',
      sortKey: '',
      accessorKey: '',
      render: (data: IClientTransactions) => (
        <KebabMenu
          options={
            [
              {
                label: 'View Client Details',
                onClick: () => navigate(`${PATHS.CLIENTS}/${data.ClientId}`),
              },
              data.paymentType === 'Scheduled' &&
                data.Status === StatusTypes.Received && {
                  label: 'Edit Payment',
                  onClick: () => navigate(`${PATHS.TRANSACTIONS}/${data.SsoId}/${data.PaymentID}`),
                },
            ].filter(Boolean) as IOption[]
          }
        />
      ),
      width: '10px',
    },
  ];

  const updateFiltersAction = (updateFields: Partial<typeof filters>) => {
    dispatch(updateFilters({ ...filters, ...updateFields }));
  };

  const { handleSort, handlePageChange, handleChangeItemsPerPage } = useFilterHandlers<
    typeof filters
  >(filters, updateFiltersAction);

  useEffect(() => {
    dispatch(fetchPaymentStatusList());
    // Reset filters
    return () => {
      dispatch(updateFilters(defaultTransactionFiltersData));
    };
  }, []);

  useEffect(() => {
    if (isInitialMount.current) {
      isInitialMount.current = false;
      return;
    }
    const updatedFilters: { [x: string]: any } = {
      ...filters,
      startDate: filters?.startDate ? formatDate(filters?.startDate, FILTER_DATE_FORMAT) : null,
      endDate: filters?.endDate ? formatDate(filters?.endDate, FILTER_DATE_FORMAT) : null,
      ssoId: clientId,
    };
    dispatch(fetchClientTransactions(updatedFilters as IFilterPayload));
  }, [filters, isInitialMount.current]);

  const isLoading = status === 'loading' || paymentStatusListStatus === 'loading';

  return (
    <>
      {status === 'failed' && (
        <Box mb={theme.spacing(12)}>
          <Alert severity='error' variant='outlined'>
            <Typography variant='paragraph2' component='span' color='error'>
              {error}
            </Typography>
          </Alert>
        </Box>
      )}
      {paymentStatusListStatus === 'failed' && (
        <Box mb={theme.spacing(12)}>
          <Alert severity='error' variant='outlined'>
            <Typography variant='paragraph2' component='span' color='error'>
              {paymentStatusListError}
            </Typography>
          </Alert>
        </Box>
      )}
      <SpectrumTable
        columns={columns as Array<IColumn>}
        data={data?.data}
        totalItems={data?.totalRecords}
        onSort={handleSort}
        orderBy={sortColumn}
        order={sortDirection as Order}
        itemsPerPage={length}
        currentPage={filters.start / filters.length}
        loading={isLoading}
        onPageChange={handlePageChange}
        onItemsPerPageChange={handleChangeItemsPerPage}
        collapseRowContent={TransactionExpandRowContent}
      />
    </>
  );
};

export default ClientTransactionsTable;
