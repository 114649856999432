import React, { ChangeEvent, useState } from 'react';
import ReportDatePicker from '../ReportDatePicker';
import { Alert, Box, Typography } from '@mui/material';
import DownloadReport from '../DownloadReport';
import { RecurringPaymentsReportDownloadTypes, ReportType } from '../ReportTypes';
import { IReportDownloadType } from '../../../../redux/transactions/TransactionModel';
import { downloadFile, formatDate } from '../../../../utils/common';
import { DATE_FORMAT } from '../../../../constants/common';
import { downloadRecurringPaymentsReport } from '../../../../redux/transactions/TransactionsSlice';
import { ComponentOverlayLoader } from '../../../../components/Loaders/Loader';

interface IRecurringPaymentsReportProps {
  onClose: () => void;
}

const RecurringPaymentsReport = ({ onClose }: IRecurringPaymentsReportProps) => {
  const [date, setDate] = useState<any>(null);
  const [viewType, setViewType] = useState(1);
  const [reportDownloadStatus, setReportDownloadStatus] = useState<any>(false);
  const [reportDownloadError, setReportDownloadError] = useState<any>(null);
  const formattedDate = date ? formatDate(date, DATE_FORMAT) : '';
  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    const value: any = event.target.value;
    setDate(value);
  };

  const handleConfirm = () => {
    setViewType(2);
  };

  const handleDownloadReport = (reports: IReportDownloadType[]) => {
    setReportDownloadStatus(true);
    setReportDownloadError('');
    Promise.all(
      reports.map((report) =>
        downloadRecurringPaymentsReport({
          nextChargeDate: formattedDate,
          fileType: report?.format?.toLowerCase()
        }),
      ),
    )
      .then((reportResults) => {
        reportResults.forEach((result) => {
          const data = result.data;
          downloadFile(data, ReportType.RECURRING_PAYMENTS_REPORT, JSON.parse(result.config.data)?.fileType);
        });
        setReportDownloadStatus(false);
      })
      .catch(() => {
        setReportDownloadStatus(false);
        setReportDownloadError('Failed to download recurring payments report');
      });
  };

  return (
    <div style={{ position: 'relative' }}>
      {reportDownloadStatus && <ComponentOverlayLoader />}
      {viewType === 1 && (
        <ReportDatePicker
          helpText={
            <Typography variant='body2' textAlign='justify'>
              Downloads the <strong>Recurring Payment Report</strong> (.csv) beginning from your
              specified date.
            </Typography>
          }
          heading='Choose Date Range'
          subHeading='Recurring Payment Report'
          isDateRange={false}
          date={date}
          handleChange={handleChange}
          onClose={onClose}
          onConfirm={handleConfirm}
          confirmButtonText='Confirm Download'
        />
      )}
      {viewType === 2 && (
        <DownloadReport
          date={formattedDate}
          reports={RecurringPaymentsReportDownloadTypes}
          heading='Ready for Download'
          subHeading='Recurring Payment Report'
          onDownloadReport={handleDownloadReport}
          onBack={() => setViewType(1)}
          showAll={false}
        >
          {reportDownloadError && (
            <Box mb={12}>
              <Alert severity='error' variant='outlined'>
                <Typography variant='paragraph2' component='span' color='error'>
                  {reportDownloadError}
                </Typography>
              </Alert>
            </Box>
          )}
        </DownloadReport>
      )}
    </div>
  );
};
export default RecurringPaymentsReport;
