import { Box } from '@mui/material';
import { styled } from '@mui/material/styles';
import { ColorPartial } from '@mui/material/styles/createPalette';

export const StyledDateRangePickerWrapper = styled(Box)(({ theme }) => {
  const grey: ColorPartial = theme.palette.grey as ColorPartial;
  const primary: ColorPartial = theme.palette.primary as ColorPartial;

  const react_date_picker = {
    width: '2rem',
    lineHeight: '2rem',
    margin: theme.spacing(1, 0),
  };

  const react_date_picker_color = {
    borderRadius: 0,
    background: primary[100],
  };

  const margin_12_0 = {
    margin: theme.spacing(6, 0),
  };

  return {
    width: '100%',
    display: 'flex',
    alignItems: 'center',

    '.react-datepicker-wrapper': {
      width: '100%',
    },

    '.react-datepicker__input-container::before': {
      content: '""',
      backgroundImage:
        'url("data:image/svg+xml,%3Csvg xmlns=\'http://www.w3.org/2000/svg\' width=\'24\' height=\'24\' viewBox=\'0 0 24 24\' fill=\'none\'%3E%3Cpath d=\'M18 15H15V18H18V15Z\' fill=\'%2363738A\'/%3E%3Cpath fill-rule=\'evenodd\' clip-rule=\'evenodd\' d=\'M18.75 2.25V4.5H21.75V19.5C21.7486 20.7421 20.7421 21.7486 19.5 21.75H4.5C3.25792 21.7486 2.25136 20.7421 2.25 19.5V4.5H5.25V2.25H6.75V4.5H17.25V2.25H18.75ZM20.25 8.25V6H3.75V8.25H20.25ZM19.5 20.25H4.5C4.0859 20.2497 3.75028 19.9141 3.75 19.5V9.75H20.25V19.5C20.2497 19.9141 19.9141 20.2497 19.5 20.25Z\' fill=\'%2363738A\'/%3E%3C/svg%3E")',
      padding: theme.spacing(6),
      position: 'absolute',
      top: '8px',
      left: '14px',
    },

    label: {
      textTransform: 'capitalize',
      color: grey[500],
      whiteSpace: 'nowrap',
    },

    '.react-datepicker': {
      boxShadow: theme.shadows[24],
      border: 'none',
      fontFamily: 'inherit',
      padding: theme.spacing(0, 3, 3),
    },

    '.react-datepicker__day-name': react_date_picker,
    '.react-datepicker__day': react_date_picker,
    '.react-datepicker__time-name': react_date_picker,

    '.react-datepicker__day--selected': react_date_picker_color,
    '.react-datepicker__day--in-selecting-range': react_date_picker_color,
    '.react-datepicker__quarter-text--in-selecting-range': react_date_picker_color,
    '.react-datepicker__month-text--selected': react_date_picker_color,
    '.react-datepicker__month-text--in-selecting-range': react_date_picker_color,
    '.react-datepicker__month-text--in-range': react_date_picker_color,
    '.react-datepicker__quarter-text--selected': react_date_picker_color,
    '.react-datepicker__quarter-text--in-range': react_date_picker_color,
    '.react-datepicker__year-text--selected': react_date_picker_color,
    '.react-datepicker__year-text--in-selecting-range': react_date_picker_color,
    '.react-datepicker__year-text--in-range': react_date_picker_color,

    '.react-datepicker__day--disabled': {
      backgroundColor: 'transparent',
    },

    '.react-datepicker__day--keyboard-selected': {
      color: 'inherit',
      backgroundColor: 'inherit',
      '&:hover': {
        backgroundColor: grey[100],
      },
    },

    '.react-datepicker__day--in-range': {
      borderRadius: 0,
      background: primary[100],

      '&.react-datepicker__day--range-start': {
        backgroundColor: primary[500],
      },

      '&.react-datepicker__day--range-end': {
        backgroundColor: primary[500],
      },
    },

    '.react-datepicker__header': {
      backgroundColor: 'transparent',
      borderBottom: 'none',
    },

    '.react-datepicker__navigation--previous': {
      left: 10,
    },

    '.react-datepicker__navigation': {
      top: 15,
    },

    '.react-datepicker__day-names': {
      fontWeight: 700,
    },

    '.react-datepicker__current-month': margin_12_0,
    '.react-datepicker-time__header': margin_12_0,
    '.react-datepicker-year-header': margin_12_0,

    '.react-datepicker-time__input': {
      minWidth: 150,
    },

    input: {
      width: '100%',
      border: 'none',
      padding: `${theme.spacing(8.25, 7, 8.25, 22)}`,
      outline: `${theme.spacing(0.5)} solid ${grey[400]}`,
      boxSizing: 'border-box',
      fontFamily: 'inherit',
      '&::placeholder': {
        color: '#000',
        opacity: 1
      },
      '&:hover': {
        outlineWidth: 1,
        outlineColor: theme.palette.common.black,
      },
      '&:focus:not(.focus-visible)': {
        outline: `${theme.spacing(0.5)} solid ${primary[500]}`,
        outlineOffset: 0,
      },
    },
    '&.small': {
      input: {
        height: 40,
      },
    },
  };
});
