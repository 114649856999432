import React from 'react';
import { Box, Card, Divider, Typography, useTheme } from '@mui/material';
import { ColorPartial } from '@mui/material/styles/createPalette';
import { IReviewUser } from '../../../redux/user/UserModel';

interface IBasicDetailsProps {
  userDetails: IReviewUser;
}

const BasicDetails = ({ userDetails }: IBasicDetailsProps) => {
  const theme = useTheme();
  const black: ColorPartial = theme.palette.black as ColorPartial;
  const blue: ColorPartial = theme.palette.blue as ColorPartial;
  const primary: ColorPartial = theme.palette.primary as ColorPartial;

  const reviewFields: Array<{ label: string; value: keyof IReviewUser }> = [
    {
      label: 'First Name',
      value: 'firstName',
    },
    {
      label: 'Last Name',
      value: 'lastName',
    },
    {
      label: 'Company Name',
      value: 'companyName',
    },
    {
      label: 'Customer ID',
      value: 'customerID',
    },
    {
      label: 'Client Type',
      value: 'companyType',
    },
    {
      label: 'Works with other Agencies',
      value: 'worksWithOtherAgencies',
    },
    {
      label: 'Account Executive',
      value: 'accountExecutive',
    },
    {
      label: 'Recent Invoice Number',
      value: 'recentInvoiceNumber',
    },
  ];

  return (
    <Card
      sx={{
        flexGrow: 1,
      }}
    >
      <Box sx={{ background: blue[100], border: `1px solid ${blue[200]}`, p: 10 }}>
        <Typography variant='h4' mb={12}>
          User Details
        </Typography>
        <Box>
          <Box display='flex' flexDirection='column' gap={theme.spacing(12)} mt={theme.spacing(8)}>
            <Box>
              {reviewFields.map((field, index) => (
                <Box mb={12} key={index}>
                  <Typography color={black[500]} variant='h6' mb={theme.spacing(4)}>
                    {field?.label}
                  </Typography>
                  <Typography variant='body1' sx={{ textTransform: 'capitalize' }}>
                    {userDetails[field.value] as string}
                  </Typography>
                  {index < reviewFields.length - 1 && (
                    <Divider
                      sx={{ borderColor: primary[100], mt: 8, mb: 8, borderStyle: 'dashed' }}
                    />
                  )}
                </Box>
              ))}
            </Box>
          </Box>
        </Box>
      </Box>
    </Card>
  );
};

export default BasicDetails;
