import React from 'react';
import { StyledAvatar } from './AvatarStyles';

interface IAvatarProps {
  children: any;
  size?: string;
  bgColor?: string;
}

export const Avatar = ({ children, size, bgColor = 'primary' }: IAvatarProps) => {
  return (
    <StyledAvatar size={size} bgColor={bgColor}>
      {children}
    </StyledAvatar>
  );
};
export default Avatar;
