import React, { useState } from 'react';
import { Link, Stack, Typography, useTheme } from '@mui/material';
import { StyledReportContainer } from './ReportStyles';
import DescriptionIcon from '@mui/icons-material/Description';
import { ExternalLinks, ReportType, ReportTypes } from './ReportTypes';
import SpectrumDialog from '../../../components/Spectrum/Dialog/SpectrumDialog';
import TransactionReport from './TransactionReport/TransactionReport';
import { IReportType } from '../../../redux/transactions/TransactionModel';
import UserReport from './UserReport/UserReport';
import RecurringPaymentsReport from './RecurringPaymentsReport/RecurringPaymentsReport';
import ArrowRightAltIcon from '@mui/icons-material/ArrowRightAlt';

const Reports = () => {
  const theme = useTheme();
  const [dialogStatus, setDialogStatus] = useState(false);
  const [selectedReport, setSelectedReport] = useState<IReportType | null>(null);

  const handleReportClick = (report: IReportType) => {
    setSelectedReport(report);
    setDialogStatus(true);
  };
  const handleClose = () => {
    setDialogStatus(false);
  };
  return (
    <div>
      <Typography mb={theme.spacing(6)} variant='h6'>
        REPORTS
      </Typography>
      <Stack spacing={4}>
        {ReportTypes.map((report) => (
          <StyledReportContainer key={report?.type} onClick={() => handleReportClick(report)}>
            <div className='report-icon'>
              <DescriptionIcon />
            </div>
            <div className='report-label'>{report?.label}</div>
          </StyledReportContainer>
        ))}
      </Stack>
      <Typography sx={{ mb: 6, mt: 10 }} variant='h6'>
        EXTERNAL LINKS
      </Typography>
      <Stack spacing={4}>
        {ExternalLinks.map((externalLink) => (
          <StyledReportContainer key={externalLink?.label}>
            <Link
              href={externalLink?.link}
              display='flex'
              width='100%'
              height='100%'
              underline='hover'
            >
              <div className='report-icon'>
                <DescriptionIcon />
              </div>
              <div className='report-label'>
                {externalLink?.label}
                <ArrowRightAltIcon className="icon" />
              </div>
            </Link>
          </StyledReportContainer>
        ))}
      </Stack>
      <SpectrumDialog open={dialogStatus} onClose={handleClose}>
        {selectedReport?.type === ReportType.TRANSACTION_REPORT && (
          <TransactionReport onClose={handleClose} />
        )}
        {selectedReport?.type === ReportType.USER_REPORT && <UserReport onClose={handleClose} />}
        {selectedReport?.type === ReportType.RECURRING_PAYMENTS_REPORT && (
          <RecurringPaymentsReport onClose={handleClose} />
        )}
      </SpectrumDialog>
    </div>
  );
};
export default Reports;
