import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';

export const StyledSpectrumDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: theme.spacing(5, 15, 15),
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1),
  },
}));
