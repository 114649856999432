import React, { useEffect } from 'react';
import { fetchTokenUsingAuthCode } from '../../redux/auth/AuthSlice';
import { getCodeFromUrl, isUserAuthenticated, redirectToAuthPage } from '../../utils/authUtils';
import { PageLoader } from '../../components/Loaders/Loader';
import { useDispatch, useSelector } from 'react-redux';
import { RootState, StoreDispatch } from '../../redux/Store';
import { PATHS } from '../../routes/Routes';
import { useNavigate } from 'react-router-dom';

const AutoLogin = () => {
  const navigate = useNavigate();
  const dispatch: StoreDispatch = useDispatch();
  const { accessTokenStatus } = useSelector((state: RootState) => state.authData);
  const isAuthenticated = isUserAuthenticated();
  const authCode = getCodeFromUrl();

  useEffect(() => {
    if (!isAuthenticated) {
      if (authCode) {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        dispatch(fetchTokenUsingAuthCode(authCode));
      } else {
        redirectToAuthPage();
      }
    } else {
      navigate(PATHS.TRANSACTIONS);
    }
  }, [authCode]);

  useEffect(() => {
    if (accessTokenStatus && accessTokenStatus === 'failed') {
      redirectToAuthPage();
    }
    if (accessTokenStatus && accessTokenStatus === 'succeeded') {
      navigate(PATHS.TRANSACTIONS);
    }
  }, [accessTokenStatus]);

  const isLoading = accessTokenStatus === 'loading';

  return <>{isLoading && <PageLoader></PageLoader>}</>;
};
export default AutoLogin;
