import React from 'react';
import { Box, Divider, InputAdornment, Typography } from '@mui/material';
import { formatAmount, formatDate } from '../../../utils/common';
import { DATE_FORMAT } from '../../../constants/common';
import SpectrumTextField from '../../../components/Spectrum/TextField/SpectrumTextField';
import {
  StyledAccordion,
  StyledAccordionDetails,
  StyledAccordionSummary,
} from './EditTransactionStyles';
import { ExpandCircleDownOutlined } from '@mui/icons-material';
import { IAdvertiserItem, IInvoiceOrderItem } from '../../../redux/transactions/TransactionModel';

interface ITransactionAdvertisers {
  advertisers: IAdvertiserItem[];
  handleUpdate: (advertisers: IAdvertiserItem[]) => void;
}

const TransactionAdvertisers = ({ advertisers, handleUpdate }: ITransactionAdvertisers) => {
  const findAndUpdateValue = (advertiserId: string, id: number, data: any) => {
    return advertisers.map((advertiser) => {
      let items = advertiser.items;
      if (advertiser.advertiserId === advertiserId) {
        items = advertiser.items.map((item) => {
          if (item.id === id) {
            const isCustomAmount = item.totalAmount !== data;
            return { ...item, isCustomAmount, newAmount: data ? Number(data) : data };
          }
          return item;
        });
        return { ...advertiser, items };
      }
      return advertiser;
    });
  };

  const handleChange = (
    advertiserId: string,
    item: IInvoiceOrderItem,
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    const data = event.target.value;
    const modAdvertisers = findAndUpdateValue(advertiserId, item.id, data);
    handleUpdate(modAdvertisers);
  };

  const handleBlur = (
    advertiserId: string,
    item: IInvoiceOrderItem,
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    const data = event.target.value;
    const modAdvertisers = findAndUpdateValue(advertiserId, item.id, data || item.oldAmount);
    handleUpdate(modAdvertisers);
  };

  return (
    <Box mt={12} gap={4}>
      {(advertisers || []).map((advertiser) => {
        const items = advertiser.items || [];
        return (
          <StyledAccordion key={advertiser?.id} TransitionProps={{ unmountOnExit: true }}>
            <StyledAccordionSummary expandIcon={<ExpandCircleDownOutlined color='primary' />}>
              <Box
                display='flex'
                justifyContent='space-between'
                sx={{
                  width: '100%',
                }}
              >
                <Typography variant='h5'>
                  {advertiser?.advertiserName}{' '}
                  <Typography variant='subtitle' ml={3}>
                    #{advertiser?.advertiserId}
                  </Typography>
                </Typography>
              </Box>
            </StyledAccordionSummary>
            <StyledAccordionDetails>
              <Box p={5}>
                {items.map((item, itemIndex) => {
                  return (
                    <Box key={item.id}>
                      <Box display='flex' alignItems='center' justifyContent='space-between'>
                        <Box>
                          <Typography variant='h5'>
                            {item?.type} # {item?.itemNumber}
                          </Typography>
                          <Box display='flex' flexDirection='column'>
                            <Typography variant='subtitle' fontSize='12'>
                              Total Amount: {formatAmount(item?.totalAmount)}
                            </Typography>
                            <Typography variant='subtitle' fontSize='12'>
                              Due: {formatDate(item?.dueDate, DATE_FORMAT)}
                            </Typography>
                          </Box>
                        </Box>
                        <SpectrumTextField
                          type='number'
                          placeholder=''
                          size='small'
                          value={item.newAmount}
                          onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                            handleChange(advertiser.advertiserId, item, event)
                          }
                          onBlur={(event: React.ChangeEvent<HTMLInputElement>) =>
                            handleBlur(advertiser.advertiserId, item, event)
                          }
                          InputProps={{
                            startAdornment: <InputAdornment position='start'>$</InputAdornment>,
                          }}
                        ></SpectrumTextField>
                      </Box>
                      <Divider sx={{ mt: 5, mb: 5 }} />
                    </Box>
                  );
                })}
              </Box>
            </StyledAccordionDetails>
          </StyledAccordion>
        );
      })}
    </Box>
  );
};
export default TransactionAdvertisers;
