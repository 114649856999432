import React from 'react';
import { Box, Typography, Button, useTheme } from '@mui/material';
import { Close } from '@mui/icons-material';
import SpectrumDialog from '../../../components/Spectrum/Dialog/SpectrumDialog';

interface IUnlinkClientModalProps {
  open: boolean;
  handleClose: () => void;
  handleUnlink: () => void;
  clientName: string;
}

const UnlinkClientModal = ({ open, handleClose, handleUnlink }: IUnlinkClientModalProps) => {
  const theme = useTheme();
  return (
    <SpectrumDialog open={open} onClose={handleClose}>
      <Typography variant={'eyebrow'}>UNLINK CLIENT</Typography>
      <Typography variant={'h2'}>Do you want to remove the user from this client?</Typography>
      <Box mt={5} display={'flex'} justifyContent={'flex-start'}>
        <Box display='flex' gap={theme.spacing(10)} mt={theme.spacing(8)}>
          <Button
            onClick={handleUnlink}
            variant='contained'
            sx={{
              borderRadius: theme.spacing(2),
              width: 'fit-content',
            }}
          >
            <Typography variant='body1'>Confirm and Unlink Client</Typography>
          </Button>
          <Button
            onClick={handleClose}
            startIcon={<Close style={{ color: theme.palette.grey[500] }} />}
          >
            <Typography variant='body1' color={theme.palette.grey[500]}>
              Cancel
            </Typography>
          </Button>
        </Box>
      </Box>
    </SpectrumDialog>
  );
};

export default UnlinkClientModal;
