import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Box, Typography, useTheme } from '@mui/material';

import { updateFilters } from '../../redux/recurringPayments/RecurringPaymentsSlice';

import ScheduleAutoPaymentsFilters from './ScheduleAutoPaymentsFilters';
import ScheduleAutoPaymentsTable from './ScheduleAutoPaymentsTable';

const ScheduleAutoPayments = () => {
  const theme = useTheme();
  const dispatch = useDispatch()

  useEffect(() => {
    return () => {
      dispatch(
        updateFilters({
          start: 0,
          length: 10,
          sortColumn: '',
          sortDirection: '',
          searchValue: '',
          startDate: null,
          endDate: null,
          userId: ''
        }),
      );
    };
  }, []);

  return (
    <Box mt={theme.spacing(16)}>
      <Typography variant='h2' mb={theme.spacing(8)}>
        Scheduled AutoPayments
      </Typography>
      <Box sx={{ mb: 6 }}>
        <ScheduleAutoPaymentsFilters />
      </Box>
      <ScheduleAutoPaymentsTable />
    </Box>
  );
};

export default ScheduleAutoPayments;
