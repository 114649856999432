import React from 'react';
import { IconButton, MenuItem, Divider, Typography } from '@mui/material';
import Avatar from '../Avatar/Avatar';
import AvatarLink from '../AvatarLink/AvatarLink';
import { StyledContainer, StyledMenuPopover } from './UserMenuStyles';
import { getFirstChar } from '../../utils/common';
import { IUserDetails } from '../../redux/auth/AuthModels';

interface IUserMenuProps {
  user: IUserDetails | null;
}

const UserMenu = ({ user }: IUserMenuProps) => {
  const userName = user ? getFirstChar(user?.firstName) + '' + getFirstChar(user?.lastName) : '';
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  return (
    <React.Fragment>
      <StyledContainer>
        <IconButton
          onClick={handleClick}
          size='small'
          sx={{ ml: 2 }}
          aria-controls={open ? 'account-menu' : undefined}
          aria-haspopup='true'
          aria-expanded={open ? 'true' : undefined}
        >
          <Avatar size='medium'>{userName}</Avatar>
        </IconButton>
      </StyledContainer>
      <StyledMenuPopover
        anchorEl={anchorEl}
        id='account-menu'
        open={open}
        onClose={handleClose}
        onClick={handleClose}
        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
      >
        <MenuItem onClick={handleClose}>
          <AvatarLink avatarLabel='OS' label='Onespot' />
        </MenuItem>
        <MenuItem onClick={handleClose}>My Account Settings</MenuItem>
      </StyledMenuPopover>
    </React.Fragment>
  );
};
export default UserMenu;
