import React from 'react';
import DatePicker, { ReactDatePickerProps } from 'react-datepicker';

import 'react-datepicker/dist/react-datepicker.css';
import { StyledDateRangePickerWrapper } from './DateRangePickerStyles';

interface ICustomDatePicker extends ReactDatePickerProps {
  label: string;
  value: any;
  onChange: any;
  name?: any;
  size?: string;
  placeholder?: string;
}

const SpectrumDatePicker = ({
  label,
  value,
  onChange,
  name,
  placeholder,
  ...rest
}: ICustomDatePicker) => {
  const handleChange = (date: Date | null) => {
    onChange({ target: { name, value: date } });
  };
  const handleCalendarClose = () => {
    if (!value) {
      onChange({ target: { name, value: value } });
    }
  };
  return (
    <StyledDateRangePickerWrapper className={rest.size || ''}>
      {label && <label>{label}</label>}
      <DatePicker
        {...rest}
        selected={value}
        onChange={handleChange}
        onCalendarClose={handleCalendarClose}
        placeholderText={placeholder}
      />
    </StyledDateRangePickerWrapper>
  );
};

export default SpectrumDatePicker;
