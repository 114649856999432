import React from 'react';
import { StyledFooter } from './FooterStyles';
import { Typography } from '@mui/material';

const Footer = () => {
  return (
    <StyledFooter>
      <Typography alignItems='center' className='info-text' variant='subtitle2'>
        Copyright © 2023 Spectrum Reach
      </Typography>
    </StyledFooter>
  );
};
export default Footer;
