import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { clientEndpoints, peopleEndpoints } from '../../constants/apiConstants';
import ApiService from '../../services/ApiService';
import {
  IRecurringPaymentsState,
  IRecurringPayments,
  IFilterPayload,
} from './RecurringPaymentsModel';

const initialState: IRecurringPaymentsState = {
  status: 'idle',
  data: {
    totalRecords: 0,
  } as IRecurringPayments,
  filters: {
    start: 0,
    length: 10,
    sortColumn: '',
    sortDirection: '',
    searchValue: '',
    userId: '',
  },
  cancellingRecurringPaymentStatus: 'idle',
  cancellingRecurringPaymentStatusError: '',
  error: '',
};

export const fetchPeopleRecurringPayments = createAsyncThunk(
  'People/GetRecurringPayments',
  async (payload: IFilterPayload) => {
    const response = await ApiService.postData(peopleEndpoints.recurringPayments, {}, payload);
    return response.data;
  },
);

export const fetchClientRecurringPayments = createAsyncThunk(
  'Client/GetRecurringPayments',
  async (payload: IFilterPayload) => {
    const response = await ApiService.postData(clientEndpoints.recurringPayments, {}, payload);
    return response.data;
  },
);

export const cancelPeopleRecurringPayments = createAsyncThunk(
  'People/CancelRecurringPayments',
  async (payload: any) => {
    const response = await ApiService.postData(peopleEndpoints.cancelRecurringPayments, {}, payload);
    return response.data;
  },
);

export const cancelClientRecurringPayments = createAsyncThunk(
  'Client/CancelRecurringPayments',
  async (payload: any) => {
    const response = await ApiService.postData(clientEndpoints.cancelRecurringPayments, {}, payload);
    return response.data;
  },
);

export const RecurringPaymentsSlice = createSlice({
  name: 'recurringPayments',
  initialState,
  reducers: {
    resetError: (state) => {
      state.cancellingRecurringPaymentStatus = 'idle';
      state.cancellingRecurringPaymentStatusError = ''
    },
    updateFilters: (state, action) => {
      state.filters = action?.payload;
    },
  },
  extraReducers(builder) {
    builder
      .addCase(fetchPeopleRecurringPayments.pending, (state) => {
        state.status = 'loading';
        state.data = {} as IRecurringPayments;
        state.error = '';
      })
      .addCase(fetchPeopleRecurringPayments.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.data = action.payload.data;
        state.error = '';
      })
      .addCase(fetchPeopleRecurringPayments.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action?.error?.message || 'Something went wrong, please try again later';
        state.data = {} as IRecurringPayments;
      })
      .addCase(fetchClientRecurringPayments.pending, (state) => {
        state.status = 'loading';
        state.data = {} as IRecurringPayments;
        state.error = '';
      })
      .addCase(fetchClientRecurringPayments.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.data = action.payload.data;
        state.error = '';
      })
      .addCase(fetchClientRecurringPayments.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action?.error?.message || 'Something went wrong, please try again later';
        state.data = {} as IRecurringPayments;
      })
      .addCase(cancelPeopleRecurringPayments.pending, (state) => {
        state.cancellingRecurringPaymentStatus = 'loading';
      })
      .addCase(cancelPeopleRecurringPayments.fulfilled, (state, action) => {
        state.cancellingRecurringPaymentStatus = 'succeeded';
      })
      .addCase(cancelPeopleRecurringPayments.rejected, (state, action) => {
        state.cancellingRecurringPaymentStatus = 'failed';
        state.cancellingRecurringPaymentStatusError =
          action?.error?.message || 'Something went wrong, please try again later';
      })
      .addCase(cancelClientRecurringPayments.pending, (state) => {
        state.cancellingRecurringPaymentStatus = 'loading';
      })
      .addCase(cancelClientRecurringPayments.fulfilled, (state, action) => {
        state.cancellingRecurringPaymentStatus = 'succeeded';
      })
      .addCase(cancelClientRecurringPayments.rejected, (state, action) => {
        state.cancellingRecurringPaymentStatus = 'failed';
        state.cancellingRecurringPaymentStatusError =
          action?.error?.message || 'Something went wrong, please try again later';
      });
  },
});
export const { resetError, updateFilters } = RecurringPaymentsSlice.actions;
export default RecurringPaymentsSlice.reducer;
