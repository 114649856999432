import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { Button, Card, Container, useTheme } from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

import { updateFilters as updateRecurringPaymentsFilters } from '../../redux/recurringPayments/RecurringPaymentsSlice';

import TransactionsHistory from './TransactionHistory';
import PageContentContainer from '../../components/PageContentContainer/PageContentContainer';
import ScheduleAutoPayments from '../ScheduleAutoPayments/ScheduleAutoPayments';
import People from '../People/People';
import ClientProfile from './ClientProfile';
import { resetFilters } from '../../redux/user/UserSlice';
import { scrollToTop } from '../../utils/common';

const ClientDetails = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { clientId } = useParams();
  const theme = useTheme();

  useEffect(() => {
    dispatch(
      updateRecurringPaymentsFilters({
        start: 0,
        length: 10,
        sortColumn: '',
        sortDirection: '',
        searchValue: '',
        userId: clientId,
      }),
    );
  }, [clientId]);

  useEffect(() => {
    scrollToTop();
    return () => {
      dispatch(resetFilters());
    };
  }, []);

  return (
    <PageContentContainer>
      <Button
        variant='text'
        onClick={() => navigate(-1)}
        sx={{ padding: 0 }}
        startIcon={<ArrowBackIcon />}
      >
        Back to Clients
      </Button>
      <Card variant='outlined' sx={{ p: theme.spacing(12), mt: theme.spacing(8) }}>
        <ClientProfile ssoId={clientId ?? ''} />
      </Card>
      <People />
      <Container
        sx={{
          paddingLeft: theme.spacing(10),
        }}
      >
        <ScheduleAutoPayments />
        <TransactionsHistory />
      </Container>
    </PageContentContainer>
  );
};

export default ClientDetails;
