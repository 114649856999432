import * as React from 'react';
import { styled } from '@mui/material/styles';
import { Box, FormHelperText, Select, Typography } from '@mui/material';

export const StyledInputLabel = styled('label', {
  shouldForwardProp: () => true,
})(({ theme }) => {
  return {
    fontSize: '1rem',
    fontWeight: '700',
    textTransform: 'capitalize',
    marginBottom: theme.spacing(4),
  };
});

const StyledSelect = styled(Select, {
  shouldForwardProp: () => true,
})(({ theme, ...props }) => {
  return {
    input: {
      ...(props.size === 'medium' && {
        height: theme.spacing(8),
      }),
    },
  };
});

export default function SpectrumSelectField({ label, children, ...rest }: any) {
  return (
    <Box sx={{ display: 'flex', flexDirection: 'column' }}>
      {label && (
        <StyledInputLabel>
          {label}{' '}
          {rest?.required && (
            <Typography component='span' variant='body2' color='red'>
              *
            </Typography>
          )}{' '}
        </StyledInputLabel>
      )}
      <StyledSelect {...rest}>{children}</StyledSelect>
      {rest.error && <FormHelperText error={rest.error}>{rest?.helperText}</FormHelperText>}
    </Box>
  );
}
