import React, { ChangeEvent, useEffect, KeyboardEvent, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { MenuItem, Grid } from '@mui/material';
import { insertSpacesBeforeCapitals } from '../../utils/common';

import { RootState, StoreDispatch } from '../../redux/Store';
import { fetchUserRoles, updateFilters } from '../../redux/user/UserSlice';
import { IUserStatus } from '../../redux/user/UserModel';

import SpectrumSearch from '../../components/Spectrum/Search/SpectrumSearch';
import CustomDateRangePicker from '../../components/DatePicker/DateRangePicker';
import SpectrumSelectField from '../../components/Spectrum/Select/SpectrumSelect';
import { PATHS } from '../../routes/Routes';

const PeopleFilters = () => {
  const { pathname } = useLocation();
  const isClientDetailPage = pathname.search(PATHS.CLIENTS) > -1;
  const dispatch: StoreDispatch = useDispatch();
  const { filters, userStatusList, userRoles } = useSelector((state: RootState) => state.userData);
  const [filterValues, setFilterValues] = useState(filters);

  useEffect(() => {
    if (isClientDetailPage) {
      dispatch(fetchUserRoles());
    }
  }, [isClientDetailPage]);

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    const { name } = event.target;
    const value: any = event.target.value;
    if (name === 'date') {
      const [startDate, endDate] = value;
      setFilterValues({ ...filterValues, startDate, endDate, start: 0 });
      if ((startDate && endDate) || (!startDate && !endDate)) {
        dispatch(updateFilters({ ...filterValues, startDate, endDate, start: 0 }));
      }
      return;
    }
    if (name === 'status' || name === 'role') {
      setFilterValues({ ...filterValues, [name]: value, start: 0 });
      dispatch(updateFilters({ ...filterValues, [name]: value, start: 0 }));
      return;
    }
    setFilterValues({ ...filterValues, [name]: value, start: 0 });
  };

  const handleKeyDown = (event: KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter') {
      dispatch(updateFilters(filterValues));
    }
  };

  useEffect(() => {
    setFilterValues(filters);
  }, [filters]);

  return (
    <div>
      <Grid container spacing={4}>
        <Grid item laptop={6}>
          <SpectrumSearch
            placeholder='Search...'
            sx={{ width: '100%' }}
            size='small'
            name='searchValue'
            value={filterValues.searchValue}
            onChange={handleChange}
            onKeyDown={handleKeyDown}
          />
        </Grid>
        <Grid item laptop={3}>
          {isClientDetailPage ? (
            <SpectrumSelectField
              variant='outlined'
              size='small'
              name='role'
              value={filterValues.role}
              onChange={handleChange}
            >
              <MenuItem value={0}>All</MenuItem>
              {userRoles.map((role) => (
                <MenuItem key={role.id} value={role.id}>
                  {role.name}
                </MenuItem>
              ))}
            </SpectrumSelectField>
          ) : (
            <CustomDateRangePicker
              size='small'
              value={[filterValues.startDate, filterValues.endDate]}
              label=''
              name='date'
              placeholder='Date Range'
              onChange={handleChange}
            />
          )}
        </Grid>
        <Grid item laptop={3}>
          <SpectrumSelectField
            variant='outlined'
            size='small'
            name='status'
            value={filterValues.status}
            onChange={handleChange}
          >
            <MenuItem value={0}>All</MenuItem>
            {userStatusList.map((status: IUserStatus) => (
              <MenuItem key={status.id} value={status.id}>
                {insertSpacesBeforeCapitals(status?.name)}
              </MenuItem>
            ))}
          </SpectrumSelectField>
        </Grid>
      </Grid>
    </div>
  );
};

export default PeopleFilters;
