import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { StoreDispatch } from '../../../redux/Store';
import { Box, Card, Typography } from '@mui/material';
import { ClientTypes } from '../../../redux/clients/ClientModel';
import LinkUserComponent from './LinkUserComponent';
import RenderAssociatedClient from './RenderAssociatedClient';
import {
  resetAgenciesList,
  resetAdvertisersList,
  resetAdvertiserError,
  resetAgencyError,
} from '../../../redux/user/UserSlice';
import { IReviewUser } from '../../../redux/user/UserModel';
import ClientSelectionModal from './ClientSelectionModal';

interface ISubmitPayloadDetails {
  clientName: string;
  clientId: string;
  clientType: string;
  ssoId: string;
}

interface IBasicDetailsProps {
  reviewUserDetails: IReviewUser;
  userDetails: {
    clientType: ClientTypes;
    client: string;
  };
  handleChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  errorUserDetails: { clientType: string; client: string };
  updatePayload: (payload: ISubmitPayloadDetails) => void;
  confirmUserPayload: ISubmitPayloadDetails;
  setConfirmUserPayload: (payload: ISubmitPayloadDetails) => void;
}

const ConfigureUser = ({
  reviewUserDetails,
  updatePayload,
  confirmUserPayload,
  setConfirmUserPayload,
}: IBasicDetailsProps) => {
  const dispatch: StoreDispatch = useDispatch();

  const [open, setOpen] = useState(false);
  const [, setSelectedClient] = useState('');

  const [payload, setPayload] = useState({
    clientType: '',
    searchType: '',
    searchValue: '',
  });

  const resetAllErrors = () => {
    dispatch(resetAdvertiserError());
    dispatch(resetAgencyError());
  };

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    resetAllErrors();
    setPayload({
      clientType: '',
      searchType: '',
      searchValue: '',
    });
    setSelectedClient('');
    dispatch(resetAgenciesList());
    dispatch(resetAdvertisersList());
  };

  const unlinkCallback = () => {
    updatePayload({
      clientName: '',
      clientId: '',
      clientType: '',
      ssoId: '',
    });
  };

  return (
    <Box display='flex' width='100%' height='100%'>
      <Card
        sx={{
          flexGrow: 1,
          boxShadow: 4,
          p: 10,
        }}
      >
        <Typography variant='h4' mb={12}>
          Configure User
        </Typography>
        <Box mb={12}>
          <Typography variant='h5' mb={12}>
            Select Client
          </Typography>

          {!confirmUserPayload.clientName ? (
            <LinkUserComponent handleOpen={handleOpen} />
          ) : (
            <RenderAssociatedClient
              unlinkCallback={unlinkCallback}
              clientName={confirmUserPayload.clientName}
              clientId={confirmUserPayload.clientId}
            />
          )}
        </Box>
      </Card>
      <ClientSelectionModal
        open={open}
        handleClose={handleClose}
        reviewUserDetails={reviewUserDetails}
        updatePayload={updatePayload}
        payload={payload}
        confirmUserPayload={confirmUserPayload}
        setConfirmUserPayload={setConfirmUserPayload}
        setPayload={setPayload}
      />
    </Box>
  );
};

export default ConfigureUser;
