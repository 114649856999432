import React, { useState } from 'react';
import SpectrumDialog from '../../../components/Spectrum/Dialog/SpectrumDialog';
import { Box, Button, CircularProgress, Divider, Typography, useTheme } from '@mui/material';
import { formatAmount, formatDate } from '../../../utils/common';
import { DATE_FORMAT } from '../../../constants/common';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import WarningAmberIcon from '@mui/icons-material/WarningAmber';
import {
  IInvoiceOrderItem,
  ITransactionDetails,
} from '../../../redux/transactions/TransactionModel';
import { adjustSchedulePayment } from '../../../redux/transactions/TransactionsSlice';
import { useNavigate } from 'react-router-dom';

interface IEditConfirmationDialog {
  isOpen: boolean;
  onClose: () => void;
  data: ITransactionDetails;
  newTotal: number;
}

const EditConfirmationDialog = ({ isOpen, onClose, data, newTotal }: IEditConfirmationDialog) => {
  const theme = useTheme();
  const navigate = useNavigate();

  const [status, setStatus] = useState('idle');
  const [error, setError] = useState('');

  const handleClose = () => {
    onClose();
  };

  const handleSave = () => {
    setStatus('loading');
    adjustSchedulePayment({ ...data, totalPaidAmount: newTotal })
      .then(() => {
        setStatus('succeeded');
        handleClose();
        navigate(-1);
      })
      .catch((error) => {
        setError(error.error);
        setStatus('failed');
      });
  };

  const changedItems: IInvoiceOrderItem[] = (data?.advertisers || []).reduce(
    (items, advertiser) => {
      advertiser.items.forEach((item) => {
        if (item.oldAmount !== item.newAmount) {
          items.push(item);
        }
      });
      return items;
    },
    [] as IInvoiceOrderItem[],
  );

  const isLoading = status === 'loading';

  return (
    <SpectrumDialog open={isOpen} onClose={handleClose}>
      {error ? (
        <Box>
          <Typography variant='h2' display='flex' alignItems='center'>
            <WarningAmberIcon color='error' sx={{ fontSize: 30, mr: 5 }} />
            Scheduled payment could not be edited
          </Typography>
          <Typography variant='body1' mt={10}>
            We ran into an issue changing or editing this Scheduled Payment. For more assistance
            contact customer support
          </Typography>
          <Box display='flex' justifyContent='flex-end' mt={10}>
            <Button
              variant='text'
              size='small'
              sx={{
                width: 'fit-content',
                padding: 4,
                mr: theme.spacing(8),
              }}
              onClick={onClose}
            >
              Close
            </Button>
          </Box>
        </Box>
      ) : (
        <>
          <Box>
            <Box marginBottom={theme.spacing(8)}>
              <Typography variant='h3' marginBottom={theme.spacing(4)}>
                Edit Schedule Payment
              </Typography>
              <Typography variant='body2'>
                Are you sure you would like to edit these payment amount ?
              </Typography>
            </Box>
            <Box>
              <Box p={5}>
                {changedItems.map((item) => {
                  return (
                    <Box key={item.itemNumber} mt={10} mb={10}>
                      <Box display='flex' alignItems='center' justifyContent='space-between'>
                        <Box width='230px'>
                          <Typography variant='h5'>
                            {item?.type} # {item?.itemNumber}
                          </Typography>
                          <Typography variant='subtitle' fontSize={12}>
                            Due {formatDate(item?.dueDate, DATE_FORMAT)}
                          </Typography>
                        </Box>
                        <Box display='flex' alignItems='center' justifyContent='space-between'>
                          <Box
                            sx={{
                              p: 5,
                              border: `1px solid ${theme.palette.grey[400]}`,
                              width: 180,
                            }}
                          >
                            {formatAmount(item.oldAmount)}
                          </Box>
                          <ArrowForwardIcon color='primary' sx={{ pl: 10, pr: 10 }} />
                          <Box
                            sx={{
                              p: 5,
                              border: `1px solid ${theme.palette.grey[400]}`,
                              width: 180,
                            }}
                          >
                            {formatAmount(item.newAmount)}
                          </Box>
                        </Box>
                      </Box>
                      <Divider sx={{ mt: 5, mb: 5 }} />
                    </Box>
                  );
                })}
              </Box>
            </Box>
            <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 8 }}>
              <Typography variant='h5' color='grey'>
                Previous Total:
              </Typography>
              <Typography variant='h5' color='grey'>
                {formatAmount(data?.totalPaidAmount || 0)}
              </Typography>
            </Box>
            <Box sx={{ display: 'flex', justifyContent: 'space-between', mt: 8, mb: 8 }}>
              <Typography variant='h5' color='primary'>
                New Total:
              </Typography>
              <Typography variant='h5' color='primary'>
                {formatAmount(newTotal || 0)}
              </Typography>
            </Box>
            <Box display='flex' flexDirection='row' justifyContent='end' mt={24}>
              <Button
                variant='outlined'
                size='small'
                sx={{
                  width: 'fit-content',
                  padding: theme.spacing(6, 16),
                  mr: theme.spacing(8),
                }}
                onClick={onClose}
              >
                Cancel
              </Button>
              <Button
                variant='contained'
                size='small'
                sx={{
                  width: 'fit-content',
                  padding: theme.spacing(6, 24),
                }}
                onClick={handleSave}
                disabled={!changedItems.length}
              >
                {isLoading ? (
                  <CircularProgress size={16} sx={{ color: '#fff' }} className='loader' />
                ) : (
                  'Save'
                )}
              </Button>
            </Box>
          </Box>
        </>
      )}
    </SpectrumDialog>
  );
};
export default EditConfirmationDialog;
