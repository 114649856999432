import React, { useState } from 'react';
import { Box, Button, Typography, useTheme, CircularProgress, Alert } from '@mui/material';
import { ColorPartial } from '@mui/material/styles/createPalette';
import { StoreDispatch } from '../../redux/Store';
import { useDispatch } from 'react-redux';
import {
  activateUser,
  deactivateUser,
  fetchRegisteredUserDetails,
} from '../../redux/user/UserSlice';
import { IAPIResponseError } from '../../redux/clients/ClientModel';

interface IActivateDeactivateUserProps {
  isActive: boolean;
  userSsoId: string;
  onClose: () => void;
}

const ActivateDeactivateUser = ({ isActive, userSsoId, onClose }: IActivateDeactivateUserProps) => {
  const theme = useTheme();
  const grey: ColorPartial = theme.palette.grey as ColorPartial;
  const white: ColorPartial = theme.palette.white as ColorPartial;
  const dispatch: StoreDispatch = useDispatch();

  const [activateDeactivateStatus, setActivateDeactivateStatus] = useState<string>('idle');
  const [activateDeactivateError, setActivateDeactivateError] = useState<any>(null);

  const handleDeactivateUser = () => {
    setActivateDeactivateStatus('loading');
    setActivateDeactivateError('');
    deactivateUser(userSsoId)
      .then(() => {
        setActivateDeactivateStatus('succeeded');
      })
      .catch((error: IAPIResponseError) => {
        setActivateDeactivateStatus('failed');
        setActivateDeactivateError(error?.error || 'Failed to deactivate user');
      });
  };
  const handleActivateUser = () => {
    setActivateDeactivateStatus('loading');
    setActivateDeactivateError('');
    activateUser(userSsoId)
      .then(() => {
        setActivateDeactivateStatus('succeeded');
      })
      .catch((error: IAPIResponseError) => {
        setActivateDeactivateStatus('failed');
        setActivateDeactivateError(error?.error || 'Failed to activate user');
      });
  };

  const handleClose = () => {
    dispatch(fetchRegisteredUserDetails(userSsoId));
    onClose();
  };

  const isLoading = activateDeactivateStatus === 'loading';
  const iSuccess = activateDeactivateStatus === 'succeeded';

  return (
    <div>
      <Typography variant='h2'>{isActive ? 'Deactivate User' : 'Activate User'}</Typography>
      {iSuccess ? (
        <>
          <Typography mt={theme.spacing(8)} variant='body1'>
            User {isActive ? 'Deactivated' : 'Activated'} Successfully
          </Typography>
          <Box gap={0} mt={theme.spacing(12)}>
            <Button
              sx={{
                borderRadius: theme.spacing(2),
                fontWeight: 500,
                minWidth: '150px',
              }}
              variant='contained'
              onClick={handleClose}
            >
              Ok
            </Button>
          </Box>
        </>
      ) : (
        <Box>
          <Typography mt={theme.spacing(8)} variant='body1'>
            Are you sure you want to {isActive ? 'Deactivate' : 'Activate'} user ?
          </Typography>
          {activateDeactivateError && (
            <Box my={theme.spacing(6)}>
              <Alert severity='error' variant='outlined'>
                <Typography variant='paragraph2' component='span' color='error'>
                  {activateDeactivateError}
                </Typography>
              </Alert>
            </Box>
          )}
          <Box gap={0} mt={theme.spacing(12)}>
            <Button
              sx={{
                borderRadius: theme.spacing(2),
                fontWeight: 500,
                minWidth: '150px',
              }}
              variant='contained'
              onClick={isActive ? handleDeactivateUser : handleActivateUser}
            >
              {isLoading ? (
                <CircularProgress style={{ color: white[500], width: 30, height: 30 }} />
              ) : (
                'Yes'
              )}
            </Button>
            <Button
              color='secondary'
              sx={{
                fontWeight: 500,
                padding: theme.spacing(8, 12),
              }}
              disabled={isLoading}
              onClick={onClose}
            >
              <Typography color={grey[500]}>Cancel</Typography>
            </Button>
          </Box>
        </Box>
      )}
    </div>
  );
};

export default ActivateDeactivateUser;
