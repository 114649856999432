import { styled } from '@mui/material/styles';
import { ColorPartial } from '@mui/material/styles/createPalette';
import { Menu } from '@mui/material';

export const StyledContainer = styled('div')(() => {
  return {
    display: 'flex',
    alignItems: 'center',
  };
});
export const StyledMenuPopover = styled(Menu)(({ theme }) => {
  const grey: ColorPartial = theme.palette.grey as ColorPartial;
  return {
    '.MuiMenu-paper': {
      minWidth: theme.spacing(100),
    },
    '.logout-link': {
      color: grey[500],
    },
  };
});
