import React, { useEffect, useRef } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Alert, Box, Typography, useTheme } from '@mui/material';

import { formatDate, formatStringToDate } from '../../utils/common';
import { RootState, StoreDispatch } from '../../redux/Store';
import { IClient } from '../../redux/clients/ClientModel';
import { fetchClients, updateFilters } from '../../redux/clients/ClientSlice';
import { fetchUserStatusList } from '../../redux/user/UserSlice';
import { DATE_FORMAT } from '../../constants/common';
import { PATHS } from '../../routes/Routes';

import SpectrumTable, { Order } from '../../components/Spectrum/Table/SpectrumTable';
import StatusCell from '../../components/Spectrum/Table/StatusCell';
import KebabMenu from '../../components/Spectrum/Table/KebabMenu';
import { useGetCurrentFilters } from '../../utils/customUtilHooks';

const ClientTable = () => {
  const theme = useTheme();
  const dispatch: StoreDispatch = useDispatch();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const isInitialMount = useRef(true);
  const {
    data,
    status,
    error,
    filters,
    filters: { sortDirection, sortColumn, length },
  } = useSelector((state: RootState) => state.clientData);
  const { userStatusListStatus, userStatusListStatusError } = useSelector((state: RootState) => state.userData)

  const columns = [
    {
      label: 'Client',
      sortKey: 'Client',
      accessorKey: 'client',
      width: '40px',
      render: (data: IClient) => (
        <div>
          <strong>{data.client}</strong>
          <Typography color='primary' variant='underline'>
            <strong>{data.clientId}</strong>
          </Typography>
        </div>
      ),
    },
    {
      label: 'Account Owner',
      sortKey: 'AccountOwner',
      accessorKey: 'accountOwner',
      width: '40px',
      render: (data: IClient) => (
        <div>
          <strong>{data.accountOwner}</strong>
          <Typography variant='subtitle2'>{data.accountOwnerUserName}</Typography>
        </div>
      ),
    },
    {
      label: 'Email',
      sortKey: 'Email',
      accessorKey: 'email',
    },
    {
      label: 'Status',
      sortKey: 'Status',
      accessorKey: 'status',
      render: (data: IClient) => <StatusCell status={data.status} />,
      width: '10px',
    },
    {
      label: 'Created',
      sortKey: 'CreatedDate',
      accessorKey: 'createdDate',
      render: (data: IClient) => formatDate(data.createdDate, DATE_FORMAT),
      width: '20px',
    },
    {
      label: '',
      sortKey: '',
      accessorKey: '',
      render: (data: IClient) => (
        <KebabMenu
          options={[
            {
              label: 'View Client Details',
              onClick: () => navigate(`${PATHS.CLIENTS}/${data.ssoId}?companyId=${data.companyId}&companyType=${data.companyType}`),
            },
          ]}
        />
      ),
      width: '10px',
    },
  ];

  const handleSort = (_: React.MouseEvent<unknown>, property: string) => {
    const isAsc = sortColumn === property && sortDirection === 'asc';
    const changedSortDirection = isAsc ? 'desc' : 'asc';
    dispatch(
      updateFilters({ ...filters, sortDirection: changedSortDirection, sortColumn: property }),
    );
  };
  const handleChangeItemsPerPage = (items: number) => {
    dispatch(updateFilters({ ...filters, start: 0, length: items }));
  };
  const handlePageChange = (pageNo: number) => {
    dispatch(updateFilters({ ...filters, start: pageNo * filters.length }));
  };
  useEffect(() => {
    const currentFiltersData = useGetCurrentFilters(searchParams, filters, formatStringToDate)
    // update to store
    dispatch(updateFilters({ ...currentFiltersData }));
    dispatch(fetchUserStatusList());
  }, []);

  useEffect(() => {
    if (isInitialMount.current) {
      isInitialMount.current = false;
      return;
    }
    const updatedFilters: any = filters;
    // Setting applied values to URL
    const params = new URLSearchParams();
    Object.keys(updatedFilters).forEach((filterKey) => {
      if (updatedFilters[filterKey]) {
        params.append(filterKey, updatedFilters[filterKey] as string);
      }
    });
    navigate({ search: params.toString() }, { replace: true });
    dispatch(fetchClients(filters));
  }, [filters, isInitialMount.current]);

  const isLoading = status === 'loading' || userStatusListStatus === 'loading';

  return (
    <>
      {status === 'failed' && (
        <Box mb={theme.spacing(12)}>
          <Alert severity='error' variant='outlined'>
            <Typography variant='paragraph2' component='span' color='error'>
              {error}
            </Typography>
          </Alert>
        </Box>
      )}
      {userStatusListStatus === 'failed' && (
        <Box mb={theme.spacing(12)}>
          <Alert severity='error' variant='outlined'>
            <Typography variant='paragraph2' component='span' color='error'>
              {userStatusListStatusError}
            </Typography>
          </Alert>
        </Box>
      )}
      <SpectrumTable
        columns={columns}
        data={data?.data}
        totalItems={data?.totalRecords || 0}
        onSort={handleSort}
        orderBy={sortColumn}
        order={sortDirection as Order}
        itemsPerPage={length}
        currentPage={filters.start / filters.length}
        loading={isLoading}
        onPageChange={handlePageChange}
        onItemsPerPageChange={handleChangeItemsPerPage}
      />
    </>
  );
};

export default ClientTable;
