import React from 'react';
import { Typography } from '@mui/material';
import Avatar from '../Avatar/Avatar';
import { StyledContainer } from './AvatarLinkStyles';
import EastIcon from '@mui/icons-material/East';

interface IAvatarLinkProps {
  avatarLabel?: string;
  label?: string;
}

const AvatarLink = ({ avatarLabel = '', label = '' }: IAvatarLinkProps) => {
  return (
    <StyledContainer>
      <Avatar size='small' bgColor='secondary'>
        {avatarLabel}
      </Avatar>
      <Typography className='link-label' variant='paragraph2' color='black'>
        {label}
      </Typography>
      <EastIcon className='link-icon' />
    </StyledContainer>
  );
};
export default AvatarLink;
