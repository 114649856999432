import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { Box, Typography, useTheme, Container } from '@mui/material';

import { peopleEndpoints } from '../../constants/apiConstants';
import Export from '../../components/Export';
import PeopleFilters from './PeopleFilters';
import PeopleTable from './PeopleTable';
import { RootState } from '../../redux/Store';
import { updateFilters } from '../../redux/user/UserSlice';
import { PATHS } from '../../routes/Routes';

const People = () => {
  const theme = useTheme();
  const { pathname } = useLocation();
  const isClientDetailPage = pathname.search(PATHS.CLIENTS) > -1;
  const { filters } = useSelector((state: RootState) => state.userData);
  const dispatch = useDispatch();

  useEffect(() => {
    return () => {
      dispatch(
        updateFilters({
          start: 0,
          length: 10,
          sortColumn: '',
          sortDirection: '',
          searchValue: '',
          startDate: null,
          endDate: null,
          status: 0,
          role: 0,
          companyId: null,
          companyType: null
        }),
      );
    };
  }, []);

  return (
    <Container sx={{
      maxWidth: 1450,
      width: '100%',
      padding: theme.spacing(10),
    }}>
      <Box display='flex' justifyContent='space-between' mb={theme.spacing(12)}>
        <Typography variant='h1'>People</Typography>
        {!isClientDetailPage && (
          <Export
            label='Export Report'
            endpoint={peopleEndpoints.export}
            filters={filters}
            filename='users'
          />
        )}
      </Box>
      <Box sx={{ mb: 6 }}>
        <PeopleFilters />
      </Box>
      <PeopleTable />
    </Container>
  );
};

export default People;
