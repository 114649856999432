import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Box, Typography, useTheme } from '@mui/material';

import { RootState } from '../../redux/Store';

import { adPortalTransactionEndpoints } from '../../constants/apiConstants';

import PageContentContainer from '../../components/PageContentContainer/PageContentContainer';
import Export from '../../components/Export';
import AdPortalTransactions from './AdPortalTransactions';
import AdPortalFilters from './AdPortalFilters';
import { updateFilters } from '../../redux/adPortalTransaction/AdPortalTransactionSlice';

const AdPortal = () => {
  const theme = useTheme();
  const { filters } = useSelector((state: RootState) => state.adPortalTransactionsData);
  const dispatch = useDispatch();

  useEffect(() => {
    return () => {
      dispatch(
        updateFilters({
          start: 0,
          length: 10,
          sortColumn: '',
          sortDirection: '',
          searchValue: '',
          startDate: null,
          endDate: null
        }),
      );
    };
  }, []);

  return (
    <PageContentContainer>
      <Box display='flex' justifyContent='space-between' mb={theme.spacing(12)}>
        <Typography variant='h1'>Adportal Transactions</Typography>
        <Export
          label='Export Report'
          endpoint={adPortalTransactionEndpoints.export}
          filters={filters}
          filename='AdportalTransactions'
        />
      </Box>
      <Box mb={theme.spacing(3)}>
        <AdPortalFilters />
      </Box>
      <AdPortalTransactions />
    </PageContentContainer>
  );
};

export default AdPortal;
