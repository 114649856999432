import React from 'react';
import { Box, Typography, useTheme } from '@mui/material';
import TransactionFilters from '../Transactions/TransactionFilters';
import TransactionsTable from '../Transactions/TransactionsTable';

const TransactionsHistory = () => {
  const theme = useTheme();

  return (
    <Box mt={theme.spacing(16)}>
      <Typography variant='h2' mb={theme.spacing(8)}>Transaction History</Typography>
      <Box sx={{ mb: 6 }}>
        <TransactionFilters />
      </Box>
      <TransactionsTable />
    </Box>
  );
};

export default TransactionsHistory;
