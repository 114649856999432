import { styled } from '@mui/material/styles';
import { AppBar } from '@mui/material';
import { ColorPartial } from '@mui/material/styles/createPalette';

export const StyledAppBar = styled(AppBar)(({ theme }) => {
  const primary: ColorPartial = theme.palette.primary as ColorPartial;
  const white: ColorPartial = theme.palette.white as ColorPartial;
  const black: ColorPartial = theme.palette.black as ColorPartial;
  return {
    background: white[500],
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    flexDirection: 'row',
    height: theme.spacing(40),
    padding: theme.spacing(0, 16),
    '.logo-container': {
      display: 'flex',
      alignItems: 'center',
      '.project-logo': {
        marginLeft: theme.spacing(5),
        paddingLeft: theme.spacing(5),
        color: black[50],
        textAlign: 'left',
        textTransform: 'uppercase',
        borderLeft: `1px solid ${black[50]}`,
      },
    },
    '.links-container': {
      flexGrow: 1,
      textAlign: 'center',
      textTransform: 'uppercase',
      fontWeight: 500,
      '.link': {
        display: 'inline-block',
        textAlign: 'center',
        lineHeight: theme.spacing(38),
        height: theme.spacing(38),
        textDecoration: 'none',
        margin: theme.spacing(0, 10),
        outline: 'none',
        color: black[50],
        '&:hover': {
          cursor: 'pointer',
          borderBottom: `${theme.spacing(2)} solid ${primary[200]}`,
          color: black[500],
        },
        '&.link-active': {
          borderBottom: `${theme.spacing(2)} solid ${primary[200]}`,
          color: black[500],
        },
      },
    },
    '.right': {
      display: 'flex',
      alignItems: 'center',
    },
  };
});
