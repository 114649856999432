import { Accordion, AccordionDetails, AccordionSummary, styled } from '@mui/material';

export const StyledAccordionSummary = styled(AccordionSummary)(({ theme }) => ({
  padding: theme.spacing(0, 8, 0, 4),
  flexDirection: 'row-reverse',
  gap: theme.spacing(4),
  '&.Mui-expanded': {
    minHeight: 'auto'
  },
  '& .MuiAccordionSummary-expandIconWrapper': {
    alignSelf: 'start',
    marginTop: theme.spacing(6),
    transform: 'rotate(270deg)',
  },
  '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
    transform: 'rotate(360deg)',
  },
  '& .MuiAccordionSummary-content': {
    flexDirection: 'column',
  },
  '& .MuiAccordionSummary-content.Mui-expanded': {
    minHeight: 'auto',
    margin: theme.spacing(6, 0),
  },
  '&.Mui-focusVisible': {
    backgroundColor: theme.palette.common.white,
  },
}));

export const StyledAccordion = styled(Accordion)(({ theme }) => ({
  border: `${theme.spacing(0.5)} solid ${theme.palette.grey[400]}`,
  marginBottom: theme.spacing(6),
  '&.Mui-expanded': {
    '&:last-of-type': {
      margin: theme.spacing(6, 0),
    },
    '&:first-of-type': {
      margin: theme.spacing(6, 0),
    },
  },
  '&:last-of-type': {
    borderRadius: 0,
  },
  '&:first-of-type': {
    borderRadius: 0,
  },
}));

export const StyledAccordionDetails = styled(AccordionDetails)(({ theme }) => ({
  padding: `${theme.spacing(1)} ${theme.spacing(8)} ${theme.spacing(1)} ${theme.spacing(14)}`,
}));
