import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState, StoreDispatch } from '../../redux/Store';
import { fetchReportsToUsers } from '../../redux/user/UserSlice';
import SpectrumSelectField from '../../components/Spectrum/Select/SpectrumSelect';
import { MenuItem } from '@mui/material';

const ReportsToUserSelection = ({
  companyType,
  companyId,
  selectedRoleId,
  reportsToSSOId,
  handleChange,
  isDisabled = false,
  error,
}: {
  companyType: string;
  companyId: number;
  selectedRoleId: number;
  reportsToSSOId: string;
  isDisabled?: boolean;
  error: string;
  handleChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
}) => {
  const { reportsToUsers, userDetails } = useSelector((state: RootState) => state.userData);
  const dispatch: StoreDispatch = useDispatch();

  useEffect(() => {
    if (companyType) {
      dispatch(
        fetchReportsToUsers({
          clientId: companyId,
          clientType: companyType,
          ssoId: userDetails?.ssoId || ''
        }),
      );
    }
  }, [companyId, companyType]);

  // Report to user always should be greater than selected role, so filtering out users based on selected role
  const filteredUsers = selectedRoleId
    ? (reportsToUsers || []).filter((user) => {
        return user?.tblUserRole?.id < selectedRoleId;
      })
    : reportsToUsers;

  return (
    <SpectrumSelectField
      variant='outlined'
      size='small'
      name='reportsToSSOId'
      value={reportsToSSOId}
      onChange={handleChange}
      label='Reports To'
      placeholder='Select User'
      disabled={isDisabled}
      error={error}
      helperText={error}
    >
      {filteredUsers.map(({ id, cognitoUserId, firstName, lastName, tblUserRole }) => (
        <MenuItem key={id} value={cognitoUserId}>
          {firstName} {lastName} ({tblUserRole?.name})
        </MenuItem>
      ))}
    </SpectrumSelectField>
  );
};

export default ReportsToUserSelection;
