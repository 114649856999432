import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { clientEndpoints, peopleEndpoints } from '../../constants/apiConstants';
import ApiService from '../../services/ApiService';
import {
  IConfirmReviewUserReqPayload,
  IFilterPayload,
  IUserRequestPayload,
  IUserResponse,
  IUsersState,
  IUpdateReviewUserClients,
  IClientDetailsResponse,
  IClientDetailsPayload,
} from './UserModel';

const initialState: IUsersState = {
  status: 'idle',
  error: '',
  data: {} as IUserResponse,
  // Agency List
  agencyList: [],
  agencyListError: null,
  agencyListStatus: 'idle',
  // Advertisers List
  advertiserList: [],
  advertiserListError: null,
  advertiserListStatus: 'idle',
  // Agency Advertiser List
  agencyAdvertiserListStatus: 'idle',
  agencyAdvertiserList: [],
  agencyAdvertiserListStatusError: '',
  // Report To
  reportsToUsersStatus: 'idle',
  reportsToUsers: [],
  reportsToUsersStatusError: '',
  // User Roles
  userRolesStatus: 'idle',
  userRoles: [],
  userRolesStatusError: '',
  // Reset User Password
  resetPasswordStatus: 'idle',
  resetPasswordError: '',
  // Review User Details
  reviewUserDetails: null,
  reviewUserDetailsStatus: '',
  reviewUserDetailsError: '',
  //User Details
  userDetails: null,
  userDetailsStatus: '',
  userDetailsStatusError: '',
  userStatusList: [],
  userStatusListStatus: 'idle',
  userStatusListStatusError: '',
  scheduleAutoPayments: [],
  filters: {
    start: 0,
    length: 10,
    sortColumn: '',
    sortDirection: '',
    searchValue: '',
    startDate: '',
    endDate: '',
    status: 0,
    role: 0,
  },
  // Selected Client Details
  selectedClientDetailsStatus: 'idle',
  selectedClientDetailsResponse: {} as IClientDetailsResponse,
  selectedClientDetailsError: '',
};

export const fetchRegisteredUsers = createAsyncThunk(
  'People/GetRegisteredUsers',
  async (payload: IFilterPayload) => {
    const { companyId, companyType, role, ...updatedFilters } = payload;
    const response = await ApiService.postData(peopleEndpoints.list, {}, updatedFilters);
    return response.data;
  },
);

export const fetchClientPeople = createAsyncThunk(
  'Client/GetPeopleDetails',
  async (payload: IFilterPayload) => {
    const response = await ApiService.postData(clientEndpoints.people, {}, payload);
    return response.data;
  },
);

export const activateUser = (ssoId: string) => {
  return ApiService.getData(peopleEndpoints.activateUserURL, { ssoId });
};
export const deactivateUser = (ssoId: string) => {
  return ApiService.getData(peopleEndpoints.deactivateUserURL, { ssoId });
};
export const resetUserPassword = createAsyncThunk(
  'People/ResetUserPassword',
  async (ssoId: string) => {
    const response = await ApiService.getData(peopleEndpoints.resetPassword, {
      ssoId,
    });
    return response.data;
  },
);

export const fetchUserStatusList = createAsyncThunk('People/GetUserStatus', async () => {
  const response = await ApiService.getData(peopleEndpoints.userStatusList);
  return response.data;
});

export const fetchUserRoles = createAsyncThunk('People/GetUserRoles', async () => {
  const response = await ApiService.getData(peopleEndpoints.userRoles);
  return response.data;
});

export const fetchRegisteredUserDetails = createAsyncThunk(
  'People/GetSelectedUserDetails',
  async (payload: string) => {
    const response = await ApiService.getData(peopleEndpoints.detail, {
      ssoId: payload,
    });
    return response.data;
  },
);
export const fetchReviewUserDetails = createAsyncThunk(
  'People/ReviewUserDetails',
  async (payload: string) => {
    const response = await ApiService.getData(peopleEndpoints.reviewUserDetailsURL, {
      ssoId: payload,
    });
    return response.data;
  },
);
export const confirmReviewUserDetails = (payload: IConfirmReviewUserReqPayload) => {
  return ApiService.postData(peopleEndpoints.confirmReviewUserDetailsURL, {}, payload);
};

export const fetchAdvertisersOfAgency = createAsyncThunk(
  'People/GetAdvertisersOfAgency',
  async (payload: string) => {
    const response = await ApiService.getData(peopleEndpoints.agencyAdvertisers, {
      agencyId: payload,
    });
    return response.data;
  },
);
export const fetchAgenciesList = createAsyncThunk('People/GetAgencies', async () => {
  const response = await ApiService.getData(peopleEndpoints.agenciesList);
  return response.data;
});
export const fetchAdvertisersList = createAsyncThunk('People/GetAdvertisers', async () => {
  const response = await ApiService.getData(peopleEndpoints.advertisersList);
  return response.data;
});

export const fetchReportsToUsers = createAsyncThunk(
  'People/GetReportsToUsers',
  async (payload: { clientId: number; clientType: string; ssoId: string }) => {
    const response = await ApiService.postData(peopleEndpoints.reportsToUsers, {}, payload);
    return response.data;
  },
);

export const updateUserDetails = (payload: IUserRequestPayload) => {
  return ApiService.postData(peopleEndpoints.updateDetails, {}, payload);
};

export const postFetchAgencies = createAsyncThunk(
  'People/GetAgencies',
  async (payload: IUpdateReviewUserClients, { rejectWithValue }) => {
    try {
      const response = await ApiService.postData(peopleEndpoints.agenciesList, {}, payload);
      return response.data.data.agencies;
    } catch (err: any) {
      if (err.error) {
        return rejectWithValue(err.error);
      }
      throw err;
    }
  },
);

export const postFetchAdvertisers = createAsyncThunk(
  'People/GetAdvertisers',
  async (payload: IUpdateReviewUserClients, { rejectWithValue }) => {
    try {
      const response = await ApiService.postData(peopleEndpoints.advertisersList, {}, payload);
      return response.data.data.advertisers;
    } catch (err: any) {
      if (err.error) {
        return rejectWithValue(err.error);
      }
      throw err;
    }
  },
);

export const getSelectedClientDetails = createAsyncThunk(
  'People/GetSelectedClientDetails',
  async (payload: IClientDetailsPayload) => {
    const response = await ApiService.postData(peopleEndpoints.selectedClientDetails, {}, payload);
    return response.data;
  },
);

export const UsersSlice = createSlice({
  name: 'people',
  initialState,
  reducers: {
    updateFilters: (state, action) => {
      state.filters = action?.payload;
    },
    setResetPasswordStatus: (state, action) => {
      state.resetPasswordStatus = action?.payload;
      state.resetPasswordError = null;
    },
    resetFilters: (state) => {
      state.filters = initialState.filters;
    },
    resetAgenciesList: (state) => {
      state.agencyList = initialState.agencyList;
    },
    resetAdvertisersList: (state) => {
      state.advertiserList = initialState.advertiserList;
    },
    resetAdvertiserError: (state) => {
      state.advertiserListError = initialState.advertiserListError;
    },
    resetAgencyError: (state) => {
      state.agencyListError = initialState.agencyListError;
    },
  },
  extraReducers(builder) {
    builder
      .addCase(fetchRegisteredUsers.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchRegisteredUsers.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.data = action.payload.data;
      })
      .addCase(fetchRegisteredUsers.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action?.error?.message || 'Something went wrong, please try again later';
      })
      .addCase(fetchClientPeople.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchClientPeople.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.data = action.payload.data;
      })
      .addCase(fetchClientPeople.rejected, (state, action) => {
        state.status = 'failed';
        state.data = {} as IUserResponse;
        state.error = action?.error?.message || 'Something went wrong, please try again later';
      })
      .addCase(fetchUserStatusList.pending, (state) => {
        state.userStatusListStatus = 'loading';
      })
      .addCase(fetchUserStatusList.fulfilled, (state, action) => {
        state.userStatusListStatus = 'succeeded';
        state.userStatusList = action.payload.data;
        state.userStatusListStatusError = '';
      })
      .addCase(fetchUserStatusList.rejected, (state, action) => {
        state.userStatusListStatus = 'failed';
        state.userStatusListStatusError =
          action?.error?.message || 'Failed to fetch user status list';
      })
      .addCase(fetchUserRoles.pending, (state, action) => {
        state.userRolesStatus = 'loading';
      })
      .addCase(fetchUserRoles.fulfilled, (state, action) => {
        state.userRolesStatus = 'succeeded';
        state.userRoles = action.payload.data;
      })
      .addCase(fetchUserRoles.rejected, (state, action) => {
        state.userRolesStatus = 'failed';
        state.userRolesStatusError = action?.error?.message || 'Failed to fetch user roles';
      })
      .addCase(resetUserPassword.pending, (state) => {
        state.resetPasswordStatus = 'loading';
        state.resetPasswordError = '';
      })
      .addCase(resetUserPassword.fulfilled, (state) => {
        state.resetPasswordStatus = 'succeeded';
      })
      .addCase(resetUserPassword.rejected, (state, action) => {
        state.resetPasswordStatus = 'failed';
        state.resetPasswordError = action?.error?.message || 'Failed to send reset password email';
      })
      .addCase(fetchRegisteredUserDetails.pending, (state) => {
        state.userDetailsStatus = 'loading';
        state.agencyAdvertiserListStatusError = '';
        state.userRolesStatusError = '';
        state.reportsToUsersStatusError = '';
      })
      .addCase(fetchRegisteredUserDetails.fulfilled, (state, action) => {
        state.userDetailsStatus = 'succeeded';
        state.userDetails = action.payload.data;
      })
      .addCase(fetchRegisteredUserDetails.rejected, (state) => {
        state.userDetailsStatus = 'failed';
        state.userDetailsStatusError = 'Failed to fetch registered user details';
        state.userDetails = null;
      })
      .addCase(fetchReviewUserDetails.pending, (state) => {
        state.reviewUserDetailsStatus = 'loading';
        state.reviewUserDetailsError = '';
        state.reviewUserDetails = null;
      })
      .addCase(fetchReviewUserDetails.fulfilled, (state, action) => {
        state.reviewUserDetailsStatus = 'succeeded';
        state.reviewUserDetails = action.payload.data;
      })
      .addCase(fetchReviewUserDetails.rejected, (state) => {
        state.reviewUserDetailsStatus = 'failed';
        state.reviewUserDetailsError = 'Failed to fetch user details';
        state.reviewUserDetails = null;
      })
      .addCase(fetchAdvertisersOfAgency.pending, (state, action) => {
        state.agencyAdvertiserListStatus = 'loading';
        state.agencyAdvertiserList = [];
      })
      .addCase(fetchAdvertisersOfAgency.fulfilled, (state, action) => {
        state.agencyAdvertiserListStatus = 'succeeded';
        state.agencyAdvertiserList = action.payload.data;
      })
      .addCase(fetchAdvertisersOfAgency.rejected, (state, action) => {
        state.agencyAdvertiserListStatus = 'failed';
        state.agencyAdvertiserListStatusError =
          action?.error?.message || 'Failed to fetch agency advertisers';
      })
      .addCase(fetchReportsToUsers.pending, (state, action) => {
        state.reportsToUsersStatus = 'loading';
        state.reportsToUsers = [];
      })
      .addCase(fetchReportsToUsers.fulfilled, (state, action) => {
        state.reportsToUsersStatus = 'succeeded';
        state.reportsToUsers = action.payload.data;
      })
      .addCase(fetchReportsToUsers.rejected, (state, action) => {
        state.reportsToUsersStatus = 'failed';
        state.reportsToUsersStatusError =
          action?.error?.message || 'Failed to fetch reporting users';
      })
      .addCase(postFetchAgencies.pending, (state) => {
        state.agencyListStatus = 'loading';
        state.agencyListError = '';
      })
      .addCase(postFetchAgencies.fulfilled, (state, action) => {
        state.agencyListStatus = 'succeeded';
        state.agencyList = action.payload;
      })
      .addCase(postFetchAgencies.rejected, (state, action: any) => {
        state.agencyListStatus = 'failed';
        state.agencyListError = action.payload || 'Failed to fetch Agencies';
        state.agencyList = [];
      })
      .addCase(postFetchAdvertisers.pending, (state) => {
        state.advertiserListStatus = 'loading';
        state.advertiserListError = '';
      })
      .addCase(postFetchAdvertisers.fulfilled, (state, action) => {
        state.advertiserListStatus = 'succeeded';
        state.advertiserList = action.payload;
      })
      .addCase(postFetchAdvertisers.rejected, (state, action: any) => {
        state.advertiserListStatus = 'failed';
        state.advertiserListError = action.payload || 'Failed to fetch Advertisers';
        state.advertiserList = [];
      })
      .addCase(getSelectedClientDetails.pending, (state) => {
        state.selectedClientDetailsStatus = 'loading';
        state.selectedClientDetailsError = '';
      })
      .addCase(getSelectedClientDetails.fulfilled, (state, action) => {
        state.selectedClientDetailsStatus = 'succeeded';
        state.selectedClientDetailsResponse = action.payload.data;
      })
      .addCase(getSelectedClientDetails.rejected, (state) => {
        state.selectedClientDetailsStatus = 'failed';
        state.selectedClientDetailsError = 'Failed to fetch selected client details';
      });
  },
});

export const {
  updateFilters,
  setResetPasswordStatus,
  resetFilters,
  resetAgenciesList,
  resetAdvertisersList,
  resetAdvertiserError,
  resetAgencyError,
} = UsersSlice.actions;
export default UsersSlice.reducer;
