import React, { useState } from 'react';
import {
  Box,
  Button,
  Typography,
  useTheme,
  CircularProgress,
  Alert,
  Collapse,
} from '@mui/material';
import { ColorPartial } from '@mui/material/styles/createPalette';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import CloseIcon from '@mui/icons-material/Close';
import { IConfirmReviewUserReqPayload } from '../../../redux/user/UserModel';
import { confirmReviewUserDetails } from '../../../redux/user/UserSlice';
import { PATHS } from '../../../routes/Routes';
import { useNavigate } from 'react-router-dom';

interface IDetailsConfirmationProps {
  onClose: () => void;
  confirmDetails: IConfirmReviewUserReqPayload;
}

const DetailsConfirmation = ({ confirmDetails, onClose }: IDetailsConfirmationProps) => {
  const theme = useTheme();
  const navigate = useNavigate();
  const blue: ColorPartial = theme.palette.blue as ColorPartial;
  const grey: ColorPartial = theme.palette.grey as ColorPartial;
  const white: ColorPartial = theme.palette.white as ColorPartial;

  const [expandStatus, setExpandStatus] = useState(false);
  const [confirmDetailsLoading, setConfirmDetailsLoading] = useState(false);
  const [confirmError, setConfirmError] = useState('');

  const handleConfirmApproval = () => {
    setConfirmDetailsLoading(true);
    setConfirmError('');
    confirmReviewUserDetails(confirmDetails)
      .then(() => {
        setConfirmError('');
        setConfirmDetailsLoading(false);
        navigate(`${PATHS.PEOPLE}/${confirmDetails?.ssoId}`, { replace: true });
      })
      .catch((error) => {
        setConfirmError(error?.error || 'Failed to confirm user details');
        setConfirmDetailsLoading(false);
      });
  };

  const isLoading = confirmDetailsLoading;

  return (
    <div>
      <Typography variant='eyebrow'>Approve User</Typography>
      <Typography variant='h2'>User Confirmation</Typography>
      <Box>
        <Typography mt={6} mb={6} variant='body1'>
          Are you ready to approve this user ?
        </Typography>
        {confirmError && (
          <Box my={theme.spacing(6)}>
            <Alert severity='error' variant='outlined'>
              <Typography variant='paragraph2' component='span' color='error'>
                {confirmError}
              </Typography>
            </Alert>
          </Box>
        )}
        <Box
          sx={{
            background: blue[100],
            border: `1px solid ${blue[200]}`,
            p: 5,
            cursor: 'pointer',
          }}
        >
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
            }}
            onClick={() => setExpandStatus(!expandStatus)}
          >
            <Typography variant='h6'>How Roles are Decided</Typography>
            <ExpandMoreIcon sx={{ transform: !expandStatus ? 'rotate(0deg)' : 'rotate(180deg)' }} />
          </Box>
          <Collapse in={expandStatus} timeout='auto' unmountOnExit>
            <Typography mt={4}>
              The very first user assigned to an account will be delegated to the{' '}
              <strong>Account Owner</strong> role and will have full access to manage this client’s
              account within the Client Portal.
              <br />
              <br />
              All other users will automatically default to <strong>Team Member</strong> and will
              have limited access to the client’s account. The account owner of the account will
              need to assign this user’s access. An email will automatically be sent to the account
              owner to review this user.
            </Typography>
          </Collapse>
        </Box>
        <Box mt={theme.spacing(12)}>
          <Button
            sx={{
              borderRadius: theme.spacing(2),
              fontWeight: 500,
              minWidth: '150px',
            }}
            variant='contained'
            onClick={handleConfirmApproval}
          >
            {isLoading ? (
              <CircularProgress style={{ color: white[500], width: 30, height: 30 }} />
            ) : (
              'Confirm Approval'
            )}
          </Button>
          <Button
            color='secondary'
            sx={{
              fontWeight: 500,
              padding: theme.spacing(8, 12),
              ml: 5,
            }}
            disabled={isLoading}
            onClick={onClose}
          >
            <CloseIcon sx={{ color: grey[500], mr: 4, fontSize: '18px' }} />
            <Typography color={grey[500]}>Cancel</Typography>
          </Button>
        </Box>
      </Box>
    </div>
  );
};

export default DetailsConfirmation;
