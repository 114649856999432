import React from 'react';
import { Container, useTheme } from '@mui/material';

interface IProps {
  children: React.ReactNode;
  className?: string;
}

const PageContentContainer = ({ children, className = '' }: IProps) => {
  const theme = useTheme();
  return (
    <>
      <Container
        className={className}
        sx={{
          minHeight: 'calc(100vh - 156px)', // Header (80px) + Footer Height (76px)
          maxWidth: 1450,
          width: '100%',
          padding: theme.spacing(10),
        }}
      >
        {children}
      </Container>
    </>
  );
};

export default PageContentContainer;
