import React from 'react';
import { Box, Card, Divider, Link, Typography, useTheme } from '@mui/material';
import { ColorPartial } from '@mui/material/styles/createPalette';
import { Email, Phone } from '@mui/icons-material';
import { IAdvertiser, IUser } from '../../redux/user/UserModel';

import RoleCell from '../../components/Spectrum/Table/RoleCell';

import { formatPhoneNumber } from '../../utils/common';
import UserNotes from './UserNotes';

interface IBasicDetailsProps {
  userDetails: IUser;
}

const BasicDetails = ({ userDetails }: IBasicDetailsProps) => {
  const theme = useTheme();
  const black: ColorPartial = theme.palette.black as ColorPartial;
  const blue: ColorPartial = theme.palette.blue as ColorPartial;
  const primary: ColorPartial = theme.palette.primary as ColorPartial;

  return (
    <Card
      sx={{
        flexGrow: 1,
      }}
    >
      <Box sx={{ background: blue[100], border: `1px solid ${blue[200]}`, p: 10 }}>
        <Typography variant='h4' mb={12}>
          User Details
        </Typography>
        <Box mb={12}>
          <Typography color={black[500]} variant='eyebrow'>
            Contact Information
          </Typography>
          <Link
            href={`mailto:${userDetails.userEmail}`}
            display='flex'
            padding={theme.spacing(4)}
            gap={theme.spacing(4)}
            alignItems='center'
          >
            <Email color='primary' fontSize='small' />
            <Typography variant='body1' color={black[500]} sx={{ textDecoration: 'underline' }}>
              {userDetails?.userEmail}
            </Typography>
          </Link>
          <Box display='flex' padding={theme.spacing(4)} gap={theme.spacing(4)} alignItems='center'>
            <Phone color='primary' fontSize='small' />
            <Typography variant='body1' color={black[500]}>
              {userDetails?.userPhone && formatPhoneNumber(userDetails.userPhone)}
            </Typography>
          </Box>
        </Box>
        <Box>
          <Divider sx={{ borderColor: primary[100] }} />
          <Box display='flex' flexDirection='column' gap={theme.spacing(12)} mt={theme.spacing(8)}>
            <Box>
              <Box mb={12}>
                <Typography color={black[500]} variant='eyebrow' mb={theme.spacing(4)}>
                  Client
                </Typography>
                <Box display='flex' gap={theme.spacing(4)}>
                  <Typography
                    variant='body1'
                    color={primary[500]}
                    sx={{ textDecoration: 'underline' }}
                  >
                    {userDetails?.client}
                  </Typography>
                  {userDetails.clientId && (
                    <Typography variant='paragraph2'>(#{userDetails.clientId})</Typography>
                  )}
                </Box>
              </Box>
              {userDetails?.role && (
                <Box mb={12}>
                  <Typography color={black[500]} variant='eyebrow' mb={theme.spacing(4)}>
                    Role
                  </Typography>
                  <RoleCell role={userDetails?.role?.name} />
                </Box>
              )}
              <Box mb={12}>
                <Typography color={black[500]} variant='eyebrow' mb={theme.spacing(4)}>
                  Reports to
                </Typography>
                <Box display='flex' gap={theme.spacing(4)}>
                  <Typography variant='body1'>
                    {userDetails?.reportsToFirstName} {userDetails?.reportsToLastName}
                  </Typography>
                  {userDetails?.reportsToUserName && (
                    <Typography
                      variant='body1'
                      color={primary[500]}
                      sx={{ textDecoration: 'underline' }}
                    >
                      @{userDetails.reportsToUserName}
                    </Typography>
                  )}
                </Box>
              </Box>
            </Box>
            <Box display='flex' flexDirection='column' gap={theme.spacing(4)}>
              <Typography color={black[500]} variant='eyebrow'>
                Account Access
              </Typography>
              <Box sx={{ maxHeight: '300px', overflow: 'auto' }}>
                {(userDetails?.advertisers || []).map(
                  ({ advertiserId, advertiserName }: IAdvertiser) => (
                    <Typography
                      fontSize='1rem'
                      key={advertiserId}
                      variant='underline'
                      color={primary[500]}
                    >
                      {advertiserName}
                    </Typography>
                  ),
                )}
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
      <UserNotes userDetails={userDetails} />
    </Card>
  );
};

export default BasicDetails;
