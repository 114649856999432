export const zipcodeRegex = /^\d{5}(?:[-]?\d{4})?$/;
/** Is Zipcode valid */
export const isZipValid = (zip: string) => {
  return !!(zip && zipcodeRegex.test(zip));
};
export const creditCardRegex = /\b\d{14,16}\b/;
/** is Credit card number valid */
export const isValidCreditCardNumber = (number: string) => {
  return !!(number && creditCardRegex.test(number));
};
export const cvvRegex = /^\d{3,4}$/;
/** is cvv number valid */
export const isValidCVV = (number: string) => {
  return !!(number && cvvRegex.test(number));
};
export const phoneNumberRegex = /^[\d-]{10}$/;
export const isValidPhoneNumber = (number: string) => {
  return !!(number && phoneNumberRegex.test(number))
}
export const emailRegex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
export const isValidEmail = (email: string) => {
  return !!(email && emailRegex.test(email))
}
