import React from 'react';
import { isUserAuthenticated, isUserHasAccess } from '../utils/authUtils';
import AutoLogin from '../pages/Auth/AutoLogin';
import NoAccessPage from '../components/NoAccess/NoAccess';

/** Determine If Route is Authenticated and Completed Profile*/
const ProtectedRoute = ({ children }: any) => {
  const isAuthenticated = isUserAuthenticated();
  const isEligible = isUserHasAccess();
  if (isAuthenticated) {
    if (isEligible) {
      return children;
    } else {
      return (
        <NoAccessPage
          title={<>You are not authorized to view this page</>}
          message={
            <>
              To request access to the Client Admin Portal, please have your manager submit an{' '}
              <b>eSARF request</b>. For questions regarding eSARF, please reach out to the Spectrum
              Reach compliance team.
            </>
          }
        />
      );
    }
  } else {
    return <AutoLogin />;
  }
};

export default ProtectedRoute;
