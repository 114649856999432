import React, { useEffect, useState } from 'react';
import { Alert, Box, Button, CircularProgress, Divider, Typography, useTheme } from '@mui/material';
import { ClientTypes } from '../../../redux/clients/ClientModel';
import { ColorPartial } from '@mui/material/styles/createPalette';
import {
  IAdvertiser,
  IErrorLocalUserDetails,
  ILocalUserDetails,
  IReviewUser,
  IUser,
  IUserRequestPayload,
  Roles,
} from '../../../redux/user/UserModel';
import UnlinkClientModal from './UnlinkClientModal';
import LinkUserComponent from '../ReviewDetails/LinkUserComponent';
import RenderAssociatedClient from '../../../pages/PeopleDetails/ReviewDetails/RenderAssociatedClient';
import AccountAccessSelection from '../../People/AccountAccessSelection';
import { useDispatch, useSelector } from 'react-redux';
import { RootState, StoreDispatch } from '../../../redux/Store';
import RoleSelection from '../../People/RoleSelection';
import ReportsToUserSelection from '../../People/ReportsToUserSelection';
import { ComponentOverlayLoader } from '../../../components/Loaders/Loader';
import { Close } from '@mui/icons-material';
import {
  fetchRegisteredUserDetails,
  updateUserDetails,
  resetAgenciesList,
  resetAdvertisersList,
  getSelectedClientDetails,
  resetAgencyError,
  resetAdvertiserError,
} from '../../../redux/user/UserSlice';
import { formatErrors } from '../../../utils/common';
import ClientSelectionModal from '../ReviewDetails/ClientSelectionModal';

interface IEditDetailsProps {
  userDetails: IUser;
  setIsEditMode: (value: boolean) => void;
}

interface ISubmitUserDetails {
  clientName: string;
  clientId: string;
  clientType: string;
  ssoId: string;
}

const EditDetails = ({ userDetails, setIsEditMode }: IEditDetailsProps) => {
  const dispatch: StoreDispatch = useDispatch();

  const theme = useTheme();
  const primary: ColorPartial = theme.palette.primary as ColorPartial;
  const white: ColorPartial = theme.palette.white as ColorPartial;

  const {
    reportsToUsersStatus,
    agencyListStatus,
    advertiserListStatus,
    agencyAdvertiserListStatus,
    reportsToUsers,
    userRolesStatusError,
    agencyListError,
    advertiserListError,
    agencyAdvertiserListStatusError,
    reportsToUsersStatusError,
    reviewUserDetails,
    selectedClientDetailsResponse,
    selectedClientDetailsStatus,
  } = useSelector((state: RootState) => state.userData);

  const [error, setError] = useState('');
  const [updateDetailsStatus, setUpdateDetailsStatus] = useState('idle');
  const [showUnlinkClientModal, setShowUnlinkClientModal] = useState(false);
  const [openLinkUser, setOpenLinkUser] = useState(false);

  const [localUserDetails, setLocalUserDetails] = useState<ILocalUserDetails>({
    companyType: '' as ClientTypes,
    client: '',
    roleId: '',
    reportsToSSOId: '',
    advertisers: [],
  });

  const [payload, setPayload] = useState({
    clientType: '',
    searchType: '',
    searchValue: '',
  });

  const [confirmUserPayload, setConfirmUserPayload] = useState({
    clientName: '',
    clientType: '',
    clientId: '',
    ssoId: userDetails.ssoId,
  });

  useEffect(() => {
    if (selectedClientDetailsStatus === 'succeeded') {
      if (selectedClientDetailsResponse.clientType === ClientTypes.Agency) {
        setLocalUserDetails({
          ...localUserDetails,
          companyType: ClientTypes.Agency,
          client: {
            id: selectedClientDetailsResponse.id,
            agencyId: selectedClientDetailsResponse.clientId,
            agencyName: selectedClientDetailsResponse.clientName,
          },
        });
      } else {
        setLocalUserDetails({
          ...localUserDetails,
          companyType: ClientTypes.Advertiser,
          client: {
            id: selectedClientDetailsResponse.id,
            advertiserId: selectedClientDetailsResponse.clientId,
            advertiserName: selectedClientDetailsResponse.clientName,
          },
        });
      }
    }
  }, [selectedClientDetailsStatus]);

  const saveNewClient = async (type: string) => {
    type === ClientTypes.Agency &&
      (await dispatch(
        getSelectedClientDetails({
          clientType: ClientTypes.Agency,
          clientId: confirmUserPayload.clientId,
          ssoId: '',
        }),
      ));
    type === ClientTypes.Advertiser &&
      (await dispatch(
        getSelectedClientDetails({
          clientType: ClientTypes.Advertiser,
          clientId: confirmUserPayload.clientId,
          ssoId: '',
        }),
      ));
  };

  const updatePayload = (confirmUserPayload: ISubmitUserDetails) => {
    setConfirmUserPayload(confirmUserPayload);
    if (confirmUserPayload.clientType === ClientTypes.Agency) {
      setLocalUserDetails({
        ...localUserDetails,
        companyType: ClientTypes.Agency,
        client: {
          agencyId: confirmUserPayload.clientId,
          agencyName: confirmUserPayload.clientName,
        },
      });
      saveNewClient(ClientTypes.Agency);
    } else {
      setLocalUserDetails({
        ...localUserDetails,
        companyType: ClientTypes.Advertiser,
        client: {
          advertiserId: confirmUserPayload.clientId,
          advertiserName: confirmUserPayload.clientName,
        },
      });
      saveNewClient(ClientTypes.Advertiser);
    }
  };

  const [errorUserDetails, setErrorUserDetails] = useState<IErrorLocalUserDetails>({
    companyType: '',
    client: '',
    roleId: '',
    reportsToSSOId: '',
    advertisers: '',
  });

  const isAgency = localUserDetails?.companyType === ClientTypes.Agency;
  const companyId = localUserDetails?.client?.id;

  // If client type is agency we pick advertisers list based on that agency
  const { agencyAdvertiserList } = useSelector((state: RootState) => state.userData);

  // If client type is advertiser itself, then we just show selected advertiser as only one account in advertisers
  const advertiserAdvertiserList = localUserDetails?.client ? [localUserDetails?.client] : [];

  const transformedAdvertiserList =
    isAgency && companyId
      ? (agencyAdvertiserList || []).map((advertiser) => {
          return advertiser.tblAdvertiser;
        })
      : advertiserAdvertiserList;

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const name = event.target.name;
    const value: any = event.target.value;
    const updatedLocalUserDetails = { ...localUserDetails };
    if (name === 'companyType') {
      updatedLocalUserDetails.client = null;
      updatedLocalUserDetails.advertisers = [];
      updatedLocalUserDetails.reportsToSSOId = '';
      updatedLocalUserDetails.roleId = '';
    }
    if (name === 'client') {
      updatedLocalUserDetails.reportsToSSOId = '';
      updatedLocalUserDetails.roleId = '';
      // On client change we're checking is it matching with old one ,
      // if yes we're setting previously selected advertisers if not we select advertisers for new client
      if (
        value?.id === userDetails?.companyId &&
        localUserDetails?.companyType === userDetails?.companyType
      ) {
        updatedLocalUserDetails.advertisers = userDetails.advertisers;
      } else {
        updatedLocalUserDetails.advertisers = [];
      }
    }
    setLocalUserDetails({ ...updatedLocalUserDetails, [name]: value });
    setErrorUserDetails({ ...errorUserDetails, [name]: '' });
  };

  const handleAccountSelection = (event: any, advertiserData: IAdvertiser) => {
    const {
      target: { checked },
    } = event;
    if (checked) {
      setLocalUserDetails((prevState: any) => ({
        ...prevState,
        advertisers: advertiserData
          ? [...(prevState.advertisers || []), advertiserData]
          : transformedAdvertiserList,
      }));
    } else {
      setLocalUserDetails((prevState: any) => ({
        ...prevState,
        advertisers: advertiserData
          ? prevState.advertisers?.filter(
              (advertiser: IAdvertiser) => advertiser.advertiserId !== advertiserData.advertiserId,
            )
          : [],
      }));
    }
  };

  useEffect(() => {
    setLocalUserDetails({
      ...localUserDetails,
      companyType: (userDetails?.companyType || '') as ClientTypes,
      client:
        userDetails?.companyType === ClientTypes.Agency
          ? {
              id: userDetails?.companyId,
              agencyId: userDetails?.clientId,
              agencyName: userDetails?.client,
            }
          : {
              id: userDetails?.companyId,
              advertiserId: userDetails?.clientId,
              advertiserName: userDetails?.client,
            },
      advertisers: userDetails?.advertisers,
      roleId: userDetails?.role?.id,
      reportsToSSOId: userDetails?.reportsToSsoId,
    });
  }, []);

  const isLoading = reportsToUsersStatus === 'loading';
  const isUpdateDetailsLoading = updateDetailsStatus === 'loading';
  const isGetSelectedDetailsLoading = selectedClientDetailsStatus === 'loading';

  useEffect(() => {
    // If report to users empty, user can only be account owner
    if (
      reportsToUsersStatus === 'succeeded' &&
      reportsToUsers?.length === 0 &&
      localUserDetails?.client
    ) {
      setLocalUserDetails({ ...localUserDetails, roleId: Roles.ACCOUNT_OWNER });
    }
  }, [reportsToUsersStatus]);

  const validateFields = () => {
    const errorDetails = { ...errorUserDetails };
    let isValid = true;
    if (!localUserDetails?.companyType) {
      errorDetails.companyType = 'Please select client type';
      isValid = false;
    }
    if (!localUserDetails?.client?.id) {
      errorDetails.client = 'Please select client';
      isValid = false;
    }
    if (!localUserDetails?.roleId) {
      errorDetails.roleId = 'Please select role';
      isValid = false;
    }
    if (localUserDetails?.roleId !== Roles.ACCOUNT_OWNER && !localUserDetails?.reportsToSSOId) {
      errorDetails.reportsToSSOId = 'Please select reports to';
      isValid = false;
    }
    if (
      localUserDetails?.roleId === Roles.ACCOUNT_OWNER &&
      !localUserDetails?.advertisers?.length
    ) {
      errorDetails.advertisers =
        'Please select all accounts, account owner should have access to all accounts';
      isValid = false;
    }
    return { isValid, errorDetails };
  };

  const handleConfirm = () => {
    const { isValid, errorDetails } = validateFields();
    if (isValid) {
      setError('');
      setUpdateDetailsStatus('loading');
      const payload: IUserRequestPayload = {
        advertisers: localUserDetails?.advertisers,
        clientId: localUserDetails?.client?.id,
        clientType: localUserDetails?.companyType,
        reportsToSSOId: localUserDetails?.reportsToSSOId,
        roleId: localUserDetails?.roleId,
        notes: userDetails?.userNotes,
        ssoId: userDetails?.ssoId,
      };
      updateUserDetails(payload)
        .then(() => {
          setIsEditMode(false);
          setUpdateDetailsStatus('succeeded');
          dispatch(fetchRegisteredUserDetails(payload?.ssoId));
        })
        .catch((error) => {
          setUpdateDetailsStatus('failed');
          const errorMessage = error?.errors ? formatErrors(error?.errors) : error?.error;
          setError(errorMessage || 'Something went wrong, Failed to update user details');
        });
    } else {
      setErrorUserDetails(errorDetails);
    }
  };

  const handleCancel = () => {
    setOpenLinkUser(false);
    setIsEditMode(false);
  };

  const displayError = (error: string) => (
    <Box my={theme.spacing(6)}>
      <Alert severity='error' variant='outlined'>
        <Typography variant='paragraph2' component='span' color='error'>
          {error}
        </Typography>
      </Alert>
    </Box>
  );

  const handleShowUnlinkModal = () => {
    setShowUnlinkClientModal(true);
  };

  const handleResetClientLink = () => {
    setConfirmUserPayload({
      clientName: '',
      clientType: '',
      clientId: '',
      ssoId: userDetails.ssoId,
    });
  };

  const resetAllErrors = () => {
    setError('');
    dispatch(resetAgencyError());
    dispatch(resetAdvertiserError());
  };

  const handleUnlinkClient = () => {
    setLocalUserDetails({ ...localUserDetails, client: null });
    setShowUnlinkClientModal(false);
  };

  const handleOpenLinkUser = () => {
    setOpenLinkUser(true);
  };

  const handleCloseLinkUser = () => {
    setOpenLinkUser(false);
    resetAllErrors();
    dispatch(resetAdvertisersList());
    dispatch(resetAgenciesList());
    setPayload({
      clientType: '',
      searchType: '',
      searchValue: '',
    });
  };

  const handleCloseUnlinkModal = () => {
    setShowUnlinkClientModal(false);
  };

  const disableConfirm =
    !localUserDetails.client ||
    !localUserDetails.client.id ||
    isLoading ||
    !localUserDetails.roleId

  return (
    <Box
      sx={{
        flexGrow: 1,
      }}
    >
      <Divider sx={{ mb: 10 }} />
      {isLoading && <ComponentOverlayLoader />}
      {userRolesStatusError && displayError(userRolesStatusError)}
      {agencyListError && displayError(agencyListError)}
      {advertiserListError && displayError(advertiserListError)}
      {agencyAdvertiserListStatusError && displayError(agencyAdvertiserListStatusError)}
      {reportsToUsersStatusError && displayError(reportsToUsersStatusError)}
      <Box>
        <Typography variant='h5' mb={12}>
          Client
        </Typography>
        {localUserDetails.client && !confirmUserPayload.clientId && (
          <Box mt={10} mb={10}>
            <RenderAssociatedClient
              unlinkCallback={() => handleShowUnlinkModal()}
              clientName={
                localUserDetails.client.advertiserName || localUserDetails.client.agencyName
              }
              clientId={localUserDetails.client.advertiserId || localUserDetails.client.agencyId}
            />
          </Box>
        )}
        {!localUserDetails.client && !confirmUserPayload.clientId && (
          <Box mt={20} mb={20}>
            <LinkUserComponent handleOpen={handleOpenLinkUser} />
          </Box>
        )}
        {confirmUserPayload.clientId && (
          <Box mt={10} mb={10}>
            <RenderAssociatedClient
              unlinkCallback={() => handleResetClientLink()}
              clientName={confirmUserPayload.clientName}
              clientId={confirmUserPayload.clientId}
            />
          </Box>
        )}
        <UnlinkClientModal
          open={showUnlinkClientModal}
          handleClose={handleCloseUnlinkModal}
          handleUnlink={handleUnlinkClient}
          clientName={userDetails.client || ''}
        />
        <ClientSelectionModal
          open={openLinkUser}
          handleClose={handleCloseLinkUser}
          payload={payload}
          setPayload={setPayload}
          confirmUserPayload={confirmUserPayload}
          setConfirmUserPayload={setConfirmUserPayload}
          reviewUserDetails={reviewUserDetails as IReviewUser}
          updatePayload={updatePayload}
          isGetSelectedDetailsLoading={isGetSelectedDetailsLoading}
          handleEditUserChange={handleChange}
        />
      </Box>
      <Divider sx={{ mb: 10 }} />
      <Box>
        <Typography variant='h5' mb={12}>
          User Permissions
        </Typography>
        <Box mb={10}>
          <RoleSelection
            selectedReportsToSSOId={localUserDetails?.reportsToSSOId}
            roleId={localUserDetails?.roleId}
            handleChange={handleChange}
            error={errorUserDetails?.roleId}
          />
        </Box>
        <Box mb={10}>
          <ReportsToUserSelection
            companyType={localUserDetails?.companyType}
            selectedRoleId={localUserDetails?.roleId}
            companyId={companyId}
            reportsToSSOId={localUserDetails?.reportsToSSOId}
            handleChange={handleChange}
            error={errorUserDetails?.reportsToSSOId}
          />
        </Box>
        <Box>
          <Typography variant='h5' mb={12}>
            Account Access
          </Typography>
          <AccountAccessSelection
            companyId={companyId}
            roleId={localUserDetails?.roleId}
            selectedAdvertisers={localUserDetails.advertisers || []}
            advertiserList={transformedAdvertiserList}
            handleChange={handleAccountSelection}
            error={errorUserDetails?.advertisers}
          />
        </Box>
        <Divider sx={{ borderColor: primary[100], mt: 10, mb: 10 }} />
      </Box>
      {error && (
        <Box mt={5} mb={5}>
          <Alert severity='error' variant='outlined'>
            <Typography variant='paragraph2' component='span' color='error'>
              {error}
            </Typography>
          </Alert>
        </Box>
      )}
      <Box display='flex' gap={theme.spacing(10)} mt={theme.spacing(8)}>
        <Button
          variant='contained'
          sx={{
            borderRadius: theme.spacing(2),
            width: 'fit-content',
          }}
          onClick={handleConfirm}
          disabled={disableConfirm}
        >
          {isUpdateDetailsLoading && (
            <CircularProgress size={18} sx={{ color: white[500], mr: 5 }} />
          )}
          Confirm Changes
        </Button>
        <Button startIcon={<Close />} onClick={handleCancel}>
          Cancel
        </Button>
      </Box>
    </Box>
  );
};
export default EditDetails;
