import React from 'react';
import { NavLink } from 'react-router-dom';
import { Typography, useMediaQuery, useTheme } from '@mui/material';
import { ReactComponent as SpectrumLogo } from '../../assets/images/spectrum-logo-blue.svg';
import DrawerComponent from './Drawer/Drawer';
import UserMenu from '../UserMenu/UserMenu';

// Types
import { ILink } from '../../types';

//Styles
import { StyledAppBar } from './NavStyles';
import { IUserDetails } from '../../redux/auth/AuthModels';

interface INavProps {
  links: Array<ILink>;
  isAuthenticated: boolean;
  productTitle: string;
  user: IUserDetails | null;
}

const Nav = ({ links, isAuthenticated = true, productTitle, user }: INavProps) => {
  const activeClassName = 'link link-active';
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('tablet'));
  return (
    <StyledAppBar sx={{ boxShadow: 2 }} position='static'>
      <div className='logo-container left'>
        <div className='spectrum-logo' role='presentation'>
          <NavLink to='/'>
            <SpectrumLogo />
          </NavLink>
        </div>
        <Typography className='project-logo' variant='productTitle'>
          {productTitle}
        </Typography>
      </div>
      {isAuthenticated && (
        <>
          {!isMobile && (
            <div className='links-container center'>
              {links.map((link, index) => {
                return (
                  <NavLink
                    key={index}
                    className={({ isActive }) => (isActive ? activeClassName : 'link')}
                    to={link.link}
                  >
                    {link?.label}
                  </NavLink>
                );
              })}
            </div>
          )}
        </>
      )}
      <div className='right'>
        <UserMenu user={user}></UserMenu>
        {isMobile && <DrawerComponent links={links} />}
      </div>
    </StyledAppBar>
  );
};

export default Nav;
