import { Chip } from '@mui/material';
import React from 'react';
import { StatusTypes } from '../../../redux/user/UserModel';
import { insertSpacesBeforeCapitals } from '../../../utils/common';

const StatusCell = ({ status }: { status: string }) => {
  const getStatusColor = () => {
    switch (status) {
      case StatusTypes.Processed:
        return 'success';
      case StatusTypes.Failed:
      case StatusTypes.Canceled:
        return 'error';
      case StatusTypes.Processing:
        return 'warning';
      case StatusTypes.Received:
        return 'primary';
      case StatusTypes.Active:
        return 'success';
      case StatusTypes.Pending:
        return 'warning';
      case StatusTypes.NeedsReview:
        return 'error';
      default:
        return 'default';
    }
  };
  return <Chip label={insertSpacesBeforeCapitals(status)} color={getStatusColor()} />;
};

export default StatusCell;
