import React from 'react';
import { Box, Button, CircularProgress, Typography, useTheme } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { ColorPartial } from '@mui/material/styles/createPalette';
import SpectrumDialog from './Spectrum/Dialog/SpectrumDialog';

const ConfirmationDialog = ({
  loading,
  open,
  title,
  heading,
  subHeading,
  cancelButtonText = 'Cancel',
  confirmButtonText,
  showCancelIcon,
  onClose,
  onConfirm,
}: {
  loading: boolean;
  open: boolean;
  title: string;
  heading: any;
  subHeading: any;
  cancelButtonText?: string;
  confirmButtonText: string;
  onClose: () => void;
  onConfirm: () => void;
  showCancelIcon?: boolean;
}) => {
  const theme = useTheme();
  const grey: ColorPartial = theme.palette.grey as ColorPartial;

  return (
    <SpectrumDialog open={open} onClose={loading ? undefined : onClose}>
      <Typography variant='eyebrow' letterSpacing={1.28} mt={theme.spacing(4)}>
        {title}
      </Typography>
      {loading ? (
        <Box
          display='flex'
          alignItems='center'
          justifyContent='center'
          sx={{
            height: '100px',
          }}
        >
          <CircularProgress />
        </Box>
      ) : (
        <>
          <Typography variant='h2'>{heading}</Typography>
          <Typography mt={theme.spacing(8)}>{subHeading}</Typography>
          <Box gap={confirmButtonText ? theme.spacing(4) : 0} mt={theme.spacing(12)}>
            {confirmButtonText && (
              <Button
                sx={{
                  borderRadius: theme.spacing(2),
                  fontWeight: 500,
                  minWidth: '150px',
                }}
                variant='contained'
                onClick={onConfirm}
                size='large'
              >
                {confirmButtonText}
              </Button>
            )}
            <Button
              color='secondary'
              sx={{
                fontWeight: 500,
                padding: theme.spacing(8, 12),
              }}
              onClick={onClose}
            >
              {showCancelIcon && <CloseIcon color='disabled' sx={{ mr: theme.spacing(2) }} />}
              <Typography color={grey[500]}>{cancelButtonText}</Typography>
            </Button>
          </Box>
        </>
      )}
    </SpectrumDialog>
  );
};

export default ConfirmationDialog;
