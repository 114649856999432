import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Box, Chip, MenuItem } from '@mui/material';

import { RootState, StoreDispatch } from '../../redux/Store';
import { fetchUserRoles } from '../../redux/user/UserSlice';

import SpectrumSelectField from '../../components/Spectrum/Select/SpectrumSelect';
import { Roles } from '../../redux/user/UserModel';

const RoleSelection = ({
  roleId,
  selectedReportsToSSOId,
  handleChange,
  isDisabled = false,
  error,
}: {
  roleId?: number;
  selectedReportsToSSOId: string;
  handleChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  isDisabled?: boolean;
  error: string;
}) => {
  const { userRoles, reportsToUsers } = useSelector((state: RootState) => state.userData);
  const dispatch: StoreDispatch = useDispatch();

  useEffect(() => {
    if (!userRoles?.length) {
      dispatch(fetchUserRoles());
    }
  }, []);

  const isAccountOwnerExist = reportsToUsers.find(
    (user) => user?.tblUserRole?.id === Roles.ACCOUNT_OWNER,
  );

  const selectedReportUser = reportsToUsers.find(
    (user) => user?.cognitoUserId === selectedReportsToSSOId,
  );

  const filteredRoles = (userRoles || []).filter((userRole) => {
    return (
      (selectedReportUser ? userRole?.id > selectedReportUser?.tblUserRole?.id : true) &&
      (isAccountOwnerExist ? userRole?.id !== Roles.ACCOUNT_OWNER : true)
    );
  });

  return (
    <SpectrumSelectField
      variant='outlined'
      size='small'
      name='roleId'
      label='Role'
      value={roleId}
      onChange={handleChange}
      disabled={isDisabled}
      error={error}
      helperText={error}
      renderValue={(selected: number) => {
        const selectedRole = userRoles.find((role) => role.id === selected);
        return (
          <Box>{selectedRole?.name && <Chip label={selectedRole?.name} color='primary' />}</Box>
        );
      }}
    >
      {filteredRoles.map((role) => (
        <MenuItem key={role.id} value={role.id}>
          {role.name}
        </MenuItem>
      ))}
    </SpectrumSelectField>
  );
};

export default RoleSelection;
