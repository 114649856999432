import { styled } from '@mui/material/styles';
import { Avatar } from '@mui/material';
import { ColorPartial } from '@mui/material/styles/createPalette';

type AvatarStyledProps = {
  size?: string;
  bgColor?: string;
};
export const StyledAvatar = styled(Avatar, {
  shouldForwardProp: () => true,
})<AvatarStyledProps>(({ theme, ...props }) => {
  const primary: ColorPartial = theme.palette.primary as ColorPartial;
  return {
    background: primary[200],
    fontSize: '0.8rem',
    letterSpacing: '1px',
    padding: theme.spacing(2),
    ...(props.bgColor === 'primary' && {
      background: primary[200],
    }),
    ...(props.bgColor === 'secondary' && {
      background: theme.palette.secondary.main,
    }),
    ...(props.size === 'small' && {
      height: theme.spacing(12),
      width: theme.spacing(12),
      fontSize: '0.7rem',
    }),
    ...(props.size === 'medium' && {
      height: theme.spacing(18),
      width: theme.spacing(18),
      fontSize: '0.8rem',
    }),
    ...(props.size === 'large' && {
      height: theme.spacing(24),
      width: theme.spacing(24),
      fontSize: '1rem',
    }),
  };
});
