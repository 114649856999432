import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { StoreDispatch } from '../../redux/Store';
import { fetchAdvertisersOfAgency } from '../../redux/user/UserSlice';
import { Checkbox, FormControlLabel, FormGroup, Typography, useTheme } from '@mui/material';
import { IAdvertiser, Roles } from '../../redux/user/UserModel';

const AccountAccessSelection = ({
  selectedAdvertisers,
  advertiserList,
  companyId,
  handleChange,
  isDisabled = false,
  error,
  roleId,
}: {
  companyId: null | string;
  selectedAdvertisers: IAdvertiser[];
  advertiserList: IAdvertiser[];
  isDisabled?: boolean;
  handleChange: (event: any, advertiserData?: any) => void;
  error: string;
  roleId: number;
}) => {
  const theme = useTheme();
  const dispatch: StoreDispatch = useDispatch();

  useEffect(() => {
    if (companyId) {
      dispatch(fetchAdvertisersOfAgency(companyId));
    }
  }, [companyId]);

  const hasHandledChange = React.useRef(false);

  useEffect(() => {
    if (roleId === Roles.ACCOUNT_OWNER && !hasHandledChange.current) {
      advertiserList.forEach((advertiser) => {
        handleChange({ target: { checked: true } }, advertiser);
      });
      hasHandledChange.current = true;
    }
    if (roleId !== Roles.ACCOUNT_OWNER) {
      hasHandledChange.current = false;
    }
  }, [roleId, advertiserList, handleChange]);

  return advertiserList.length > 0 ? (
    <FormGroup>
      <FormControlLabel
        control={
          <Checkbox
            checked={advertiserList.length === selectedAdvertisers.length}
            indeterminate={
              selectedAdvertisers.length >= 1 &&
              advertiserList.length !== selectedAdvertisers.length
            }
            onChange={(event) => handleChange(event)}
            value=''
            disabled={isDisabled}
          />
        }
        label='All'
      />
      {advertiserList.map((advertiser: IAdvertiser) => (
        <FormControlLabel
          key={advertiser.advertiserId}
          control={
            <Checkbox
              checked={
                roleId === Roles.ACCOUNT_OWNER ||
                selectedAdvertisers.findIndex(
                  (item: IAdvertiser) => item.advertiserId === advertiser.advertiserId,
                ) > -1
              }
              value={advertiser.advertiserId}
              onChange={(event) => handleChange(event, advertiser)}
              disabled={isDisabled}
            />
          }
          sx={{ ml: theme.spacing(10) }}
          label={advertiser.advertiserName}
        />
      ))}
      {error && (
        <Typography variant='subtitle' color={theme.palette.error.main}>
          {error}
        </Typography>
      )}
    </FormGroup>
  ) : null;
};

export default AccountAccessSelection;
