import { styled } from '@mui/material';
import { ColorPartial } from '@mui/material/styles/createPalette';

export const StyledDateCell = styled('div')(({ theme }) => {
  const primary: ColorPartial = theme.palette.primary as ColorPartial;
  return {
    '.info': {
      backgroundColor: primary[50],
      color: primary[400],
      border: `1px solid ${primary[300]}`,
      fontSize: 10,
      padding: theme.spacing(0.5, 2),
      display: 'inline-flex',
      alignItems: 'center',
      '.info-icon': {
        fontSize: 10,
        padding: theme.spacing(0, 1),
      },
    },
  };
});
export const StyledTransactionExpandRowDetails = styled('div')(({ theme }) => {
  const primary: ColorPartial = theme.palette.primary as ColorPartial;
  const blue: ColorPartial = theme.palette.blue as ColorPartial;
  const black: ColorPartial = theme.palette.black as ColorPartial;
  const white: ColorPartial = theme.palette.white as ColorPartial;
  return {
    backgroundColor: blue[100],
    border: `1px solid ${blue[200]}`,
    margin: theme.spacing(5),
    borderRadius: theme.spacing(2),
    padding: theme.spacing(8),
    '.divider': {
      borderColor: blue[200],
    },
    '.label': {
      fontSize: 14,
      color: black[500],
    },
    '.value': {
      margin: theme.spacing(5, 0),
      '&.description': {
        minHeight: 23,
        maxHeight: 150,
        overflow: 'auto',
        padding: theme.spacing(5),
        border: `1px solid ${theme.palette.grey[400]}`,
        backgroundColor: white[500],
        color: theme.palette.grey[600],
      },
    },
    ul: {
      minHeight: 100,
      maxHeight: 210,
      overflow: 'auto',
    },
    'ul li::marker': {
      color: primary[500],
      fontSize: 16,
    },
  };
});
