import React, { useEffect } from 'react';
import { Box, Button, Typography, useTheme, CircularProgress, Alert } from '@mui/material';
import { ColorPartial } from '@mui/material/styles/createPalette';
import { RootState, StoreDispatch } from '../../redux/Store';
import { useDispatch, useSelector } from 'react-redux';
import { resetUserPassword, setResetPasswordStatus } from '../../redux/user/UserSlice';

interface IResetPasswordProps {
  userSsoId: string;
  onClose: () => void;
}

const ResetPassword = ({ userSsoId, onClose }: IResetPasswordProps) => {
  const theme = useTheme();
  const dispatch: StoreDispatch = useDispatch();
  const grey: ColorPartial = theme.palette.grey as ColorPartial;
  const white: ColorPartial = theme.palette.white as ColorPartial;

  const { resetPasswordStatus, resetPasswordError } = useSelector(
    (state: RootState) => state.userData,
  );

  const handleReset = () => {
    dispatch(resetUserPassword(userSsoId));
  };
  const isLoading = resetPasswordStatus === 'loading';
  const iSuccess = resetPasswordStatus === 'succeeded';

  useEffect(() => {
    return () => {
      dispatch(setResetPasswordStatus('idle'));
    };
  }, []);

  return (
    <div>
      <Typography variant='h2'>Reset Password</Typography>
      {iSuccess ? (
        <>
          <Typography mt={theme.spacing(8)} variant='body1'>
            An email with instructions to reset this users password has sent
          </Typography>
          <Box gap={0} mt={theme.spacing(12)}>
            <Button
              sx={{
                borderRadius: theme.spacing(2),
                fontWeight: 500,
                minWidth: '150px',
              }}
              variant='contained'
              onClick={onClose}
            >
              Ok
            </Button>
          </Box>
        </>
      ) : (
        <Box>
          <Typography mt={theme.spacing(8)} variant='body1'>
            An email with instructions to reset this users password will be send
          </Typography>
          {resetPasswordError && (
            <Box my={theme.spacing(6)}>
              <Alert severity='error' variant='outlined'>
                <Typography variant='paragraph2' component='span' color='error'>
                  {resetPasswordError}
                </Typography>
              </Alert>
            </Box>
          )}
          <Box gap={0} mt={theme.spacing(12)}>
            <Button
              sx={{
                borderRadius: theme.spacing(2),
                fontWeight: 500,
                minWidth: '150px',
              }}
              variant='contained'
              onClick={handleReset}
            >
              {isLoading ? (
                <CircularProgress style={{ color: white[500], width: 30, height: 30 }} />
              ) : (
                'Reset'
              )}
            </Button>
            <Button
              color='secondary'
              sx={{
                fontWeight: 500,
                padding: theme.spacing(8, 12),
              }}
              disabled={isLoading}
              onClick={onClose}
            >
              <Typography color={grey[500]}>Cancel</Typography>
            </Button>
          </Box>
        </Box>
      )}
    </div>
  );
};

export default ResetPassword;
