import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { clientEndpoints, transactionEndpoints } from '../../constants/apiConstants';
import ApiService from '../../services/ApiService';
import {
  ITransactionsState,
  ITransactions,
  IFilterPayload,
  IReportDownloadPayload,
  IUserReportDownloadPayload,
  IRecurringPaymentsReportDownloadPayload,
  ITransactionDetailsReqPayload,
  ITransactionDetailsResponse,
  ITransactionDetails,
  IInvoiceOrderItem,
  defaultTransactionFiltersData,
} from './TransactionModel';

const initialState: ITransactionsState = {
  status: 'idle',
  data: {} as ITransactions,
  paymentStatusList: [],
  paymentStatusListStatus: 'idle',
  paymentStatusListError: '',
  transactionDetails: {} as ITransactionDetails,
  transactionDetailsStatus: 'idle',
  transactionDetailsError: '',
  filters: defaultTransactionFiltersData,
  reportDownloadStatus: 'idle',
  reportDownloadError: '',
  error: '',
};

export const fetchTransactions = createAsyncThunk(
  'Clients/GetTransactions',
  async (payload: IFilterPayload) => {
    const response = await ApiService.postData(transactionEndpoints.list, {}, payload);
    return response.data;
  },
);

export const fetchClientTransactions = createAsyncThunk(
  'Transactions/GetTransactions',
  async (payload: IFilterPayload) => {
    const response = await ApiService.postData(clientEndpoints.transactions, {}, payload);
    return response.data;
  },
);

export const fetchPaymentStatusList = createAsyncThunk(
  'Transactions/PaymentStatusList',
  async () => {
    const response = await ApiService.getData(transactionEndpoints.paymentStatusList);
    return response.data;
  },
);

export const downloadTransactionReport = (payload: IReportDownloadPayload) => {
  return ApiService.postData(transactionEndpoints.downloadTransactionsHistoryURL, {}, payload);
};

export const downloadAdportalReport = (payload: IReportDownloadPayload) => {
  return ApiService.postData(
    transactionEndpoints.downloadAdportalTransactionsHistoryURL,
    {},
    payload,
  );
};

export const downloadUsersReport = (payload: IUserReportDownloadPayload) => {
  return ApiService.postData(transactionEndpoints.downloadUsersReportURL, {}, payload);
};

export const adjustSchedulePayment = (payload: ITransactionDetails) => {
  return ApiService.postData(clientEndpoints.adjustSchedulePayment, {}, payload);
};

export const downloadRecurringPaymentsReport = (
  payload: IRecurringPaymentsReportDownloadPayload,
) => {
  return ApiService.postData(transactionEndpoints.downloadRecurringPaymentsReportURL, {}, payload);
};
export const fetchTransactionDetails = createAsyncThunk(
  'Transactions/GetTransactionDetails',
  async (payload: ITransactionDetailsReqPayload) => {
    const response = await ApiService.postData(
      transactionEndpoints.transactionDetails,
      {},
      payload,
    );
    return response.data;
  },
);

export const TransactionsSlice = createSlice({
  name: 'transactions',
  initialState,
  reducers: {
    updateFilters: (state, action) => {
      state.filters = action?.payload;
    },
    resetReportDownloadStatus: (state, action) => {
      state.reportDownloadError = '';
      state.reportDownloadStatus = 'idle';
    },
    updateTransactionDetails: (state, action) => {
      state.transactionDetails = action.payload;
    },
  },
  extraReducers(builder) {
    builder
      .addCase(fetchTransactions.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchTransactions.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.data = action.payload.data;
        state.error = '';
      })
      .addCase(fetchTransactions.rejected, (state, action) => {
        state.status = 'failed';
        (state.data = {} as ITransactions),
          (state.error = action?.error?.message || 'Something went wrong, please try again later');
      })
      .addCase(fetchTransactionDetails.pending, (state) => {
        state.transactionDetailsStatus = 'loading';
        state.transactionDetailsError = '';
      })
      .addCase(fetchTransactionDetails.fulfilled, (state, action) => {
        state.transactionDetailsStatus = 'succeeded';
        const response: ITransactionDetailsResponse = action.payload.data;
        state.transactionDetails = {
          SsoId: action?.meta?.arg?.ssoId,
          ...response,
          advertisers: response.advertisers.map((advertiser) => {
            let items: IInvoiceOrderItem[] = [];
            if (advertiser?.paidOrders?.length) {
              items = (advertiser?.paidOrders || []).map((order) => ({
                id: order.id,
                itemNumber: order.orderNumber,
                isCustomAmount: order.isCustomAmount,
                totalAmount: order.orderAmount,
                dueDate: order.dueDate,
                newAmount: order.isCustomAmount ? order.customAmount : order.orderAmount,
                oldAmount: order.isCustomAmount ? order.customAmount : order.orderAmount,
                type: 'Ord',
              }));
            } else if (advertiser?.paidInvoices?.length) {
              items = (advertiser?.paidInvoices || []).map((invoice) => ({
                id: invoice.id,
                itemNumber: invoice.invoiceNumber,
                isCustomAmount: invoice.isCustomAmount,
                totalAmount: invoice.openAmount,
                dueDate: invoice.dueDate,
                newAmount: invoice.isCustomAmount ? invoice.customAmount : invoice.openAmount,
                oldAmount: invoice.isCustomAmount ? invoice.customAmount : invoice.openAmount,
                type: 'Inv',
              }));
            }
            return { ...advertiser.advertiserDetails, items };
          }),
        };
        state.transactionDetailsError = '';
      })
      .addCase(fetchTransactionDetails.rejected, (state, action) => {
        state.transactionDetailsStatus = 'failed';
        state.transactionDetails = {} as ITransactionDetails;
        state.transactionDetailsError =
          action?.error?.message || 'Something went wrong, please try again later';
      })
      .addCase(fetchClientTransactions.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchClientTransactions.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.data = action.payload.data;
        state.error = '';
      })
      .addCase(fetchClientTransactions.rejected, (state, action) => {
        state.status = 'failed';
        (state.data = {} as ITransactions),
          (state.error = action?.error?.message || 'Something went wrong, please try again later');
      })
      .addCase(fetchPaymentStatusList.pending, (state) => {
        state.paymentStatusListStatus = 'loading';
      })
      .addCase(fetchPaymentStatusList.fulfilled, (state, action) => {
        state.paymentStatusListStatus = 'succeeded';
        state.paymentStatusList = action.payload.data;
        state.paymentStatusListError = '';
      })
      .addCase(fetchPaymentStatusList.rejected, (state, action) => {
        state.paymentStatusListStatus = 'failed';
        state.paymentStatusListError =
          action?.error?.message || 'Failed to fetch payment status list';
      });
  },
});
export const { updateFilters, resetReportDownloadStatus, updateTransactionDetails } =
  TransactionsSlice.actions;
export default TransactionsSlice.reducer;
