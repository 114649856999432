import React from 'react';
import { Box, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';

import { ColorPartial } from '@mui/material/styles/createPalette';
import LinkIcon from '@mui/icons-material/Link';

interface IBasicDetailsProps {
  handleOpen: () => void;
}

export const StyledOutlinedButton = styled(Box)(({ theme }) => {
  const grey: ColorPartial = 'lightgrey' as ColorPartial;
  return {
    padding: theme.spacing(10),
    boxShadow: theme.shadows[2],
    display: 'flex',
    alignItems: 'center',
    border: `1px solid ${grey}`,
    '&:hover': {
      cursor: 'pointer',
      boxShadow: theme.shadows[1],
    },
  };
});

export const StyledOutlinedBox = styled(Box)(({ theme }) => {
  const grey: ColorPartial = 'lightgrey' as ColorPartial;
  return {
    padding: theme.spacing(10),
    border: `1px solid ${grey}`,
  };
});

const LinkUserComponent = ({ handleOpen }: IBasicDetailsProps) => {
  return (
    <StyledOutlinedButton onClick={handleOpen}>
      <LinkIcon style={{ fontSize: 14 }} color='primary' />
      <Typography variant='subtitle2' color='primary' pl={5}>
        Link User to Client
      </Typography>
    </StyledOutlinedButton>
  );
};

export default LinkUserComponent;
