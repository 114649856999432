import React, { useState } from 'react';
import { formatDate } from './common';
import { DATE_FORMAT } from '../constants/common';

export const useGetCurrentFilters = (
  searchParams: URLSearchParams,
  filters: { [x: string]: any },
  formatFn: (data: string) => Date,
) => {
  // Default values from store
  const currentFiltersData: { [x: string]: any } = { ...filters };
  // Values From URl
  const paramEntries: any = searchParams.entries();
  for (const entry of paramEntries) {
    const [param, value] = entry;
    if (value) {
      if (param === 'startDate' || param === 'endDate') {
        currentFiltersData[param] = formatFn(value);
      } else {
        currentFiltersData[param] = value;
      }
    }
  }
  return currentFiltersData;
};
export const useFilterHandlers = <
  T extends { sortColumn: string; sortDirection: string; start: number; length: number },
>(
  filters: T,
  updateFilters: (updatedFields: any) => void,
) => {
  const handleSort = (event: React.MouseEvent<unknown>, property: string) => {
    const isAsc = filters.sortColumn === property && filters.sortDirection === 'asc';
    const changedSortDirection = isAsc ? 'desc' : 'asc';
    updateFilters({ sortDirection: changedSortDirection, sortColumn: property });
  };
  const handleChangeItemsPerPage = (items: number) => {
    updateFilters({ start: 0, length: items });
  };
  const handlePageChange = (pageNo: number) => {
    updateFilters({ start: pageNo * filters.length });
  };
  return { handleSort, handleChangeItemsPerPage, handlePageChange };
};
export const useReportUtils = () => {
  const [dateRange, setDateRange] = useState<Array<string>>([]);
  const [viewType, setViewType] = useState(1);
  const [reportDownloadStatus, setReportDownloadStatus] = useState<any>(false);
  const [reportDownloadError, setReportDownloadError] = useState<any>(null);
  const [startDate, endDate] = dateRange;
  const formattedStartDate = startDate ? formatDate(startDate, DATE_FORMAT) : '';
  const formattedEndDate = endDate ? formatDate(endDate, DATE_FORMAT) : '';
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value: any = event.target.value;
    setDateRange(value);
  };
  const handleConfirm = () => {
    setViewType(2);
  };
  return {
    dateRange,
    formattedStartDate,
    formattedEndDate,
    viewType,
    reportDownloadStatus,
    reportDownloadError,
    handleChange,
    handleConfirm,
    setReportDownloadStatus,
    setReportDownloadError,
    setViewType,
    setDateRange
  };
};
