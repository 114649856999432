import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Box, Typography, useTheme } from '@mui/material';

import { updateFilters } from '../../redux/user/UserSlice';

import PageContentContainer from '../../components/PageContentContainer/PageContentContainer';
import ClientFilters from './ClientFilters';
import ClientTable from './ClientTable';

const Clients = () => {
  const theme = useTheme();
  const dispatch = useDispatch();

  useEffect(() => {
    return () => {
      dispatch(
        updateFilters({
          start: 0,
          length: 10,
          sortColumn: '',
          sortDirection: '',
          searchValue: '',
          startDate: null,
          endDate: null
        }),
      );
    };
  }, []);

  return (
    <PageContentContainer>
        <Typography mb={theme.spacing(12)} variant='h1'>Clients</Typography>
      <Box sx={{ mb: 6 }}>
        <ClientFilters />
      </Box>
      <ClientTable />
    </PageContentContainer>
  );
};

export default Clients;
