import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getAuthItem, constants, isUserAuthenticated } from '../../utils/authUtils';
import { differenceInMilliseconds } from 'date-fns';
import { RootState } from '../../redux/Store';
import { fetchAccessTokenUsingRefreshToken } from '../../redux/auth/AuthSlice';

// Gets new Access Token Before token expires
const GetNewAccessToken = () => {
  const dispatch = useDispatch();
  const { accessTokenStatus } = useSelector((state: RootState) => ({
    accessTokenStatus: state.authData.accessTokenStatus,
  }));

  const isAccessTokenLoading = accessTokenStatus === 'loading';
  const isAuthenticated = isUserAuthenticated();

  const getTime = () => {
    const endDate = new Date(getAuthItem(constants.EXPIRY_TIME) || '');
    const currentDate = new Date();
    return differenceInMilliseconds(endDate, currentDate) - 60 * 1000;
  };

  useEffect(() => {
    let timerId: any = null;
    if (isAuthenticated && !isAccessTokenLoading) {
      const time: number = getTime();
      if (time > 0) {
        timerId = setTimeout(() => {
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          dispatch(fetchAccessTokenUsingRefreshToken());
        }, time);
      }
    }
    return () => clearTimeout(timerId);
  }, [isAccessTokenLoading]);

  return <></>;
};

export default GetNewAccessToken;
