import React from 'react';
import { StyledOutlinedBox } from './LinkUserComponent';
import { Box, Radio, Typography } from '@mui/material';
import { ClientTypes } from '../../../redux/clients/ClientModel';
import {
  IAdvertiser,
  IAgency,
  IReviewUser,
  IUpdateReviewUserClients,
} from '../../../redux/user/UserModel';
import { ISubmitPayloadDetails } from './ClientSelectionModal';

interface IRenderClientListProps {
  type: string;
  clientList: IAgency[] | IAdvertiser[];
  selectedClient: string;
  setSelectedClient: (value: string) => void;
  payload: IUpdateReviewUserClients;
  setConfirmUserPayload: (payload: ISubmitPayloadDetails) => void;
  confirmUserPayload: ISubmitPayloadDetails;
  reviewUserDetails: IReviewUser;
  handleEditUserChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
}

export enum ClientSelectTypes {
  Agency = 'agencyId',
  Advertiser = 'advertiserId',
  AgencyName = 'agencyName',
  AdvertiserName = 'advertiserName',
}

const RenderClientList = ({
  type,
  clientList,
  selectedClient,
  setSelectedClient,
  payload,
  confirmUserPayload,
  setConfirmUserPayload,
  reviewUserDetails,
  handleEditUserChange,
}: IRenderClientListProps) => {
  const idValue =
    type === ClientTypes.Agency ? ClientSelectTypes.Agency : ClientSelectTypes.Advertiser;
  const nameValue =
    type === ClientTypes.Agency ? ClientSelectTypes.AgencyName : ClientSelectTypes.AdvertiserName;

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>, client: any) => {
    setSelectedClient(event.target.value);
    handleEditUserChange && handleEditUserChange(event);
    setConfirmUserPayload({
      ...confirmUserPayload,
      clientName: client[nameValue],
      clientId: event.target.value,
      clientType: payload.clientType,
      ssoId: reviewUserDetails?.ssoId,
    });
  };

  React.useEffect(() => {
    const event = {
      target: {
        name: 'companyType',
        value: payload.clientType,
      },
    };

    handleEditUserChange && handleEditUserChange(event as React.ChangeEvent<HTMLInputElement>);
  }, [type]);

  return (
    <>
      {clientList?.map((client: any) => (
        <StyledOutlinedBox key={client.idValue}>
          <Box display={'flex'} justifyContent={'flex-start'} alignItems={'center'}>
            <Radio
              name='client'
              value={client[idValue]}
              checked={selectedClient === client[idValue]}
              onChange={(event) => handleChange(event, client)}
            />
            <Typography variant='h4'>{client[nameValue]}</Typography>
            <Typography variant='subtitle2' ml={5}>
              {client[idValue]}
            </Typography>
          </Box>
        </StyledOutlinedBox>
      ))}
    </>
  );
};
export default RenderClientList;
