import React, { ChangeEvent } from 'react';
import { Typography, Grid, Button, Box, useTheme } from '@mui/material';
import CustomDateRangePicker from '../../../components/DatePicker/DateRangePicker';
import { StyledReportCalendarContainer } from './ReportStyles';
import CloseIcon from '@mui/icons-material/Close';
import { ColorPartial } from '@mui/material/styles/createPalette';
import SpectrumDatePicker from '../../../components/DatePicker/DatePicker';

interface IReportDatePicker {
  heading: React.ReactNode;
  subHeading: React.ReactNode;
  helpText: React.ReactNode;
  date?: string;
  dateRange?: Array<string>;
  handleChange: (event: ChangeEvent<HTMLInputElement>) => void;
  confirmButtonText: string;
  onClose: () => void;
  onConfirm: () => void;
  isDateRange: boolean;
}

const ReportDatePicker = ({
  heading,
  subHeading,
  helpText,
  date,
  dateRange,
  handleChange,
  confirmButtonText,
  onClose,
  onConfirm,
  isDateRange,
}: IReportDatePicker) => {
  const theme = useTheme();
  const grey: ColorPartial = theme.palette.grey as ColorPartial;
  return (
    <div>
      <Typography variant='eyebrow'>{subHeading}</Typography>
      <Typography variant='h2'>{heading}</Typography>
      <Grid container spacing={15} sx={{ mt: 0 }} justifyContent="start">
        <Grid item mobile={isDateRange ? 3 : 6}>
          {helpText}
        </Grid>
        <Grid item mobile={isDateRange ? 9 : 4}>
          <StyledReportCalendarContainer>
            {isDateRange ? (
              <CustomDateRangePicker label='' value={dateRange} onChange={handleChange} inline />
            ) : (
              <SpectrumDatePicker label='' value={date} onChange={handleChange} inline />
            )}
          </StyledReportCalendarContainer>
        </Grid>
      </Grid>
      <Box sx={{ display: 'flex', justifyContent: 'space-between', mt: 12 }} gap={theme.spacing(4)}>
        <Button
          color='secondary'
          sx={{
            fontWeight: 500,
            padding: theme.spacing(8, 12),
          }}
          onClick={onClose}
        >
          <CloseIcon color='disabled' sx={{ mr: theme.spacing(2) }} />
          <Typography color={grey[500]}>Cancel</Typography>
        </Button>
        <Button
          sx={{
            borderRadius: theme.spacing(2),
            fontWeight: 500,
          }}
          disabled={isDateRange ? !(dateRange?.[0] && dateRange?.[1]) : !date}
          variant='contained'
          onClick={onConfirm}
          size='medium'
        >
          {confirmButtonText}
        </Button>
      </Box>
    </div>
  );
};

export default ReportDatePicker;
