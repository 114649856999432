import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Alert, Box, Button, MenuItem, Typography, useTheme } from '@mui/material';
import SpectrumDialog from '../../components/Spectrum/Dialog/SpectrumDialog';
import SpectrumSelectField from '../../components/Spectrum/Select/SpectrumSelect';
import { Close } from '@mui/icons-material';

import { useDispatch, useSelector } from 'react-redux';
import { RootState, StoreDispatch } from '../../redux/Store';
import { fetchNewAccountOwner, setNewAccountOwner, resetStatus, fetchClientDetails } from '../../redux/clients/ClientSlice';
import { ComponentLoader } from '../../components/Loaders/Loader';

interface IChangeAccountOwnerDialog {
  isOpen: boolean;
  onClose: () => void;
}

const ChangeAccountOwnerDialog = ({ isOpen, onClose }: IChangeAccountOwnerDialog) => {
  const theme = useTheme();
  const dispatch: StoreDispatch = useDispatch();
  const { clientId } = useParams();
  const [dropDownValue, setDropDownValue] = useState('');
  const {
    newAccountOwner,
    newAccountOwnerError,
    newAccountOwnerStatus,
    setNewAccountOwnerError,
    setNewAccountOwnerStatus,
    clientDetails
  } = useSelector((state: RootState) => state.clientData);

  const handleClose = () => {
    dispatch(resetStatus());
    onClose();
  };

  useEffect(() => {
    dispatch(fetchNewAccountOwner(clientDetails!.ssoId));
  }, []);

  useEffect(() => {
    if (setNewAccountOwnerStatus === 'succeeded') {
      dispatch(fetchClientDetails(clientId as string));
      handleClose();
    }
  }, [setNewAccountOwnerStatus]);

  const handleDropDownChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;
    setDropDownValue(value);
  };

  const handleSubmit = () => {
    dispatch(
      setNewAccountOwner({ existingOwnerSsoId: clientDetails!.ssoId, newAccountOwnerSsoId: dropDownValue }),
    );
  };

  const displayError = (error: string) => (
    <Box my={theme.spacing(6)}>
      <Alert severity='error' variant='outlined'>
        <Typography variant='paragraph2' component='span' color='error'>
          {error}
        </Typography>
      </Alert>
    </Box>
  );

  const renderDropDown = () => {
    switch (newAccountOwnerStatus) {
      case 'loading': {
        return <ComponentLoader />;
      }
      case 'succeeded': {
        return (
          <Box marginY={theme.spacing(12)}>
            <SpectrumSelectField
              label='New Account Owner *'
              variant='outlined'
              onChange={handleDropDownChange}
            >
              {newAccountOwner?.map((accountOwner) => (
                <MenuItem value={accountOwner.cognitoUserId} key={accountOwner.id}>
                  {accountOwner.firstName} {accountOwner.lastName}
                </MenuItem>
              ))}
            </SpectrumSelectField>
          </Box>
        );
      }
      case 'failed': {
        return displayError(newAccountOwnerError);
      }
    }
  };

  return (
    <SpectrumDialog open={isOpen} onClose={handleClose}>
      <Box marginBottom={theme.spacing(8)}>
        <Typography variant='eyebrow' marginBottom={theme.spacing(4)}>
          Set Account Owner
        </Typography>
        <Typography variant='h4'>Confirm Account Owner</Typography>
      </Box>
      {setNewAccountOwnerStatus === 'failed' && displayError(setNewAccountOwnerError)}
      <Typography variant='body1'>
        Are you sure you&apos;d like to update the account owner? Before proceeding, please be sure
        to verify this user&apos;s identity and that they are authorized to run this account.
        Additional content can be included here describing what the finance user would need to do
        before they should confirm this step.
      </Typography>
      {renderDropDown()}
      <Box display='flex' gap={theme.spacing(4)}>
        <Button
          variant='contained'
          size='large'
          sx={{ borderRadius: theme.spacing(2) }}
          onClick={handleSubmit}
          disabled={dropDownValue === ''}
        >
          Set Account Owner
        </Button>
        <Button
          variant='text'
          size='large'
          startIcon={<Close />}
          sx={{ borderRadius: theme.spacing(2) }}
          onClick={handleClose}
        >
          Cancel
        </Button>
      </Box>
    </SpectrumDialog>
  );
};

export default ChangeAccountOwnerDialog;
