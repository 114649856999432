import { styled } from '@mui/material/styles';
import { ColorPartial } from '@mui/material/styles/createPalette';
import { IconButton } from '@mui/material';

export const StyledLinksContainer = styled('div')(({ theme }) => {
  const primary: ColorPartial = theme.palette.primary as ColorPartial;
  const white: ColorPartial = theme.palette.white as ColorPartial;
  return {
    background: primary[800],
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'row',
    padding: theme.spacing(0, 8),
    '.links-container': {
      flexGrow: 1,
      textAlign: 'center',
      display: 'flex',
      flexDirection: 'column',
      '.link': {
        display: 'inline-block',
        textAlign: 'center',
        lineHeight: theme.spacing(16),
        height: theme.spacing(16),
        textDecoration: 'none',
        margin: theme.spacing(10),
        color: white[600],
        outline: 'none',
        '&:hover': {
          cursor: 'pointer',
          borderBottom: `4px solid ${primary[200]}`,
          color: white[500],
        },
        '&.link-active': {
          borderBottom: `4px solid ${primary[200]}`,
          color: white[500],
        },
      },
    },
  };
});
export const StyledIconButton = styled(IconButton)(({ theme }) => {
  const black: ColorPartial = theme.palette.black as ColorPartial;
  return {
    color: black[500],
  };
});
