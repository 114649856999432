import { styled, tableCellClasses, TablePagination } from '@mui/material';
import TableCell from '@mui/material/TableCell';
import { ColorPartial } from '@mui/material/styles/createPalette';
import TableContainer from '@mui/material/TableContainer';

export const StyledTableWrapper = styled('div')(() => {
  return {
    display: 'flex',
    flexDirection: 'column',
  };
});
export const StyledTableContainer = styled(TableContainer)(({ theme }) => {
  const black: ColorPartial = theme.palette.black as ColorPartial;
  return {
    borderRadius: theme.spacing(2),
    border: `1px solid ${black[100]}`,
    boxShadow: theme.shadows[2],
  };
});

export const StyledTableCell = styled(TableCell)(({ theme }) => {
  const black: ColorPartial = theme.palette.black as ColorPartial;
  return {
    [`&.${tableCellClasses.head}`]: {
      fontWeight: 600,
      fontSize: 16,
      '.MuiTableSortLabel-root': {
        width: '100%',
        display: 'flex',
        justifyContent: 'space-between',
      },
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 16,
      fontWeight: 400,
      borderColor: black[100],
    },
  };
});
export const StyledSortIcon = styled('div')(({ theme }) => {
  return {
    position: 'relative',
    height: theme.spacing(13),
    margin: theme.spacing(0, 4),
    flexDirection: 'column',
    '.asc-icon': {
      fontSize: '1.2rem',
      top: 0,
      position: 'absolute',
    },
    '.desc-icon': {
      fontSize: '1.2rem',
      top: '8px',
      position: 'absolute',
    },
  };
});
export const StyledTablePagination = styled(TablePagination)(({ theme }) => {
  const primary: ColorPartial = theme.palette.primary as ColorPartial;
  const white: ColorPartial = theme.palette.white as ColorPartial;
  const black: ColorPartial = theme.palette.black as ColorPartial;
  return {
    color: black[50],
    border: 'none',
    '.MuiPaginationItem-root': {
      color: black[50],
      '&.Mui-selected': {
        backgroundColor: primary[500],
        color: white[500],
        '&:hover': {
          backgroundColor: primary[500],
          color: white[500],
        },
      },
    },
    '.MuiTablePagination-selectLabel': {
      marginRight: theme.spacing(4),
      fontSize: 16,
      textTransform: 'capitalize',
    },
    '.MuiInputBase-root': {
      fontSize: 16,
      marginRight: theme.spacing(4),
    },
    '.MuiTablePagination-select': {
      fontWeight: 500,
    },
    '.MuiTablePagination-displayedRows': {
      fontSize: 16,
      fontWeight: 500,
      '&::after': {
        fontWeight: 400,
        content: '" results"',
      },
    },
    '.MuiTablePagination-actions': {
      flexGrow: 1,
      display: 'flex',
      justifyContent: 'flex-end',
    },
    '.MuiTablePagination-spacer': {
      display: 'none',
    },
  };
});
