import React from 'react';
import { ITransactionData } from '../../redux/transactions/TransactionModel';
import { StyledTransactionExpandRowDetails } from './TransactionsStyles';
import { Box, Chip, Divider, Grid, Typography } from '@mui/material';
import { formatAmount } from '../../utils/common';

const TransactionExpandRowContent = (data: ITransactionData) => {
  return (
    <StyledTransactionExpandRowDetails>
      <Grid container spacing={8}>
        <Grid item laptop={3} sx={{ display: 'flex' }}>
          <Box width='100%'>
            <Typography variant='eyebrow' className='label'>
              INVOICE/ORDER DETAILS
            </Typography>
            <ul>
              {(data?.invoiceOrderDetails || []).map((invoiceOrderDetail) => {
                return (
                  <li key={invoiceOrderDetail?.number}>
                    <Box>
                      {invoiceOrderDetail?.type} <strong>#{invoiceOrderDetail?.number}</strong>
                      <Box display='inline' ml={2} mr={2}>
                        -
                      </Box>
                      <Box display='inline'>{formatAmount(invoiceOrderDetail?.amount)}</Box>
                    </Box>
                  </li>
                );
              })}
            </ul>
          </Box>
          <Divider orientation='vertical' className='divider'></Divider>
        </Grid>
        <Grid item laptop={9}>
          <Grid container>
            <Grid item laptop={4}>
              <Typography variant='eyebrow' className='label'>
                RECURRING PAYMENT ID
              </Typography>
              <Box className='value'>{data?.recurringPaymentId || 'N/A'}</Box>
            </Grid>
            <Grid item laptop={4}>
              <Typography variant='eyebrow' className='label'>
                ERROR MESSAGE
              </Typography>
              <Box className='value'>{data?.errorMessage || 'N/A'}</Box>
            </Grid>
            <Grid item laptop={4}>
              <Typography variant='eyebrow' className='label'>
                PAYMENT TYPE
              </Typography>
              <Box className='value'>
                <Chip label={data?.paymentType} color='success' variant='rectangle' size='small' />
              </Box>
            </Grid>
            <Grid item laptop={12} sx={{ mt: 8 }}>
              <Typography variant='eyebrow' className='label'>
                Description
              </Typography>
              <Box className='value description'>{data?.description || 'No Notes Includes'}</Box>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </StyledTransactionExpandRowDetails>
  );
};
export default TransactionExpandRowContent;
