import React, { ChangeEvent, useEffect, KeyboardEvent } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { MenuItem, Grid } from '@mui/material';
import SpectrumSearch from '../../components/Spectrum/Search/SpectrumSearch';
import CustomDateRangePicker from '../../components/DatePicker/DateRangePicker';
import SpectrumSelectField from '../../components/Spectrum/Select/SpectrumSelect';
import { RootState, StoreDispatch } from '../../redux/Store';
import { updateFilters } from '../../redux/transactions/TransactionsSlice';
import { IPaymentStatus } from '../../redux/transactions/TransactionModel';

const TransactionFilters = () => {
  const dispatch: StoreDispatch = useDispatch();
  const { filters, paymentStatusList } = useSelector((state: RootState) => state.transactionsData);
  const [filterValues, setFilterValues] = React.useState(filters);

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    const name = event.target.name;
    const value: any = event.target.value;
    if (name === 'date') {
      const [startDate, endDate] = value;
      setFilterValues({ ...filterValues, startDate, endDate, start: 0 });
      if ((startDate && endDate) || (!startDate && !endDate)) {
        dispatch(updateFilters({ ...filterValues, startDate, endDate, start: 0 }));
      }
      return;
    }
    if (name === 'paymentStatus') {
      setFilterValues({ ...filterValues, [name]: value, start: 0 });
      dispatch(updateFilters({ ...filterValues, [name]: value, start: 0 }));
      return;
    }
    setFilterValues({ ...filterValues, [name]: value, start: 0 });
  };

  const handleKeyDown = (event: KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter') {
      dispatch(updateFilters(filterValues));
    }
  };

  useEffect(() => {
    setFilterValues(filters);
  }, [filters]);

  return (
    <div>
      <Grid container spacing={4}>
        <Grid item laptop={6}>
          <SpectrumSearch
            placeholder='Search...'
            sx={{ width: '100%' }}
            size='small'
            name='searchValue'
            value={filterValues.searchValue}
            onChange={handleChange}
            onKeyDown={handleKeyDown}
          />
        </Grid>
        <Grid item laptop={3}>
          <CustomDateRangePicker
            size='small'
            value={[filterValues.startDate, filterValues.endDate]}
            label=''
            name='date'
            placeholder='Date Range'
            onChange={handleChange}
          />
        </Grid>
        <Grid item laptop={3}>
          <SpectrumSelectField
            variant='outlined'
            size='small'
            name='paymentStatus'
            value={filterValues.paymentStatus}
            onChange={handleChange}
          >
            <MenuItem value={0}>All</MenuItem>
            {paymentStatusList.map((status: IPaymentStatus) => (
              <MenuItem key={status?.id} value={status?.id}>
                {status?.description}
              </MenuItem>
            ))}
          </SpectrumSelectField>
        </Grid>
      </Grid>
    </div>
  );
};
export default TransactionFilters;
