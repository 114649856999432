import React, { ChangeEvent, useEffect, KeyboardEvent, useState } from 'react';
import Grid from '@mui/material/Grid';
import SpectrumSearch from '../../components/Spectrum/Search/SpectrumSearch';
import { useDispatch, useSelector } from 'react-redux';
import { RootState, StoreDispatch } from '../../redux/Store';
import CustomDateRangePicker from '../../components/DatePicker/DateRangePicker';
import { updateFilters } from '../../redux/adPortalTransaction/AdPortalTransactionSlice';

const AdPortalFilters = () => {
  const dispatch: StoreDispatch = useDispatch();
  const { filters } = useSelector((state: RootState) => state.adPortalTransactionsData);
  const [filterValues, setFilterValues] = useState(filters);

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    const { target } = event;
    const { name } = target;
    const value: any = target.value;
    if (name === 'date') {
      const [startDate, endDate] = value;
      setFilterValues((prevFilterValues) => ({ ...prevFilterValues, startDate, endDate, start: 0 }));
      if ((startDate && endDate) || (!startDate && !endDate)) {
        dispatch(updateFilters({ ...filterValues, startDate, endDate, start: 0 }));
      }
      return;
    }
    setFilterValues((prevFilterValues) => ({ ...prevFilterValues, [name]: value }));
  };

  const handleKeyDown = (event: KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter') {
      dispatch(updateFilters(filterValues));
    }
  };

  useEffect(() => {
    setFilterValues(filters);
  }, [filters]);

  return (
    <div>
      <Grid container spacing={4}>
        <Grid item laptop={9}>
          <SpectrumSearch
            placeholder='Search...'
            sx={{ width: '100%' }}
            size='small'
            name='searchValue'
            value={filterValues.searchValue}
            onChange={handleChange}
            onKeyDown={handleKeyDown}
          />
        </Grid>
        <Grid item laptop={3}>
          <CustomDateRangePicker
            size='small'
            value={[filterValues.startDate, filterValues.endDate]}
            label=''
            name='date'
            placeholder='Date Range'
            onChange={handleChange}
          />
        </Grid>
      </Grid>
    </div>
  );
};
export default AdPortalFilters;
