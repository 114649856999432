import { ClientTypes } from '../clients/ClientModel';

export interface IUser {
  firstName: string;
  lastName: string;
  company: null | string;
  companyId: number;
  companyType: string;
  client: null | string;
  clientId: null | string;
  role: IUserRole;
  status: string;
  createdDate: string;
  userId: number;
  userName: string;
  userEmail: string;
  userPhone: string;
  reportsTo: string;
  reportsToFirstName: string;
  reportsToLastName: string;
  reportsToUserName: string;
  reportsToSsoId: string;
  reportsToId: number;
  ssoId: string;
  userNotes: string;
  advertisers: IAdvertiser[];
}
export interface IReviewUser {
  userId: number;
  ssoId: string;
  firstName: string;
  lastName: string;
  userName: string;
  userEmail: string;
  userPhone: string;
  companyType: string;
  companyName: string;
  customerID: string;
  worksWithOtherAgencies: string;
  accountExecutive: string;
  recentInvoiceNumber: string;
  isUnderReview: boolean;
  status: string;
  createdDate: string;
}
export interface IConfirmReviewUserReqPayload {
  clientId: string;
  clientType: string;
  ssoId: string;
}

export interface IUserBasicInfo {
  userId: number;
  FirstName: string;
  LastName: string;
  User: string;
  Client: string;
  ClientId: null | string;
  Role: any;
  Status: string;
  Created: string;
  ssoId: string;
}

export interface IUserResponse {
  filteredRecords: number;
  totalRecords: number;
  data: IUserBasicInfo[];
}

export interface IAdvertiserListResponse {
  id: number;
  agencyId: number;
  tblAgency: any;
  advertiserId: number;
  tblAdvertiser: IAdvertiser;
}

export interface IAgency {
  id: number;
  agencyId: number;
  agencyName: string;
}

export interface IAdvertiser {
  id: number;
  advertiserId: number;
  advertiserName: string;
}

export interface IUserRole {
  id: number;
  name: string;
}

export interface IReportUser {
  id: number;
  cognitoUserId: string;
  acceptedTermsAndConditions: boolean;
  acceptedTermsAndConditionsDateTime: string;
  firstName: string;
  lastName: string;
  userRoleId: number;
  tblUserRole: TblUserRole;
  userStatusId: number;
  tblUserStatus: TblUserStatus;
  agencyId: number;
  tblAgency: TblAgency;
  advertiserId: any;
  tblAdvertiser: any;
  userNotes: any;
  reportsTo: any;
}

export interface TblUserRole {
  id: number;
  name: string;
}

export interface TblUserStatus {
  id: number;
  name: string;
}

export interface TblAgency {
  id: number;
  agencyId: number;
  agencyName: string;
}

export interface IClientDetailsResponse {
  id: number;
  clientType: string;
  clientName: string;
  clientId: string;
}

export interface IClientDetailsPayload {
  ssoId: string;
  clientType: string;
  clientId: string;
}

export interface IUsersState {
  status: string;
  data: null | IUserResponse;
  // Agency List
  agencyList: IAgency[];
  agencyListError: any;
  agencyListStatus: string;
  // Advertisers List
  advertiserList: IAdvertiser[];
  advertiserListError: any;
  advertiserListStatus: string;
  // Agency Advertiser List
  agencyAdvertiserListStatus: string;
  agencyAdvertiserList: IAdvertiserListResponse[];
  agencyAdvertiserListStatusError: string;
  // Report To
  reportsToUsersStatus: string;
  reportsToUsers: IReportUser[];
  reportsToUsersStatusError: string;
  // User Roles
  userRolesStatus: string;
  userRoles: IUserRole[];
  userRolesStatusError: string;
  // Reset User Password
  resetPasswordStatus: string;
  resetPasswordError: any;
  // Review User Details
  reviewUserDetails: null | IReviewUser;
  reviewUserDetailsStatus: string;
  reviewUserDetailsError: string;
  // User Details
  userDetails: null | IUser;
  userDetailsStatus: string;
  userDetailsStatusError: string;
  error: any;
  filters: IFilterPayload;
  userStatusList: IUserStatus[];
  userStatusListStatus: string;
  userStatusListStatusError: string;
  scheduleAutoPayments: any[];
  // Selected Client Details
  selectedClientDetailsStatus: string;
  selectedClientDetailsResponse: IClientDetailsResponse;
  selectedClientDetailsError: string;
}

export interface IFilterPayload {
  start: number;
  length: number;
  sortColumn: string;
  sortDirection: string;
  searchValue: string;
  startDate: null | string | Date;
  endDate: null | string | Date;
  status?: number;
  role?: number;
  companyId?: number | null;
  companyType?: string | null;
}

export interface IUserStatus {
  id: number;
  name: string;
}
export enum Roles {
  ACCOUNT_OWNER = 1,
  MANAGER = 2,
  TEAM_MEMBER = 3,
}
export enum RoleValues {
  ACCOUNT_OWNER = 'Account Owner',
  MANAGER = 'Manager',
  TEAM_MEMBER = 'Team Member',
}
export interface IUserRequestPayload {
  ssoId: string;
  clientType: string;
  clientId: number;
  roleId: number;
  reportsToSSOId: string;
  notes: string;
  advertisers: IAdvertiser[];
}

export interface ILocalUserDetails {
  companyType: ClientTypes;
  client: any;
  roleId: any;
  reportsToSSOId: string;
  advertisers: IAdvertiser[];
}
export interface IErrorLocalUserDetails {
  companyType: string;
  client: string;
  roleId: string;
  reportsToSSOId: string;
  advertisers: string;
}

export interface IUpdateReviewUserClients {
  clientType: string;
  searchType: string;
  searchValue: string;
}

export enum PeopleTableActions {
  RESET_PASSWORD = 'RESET_PASSWORD',
  ACTIVATE_DEACTIVATE_USER = 'ACTIVATE_DEACTIVATE_USER',
}

export enum StatusTypes {
  Processed = 'Processed',
  Failed = 'Failed',
  Canceled = 'Canceled',
  Processing = 'Processing',
  Received = 'Received',
  Active = 'Active',
  Pending = 'Pending',
  NeedsReview = 'NeedsReview',
}
