import React from 'react';
import { CircularProgress } from '@mui/material';
import { LoaderContainer } from './LoaderStyles';

export function PageLoader() {
  return (
    <LoaderContainer id='loader' className='pageLoader'>
      <CircularProgress className='pageLoaderImg' size={50} aria-label='Loading...' />
    </LoaderContainer>
  );
}

export function ComponentLoader() {
  return (
    <LoaderContainer data-testid='loader' id='loader' className='componentLoader'>
      <CircularProgress className='componentLoaderImg' size={50} aria-label='Loading...' />
    </LoaderContainer>
  );
}

export function ComponentOverlayLoader() {
  return (
    <LoaderContainer data-testid='loader' id='loader' className='componentLoader overlay'>
      <CircularProgress className='componentLoaderImg' size={50} aria-label='Loading...' />
    </LoaderContainer>
  );
}
