import React from 'react';
import PageContentContainer from '../../components/PageContentContainer/PageContentContainer';
import TransactionsTable from './TransactionsTable';
import { Box, Grid, Typography, useTheme } from '@mui/material';
import TransactionFilters from './TransactionFilters';
import Reports from './Reports/Reports';

const Transactions = () => {
  const theme = useTheme();
  return (
    <PageContentContainer>
      <Typography mb={theme.spacing(12)} variant='h1'>
        Transactions
      </Typography>
      <Grid container spacing={14}>
        <Grid item laptop={10}>
          <Box sx={{ mb: 6 }}>
            <TransactionFilters />
          </Box>
          <TransactionsTable />
        </Grid>
        <Grid item laptop={2}>
          <Reports />
        </Grid>
      </Grid>
    </PageContentContainer>
  );
};

export default Transactions;
