import React, { ReactNode } from 'react';
import { Typography, Card, useTheme, Button, Box } from '@mui/material';
import ArrowRightAltIcon from '@mui/icons-material/ArrowRightAlt';
import WarningIcon from '@mui/icons-material/Warning';
import PageContentContainer from '../PageContentContainer/PageContentContainer';
import { SARF } from '../../constants/common';

interface INoAccessPage {
  title: ReactNode;
  message: ReactNode;
}
const NoAccessPage = ({ title, message }: INoAccessPage) => {
  const theme = useTheme();

  const redirectToSARF = (): void => {
    window.location.href = SARF;
  };

  return (
    <PageContentContainer>
      <Box
        display='flex'
        alignItems='center'
        flexDirection='column'
        textAlign='center'
        p={theme.spacing(5)}
        margin={theme.spacing(12, 'auto', 0)}
        maxWidth={800}
      >
        <Typography variant='h1' maxWidth={450}>
          {title}
        </Typography>
        <Typography
          color='info'
          mt={theme.spacing(12)}
          mb={theme.spacing(8)}
          variant='body1'
        >
          {message}
        </Typography>
        <Typography
          color='error'
          fontWeight={700}
          sx={{
            display: 'flex',
            alignItems: 'center',
          }}
        >
          <WarningIcon color='error' sx={{ mr: theme.spacing(4) }} />
          Reachme cannot provide access to this website.
        </Typography>
        <Button
          onClick={redirectToSARF}
          sx={{
            mt: theme.spacing(12),
            borderRadius: 1,
            paddingY: theme.spacing(8),
            fontWeight: 500,
          }}
          variant='contained'
        >
          Continue to eSARF
          <ArrowRightAltIcon />
        </Button>
      </Box>
    </PageContentContainer>
  );
};
export default NoAccessPage;
