import {
  IExternalLinkType,
  IReportDownloadType,
  IReportType,
} from '../../../redux/transactions/TransactionModel';
import {appConstants} from '../../../constants/apiConstants';

export enum ReportType {
  TRANSACTION_REPORT = 'TRANSACTION_REPORT',
  USER_REPORT = 'USER_REPORT',
  RECURRING_PAYMENTS_REPORT = 'RECURRING_PAYMENTS_REPORT',
  ADPORTAL_TRANSACTION_REPORT = 'ADPORTAL_TRANSACTION_REPORT',
}

export const ReportTypes: IReportType[] = [
  {
    label: 'Transaction Report',
    type: 'TRANSACTION_REPORT',
  },
  {
    label: 'User Report',
    type: 'USER_REPORT',
  },
  {
    label: 'Recurring Payments Report',
    type: 'RECURRING_PAYMENTS_REPORT',
  },
];
export const ExternalLinks: IExternalLinkType[] = [
  {
    label: 'SAP',
    link: appConstants.sapURL,
  },
  {
    label: 'Anacab',
    link: appConstants.anacabURL,
  },
];
export const TransactionReportDownloadTypes: IReportDownloadType[] = [
  {
    label: 'Transaction Report',
    format: 'CSV',
    type: ReportType.TRANSACTION_REPORT,
    id: 1,
  },
  {
    label: 'Transaction Report',
    type: ReportType.TRANSACTION_REPORT,
    format: 'TXT',
    id: 2,
  },
  {
    label: 'Adportal Report',
    type: ReportType.ADPORTAL_TRANSACTION_REPORT,
    format: 'CSV',
    id: 3,
  },
];
export const ClientDetailsTransactionReportDownloadTypes: IReportDownloadType[] = [
  {
    label: 'Transaction Report',
    format: 'CSV',
    type: ReportType.TRANSACTION_REPORT,
    id: 1,
  },
  {
    label: 'Transaction Report',
    type: ReportType.TRANSACTION_REPORT,
    format: 'TXT',
    id: 2,
  },
];
export const UserReportDownloadTypes: IReportDownloadType[] = [
  {
    label: 'User Report',
    format: 'CSV',
    id: 1,
  },
];
export const RecurringPaymentsReportDownloadTypes: IReportDownloadType[] = [
  {
    label: 'Recurring Payments Report',
    format: 'CSV',
    id: 1,
  },
];
