import React, { useEffect, useState } from 'react';
import { Alert, Box, Button, Card, Divider, Typography, useTheme } from '@mui/material';
import { ColorPartial } from '@mui/material/styles/createPalette';
import PageContentContainer from '../../../components/PageContentContainer/PageContentContainer';
import { formatAmount } from '../../../utils/common';
import { useDispatch, useSelector } from 'react-redux';
import { RootState, StoreDispatch } from '../../../redux/Store';
import {
  fetchTransactionDetails,
  updateTransactionDetails,
} from '../../../redux/transactions/TransactionsSlice';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { PageLoader } from '../../../components/Loaders/Loader';
import EditConfirmationDialog from './EditConfirmationDialog';
import PaymentIcon from '@mui/icons-material/Payment';
import AccountBalanceIcon from '@mui/icons-material/AccountBalance';
import TransactionAdvertisers from './TransactionAdvertisers';
import { useNavigate, useParams } from 'react-router-dom';
import { IAdvertiserItem } from '../../../redux/transactions/TransactionModel';

const EditTransaction = () => {
  const theme = useTheme();
  const blue: ColorPartial = theme.palette.blue as ColorPartial;
  const dispatch: StoreDispatch = useDispatch();
  const { ssoId, transactionId } = useParams();
  const navigate = useNavigate();

  const [dialogStatus, setDialogStatus] = useState(false);

  const { transactionDetails, transactionDetailsError, transactionDetailsStatus } = useSelector(
    (state: RootState) => state.transactionsData,
  );

  const newTotalAmount = (transactionDetails?.advertisers || []).reduce((total, advertiser) => {
    const advTotal = advertiser.items.reduce((total, item) => {
      return total + Number(item.newAmount);
    }, 0);
    return total + advTotal;
  }, 0);

  const handleUpdate = (advertisers: IAdvertiserItem[]) => {
    dispatch(updateTransactionDetails({ ...transactionDetails, advertisers }));
  };

  const toggleDialog = () => {
    setDialogStatus((prev) => !prev);
  };

  const handleSave = () => {
    toggleDialog();
  };

  useEffect(() => {
    if (transactionId && ssoId) {
      dispatch(
        fetchTransactionDetails({
          ssoId,
          transactionId: parseInt(transactionId),
        }),
      );
    }
  }, []);

  if (transactionDetailsStatus === 'loading') return <PageLoader />;

  if (transactionDetailsStatus === 'failed')
    return (
      <Box my={theme.spacing(6)}>
        <Alert severity='error' variant='outlined'>
          <Typography variant='paragraph2' component='span' color='error'>
            {transactionDetailsError}
          </Typography>
        </Alert>
      </Box>
    );

  return (
    <PageContentContainer>
      <Button variant='text' onClick={() => navigate(-1)} startIcon={<ArrowBackIcon />}>
        Back
      </Button>
      <Card variant='outlined' sx={{ p: theme.spacing(12), mt: theme.spacing(8) }}>
        <Typography variant='h2' mb={12}>
          Edit Payment Amount
        </Typography>
        <Box
          sx={{
            flexGrow: 1,
          }}
        >
          <Card
            sx={{
              flexGrow: 1,
            }}
          >
            <Box sx={{ background: blue[100], border: `1px solid ${blue[200]}`, p: 10, mb: 12 }}>
              <Box display='flex' alignItems='center' justifyContent='space-between'>
                <Typography variant='h3'>Schedule Payment</Typography>
                <Typography variant='h6' display='flex' alignItems='center'>
                  {transactionDetails?.mop === 'Card' ? (
                    <PaymentIcon sx={{ mr: 2 }} color='inherit' fontSize='medium' />
                  ) : (
                    <AccountBalanceIcon sx={{ mr: 2 }} color='inherit' fontSize='medium' />
                  )}
                  {transactionDetails?.mopLastFour}
                </Typography>
              </Box>
              <Typography mt={2} variant='body1' color='grey' fontSize='small'>
                Transaction # {transactionDetails?.spsTransactionId}
              </Typography>
              <Divider />
              <TransactionAdvertisers
                advertisers={transactionDetails?.advertisers || []}
                handleUpdate={handleUpdate}
              />
            </Box>
            <>
              <Divider />
              <Box sx={{ display: 'flex', justifyContent: 'space-between', mt: 8, mb: 8 }}>
                <Typography variant='h6'>Total:</Typography>
                <Typography variant='h6'>{formatAmount(newTotalAmount || 0)}</Typography>
              </Box>
              <Box display='flex' flexDirection='row' justifyContent='end' mt={24}>
                <Button
                  variant='outlined'
                  size='small'
                  sx={{
                    width: 'fit-content',
                    padding: theme.spacing(6, 16),
                    mr: theme.spacing(8),
                  }}
                >
                  Cancel
                </Button>
                <Button
                  variant='contained'
                  size='small'
                  onClick={handleSave}
                  sx={{
                    width: 'fit-content',
                    padding: theme.spacing(6, 24),
                  }}
                >
                  Save
                </Button>
              </Box>
            </>
          </Card>
        </Box>
      </Card>
      {dialogStatus && (
        <EditConfirmationDialog
          isOpen={dialogStatus}
          onClose={toggleDialog}
          data={transactionDetails}
          newTotal={newTotalAmount}
        />
      )}
    </PageContentContainer>
  );
};

export default EditTransaction;
