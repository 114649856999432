import { configureStore } from '@reduxjs/toolkit';
import AuthDataReducer from './auth/AuthSlice';
import AdPortalTransactionReducer from './adPortalTransaction/AdPortalTransactionSlice';
import ClientReducer from './clients/ClientSlice';
import RecurringPaymentsReducer from './recurringPayments/RecurringPaymentsSlice';
import TransactionReducer from './transactions/TransactionsSlice';
import UserReducer from './user/UserSlice';
import ContentManagerReducer from './contentManager/ContentManagerSlice';

export const store = configureStore({
  reducer: {
    authData: AuthDataReducer,
    adPortalTransactionsData: AdPortalTransactionReducer,
    clientData: ClientReducer,
    recurringPaymentsData: RecurringPaymentsReducer,
    transactionsData: TransactionReducer,
    userData: UserReducer,
    contentManager: ContentManagerReducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
});

// Infer the `RootState` and `StoreDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type StoreDispatch = typeof store.dispatch;
