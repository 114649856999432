import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { adPortalTransactionEndpoints } from '../../constants/apiConstants';
import { IAdPortalTransactionsState, IFilterPayload } from './AdPortalTransactionModel';
import ApiService from '../../services/ApiService';

const initialState: IAdPortalTransactionsState = {
  status: 'idle',
  data: null,
  error: '',
  filters: {
    start: 0,
    length: 10,
    sortColumn: '',
    sortDirection: '',
    searchValue: '',
  }
};

export const fetchAdPortalTransactions = createAsyncThunk(
  'AdPortal/GetAdPortalTransactions',
  async (payload: IFilterPayload) => {
    const response = await ApiService.postData(adPortalTransactionEndpoints.list, {}, payload);
    return response.data;
  },
);

export const AdPortalTransactionsSlice = createSlice({
  name: 'adPortalTransactions',
  initialState,
  reducers: {
    updateFilters: (state, action) => {
      state.filters = action?.payload;
    },
  },
  extraReducers(builder) {
    builder
      .addCase(fetchAdPortalTransactions.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchAdPortalTransactions.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.data = action.payload.data;
        state.error = '';
      })
      .addCase(fetchAdPortalTransactions.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action?.error?.message || 'Something went wrong, please try again later';
      })
  }
})

export const { updateFilters } = AdPortalTransactionsSlice.actions;
export default AdPortalTransactionsSlice.reducer;
