import {
  IAuthState,
  IFetchAccessTokenUsingRefreshToken,
  IFetchTokenUsingCodePayload,
} from './AuthModels';
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import ApiService from '../../services/ApiService';
import { appConstants, authApiEndPoints, ssoConfig } from '../../constants/apiConstants';
import queryString from 'query-string';
import { constants, getAuthItem, removeTokens, saveToken } from '../../utils/authUtils';

const initialState: IAuthState = {
  error: null,
  accessTokenStatus: 'idle',
};

export const fetchTokenUsingAuthCode = createAsyncThunk(
  'auth/fetchTokenUsingCode',
  async (authCode: string) => {
    const payload: IFetchTokenUsingCodePayload = {
      grant_type: 'authorization_code',
      code: authCode,
      redirect_uri: appConstants.callbackURL,
      client_id: ssoConfig.clientId,
    };
    const formData = queryString.stringify(payload);
    const response = await ApiService.postData(authApiEndPoints.tokenURL, {}, formData, {
      'Content-Type': 'application/x-www-form-urlencoded',
    });
    return response.data;
  },
);
export const fetchAccessTokenUsingRefreshToken = createAsyncThunk(
  'auth/fetchAccessTokenUsingRefreshToken',
  async () => {
    const refreshToken = getAuthItem(constants.REFRESH_TOKEN);
    const payload: IFetchAccessTokenUsingRefreshToken = {
      grant_type: 'refresh_token',
      client_id: ssoConfig.clientId,
      refresh_token: refreshToken || '',
    };
    const formData = queryString.stringify(payload);
    const response = await ApiService.postData(authApiEndPoints.tokenURL, {}, formData, {
      'Content-Type': 'application/x-www-form-urlencoded',
    });
    return response.data;
  },
);
export const AuthDataSlice = createSlice({
  name: 'authData',
  initialState,
  reducers: {
    logoutUser: (state, action) => {
      removeTokens();
      state.accessTokenStatus = 'idle';
    },
  },
  extraReducers(builder) {
    builder
      .addCase(fetchTokenUsingAuthCode.pending, (state) => {
        state.accessTokenStatus = 'loading';
      })
      .addCase(fetchTokenUsingAuthCode.fulfilled, (state, action) => {
        state.accessTokenStatus = 'succeeded';
        saveToken(action.payload);
      })
      .addCase(fetchTokenUsingAuthCode.rejected, (state, action) => {
        state.accessTokenStatus = 'failed';
        state.error = action?.error?.message || 'Failed to get token details';
      })
      .addCase(fetchAccessTokenUsingRefreshToken.pending, (state) => {
        state.accessTokenStatus = 'loading';
      })
      .addCase(fetchAccessTokenUsingRefreshToken.fulfilled, (state, action) => {
        state.accessTokenStatus = 'succeeded';
        saveToken(action.payload);
      })
      .addCase(fetchAccessTokenUsingRefreshToken.rejected, (state, action) => {
        state.accessTokenStatus = 'failed';
        state.error = action?.error?.message || 'Failed to renew access token';
      });
  },
});
export const { logoutUser } = AuthDataSlice.actions;
export default AuthDataSlice.reducer;
