import React, { useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import { Alert, Box, Typography, useTheme } from '@mui/material';
import ReplayIcon from '@mui/icons-material/Replay';
import SpectrumTable, { IColumn, Order } from '../../components/Spectrum/Table/SpectrumTable';
import StatusCell from '../../components/Spectrum/Table/StatusCell';
import KebabMenu, { IOption } from '../../components/Spectrum/Table/KebabMenu';
import TransactionExpandRowContent from './TransactionExpandRowContent';
import PaymentTypeCell from './PaymentTypeCell';
import { StyledDateCell } from './TransactionsStyles';
import { formatAmount, formatDate, formatStringToDate } from '../../utils/common';
import { RootState, StoreDispatch } from '../../redux/Store';
import {
  fetchPaymentStatusList,
  fetchTransactions,
  updateFilters,
} from '../../redux/transactions/TransactionsSlice';
import { DATE_FORMAT, FILTER_DATE_FORMAT, PAYMENT_TYPES } from '../../constants/common';
import {
  defaultTransactionFiltersData,
  IFilterPayload,
  ITransactionData,
} from '../../redux/transactions/TransactionModel';
import { PATHS } from '../../routes/Routes';
import { StatusTypes } from '../../redux/user/UserModel';
import { useGetCurrentFilters, useFilterHandlers } from '../../utils/customUtilHooks';

const TransactionsTable = () => {
  const theme = useTheme();
  const dispatch: StoreDispatch = useDispatch();
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const [searchParams] = useSearchParams();
  const isInitialMount = useRef(true);
  const {
    data,
    status,
    error,
    filters,
    paymentStatusListError,
    paymentStatusListStatus,
    filters: { sortDirection, sortColumn, length },
  } = useSelector((state: RootState) => state.transactionsData);
  const isUserDetailPage = pathname.search(PATHS.PEOPLE) > -1;
  const isTransactionDetailPage = pathname.search(PATHS.TRANSACTIONS) > -1;
  const columns = [
    {
      label: 'Date',
      sortKey: 'Date',
      accessorKey: 'date',
      render: (data: ITransactionData) => (
        <StyledDateCell>
          <div>{formatDate(data.date, DATE_FORMAT)}</div>
          {data?.paymentType === PAYMENT_TYPES.Recurring && (
            <div className='info'>
              <ReplayIcon className='info-icon' /> <span>RECURRING</span>
            </div>
          )}
        </StyledDateCell>
      ),
    },
    {
      label: 'Payment ID',
      sortKey: 'PaymentID',
      accessorKey: 'paymentID',
    },
    {
      label: 'User',
      sortKey: 'User',
      accessorKey: 'user',
      render: (data: ITransactionData) => (
        <div>
          <strong>{data.user}</strong>
          <Typography color='primary' variant='underline'>
            <strong>{data.userName}</strong>
          </Typography>
        </div>
      ),
    },
    {
      label: 'Email',
      sortKey: 'Email',
      accessorKey: 'email',
    },
    {
      label: 'Client',
      sortKey: 'ClientName',
      accessorKey: 'client',
      render: (data: ITransactionData) => (
        <div>
          <div>
            <strong>{data.clientName}</strong>
          </div>
          <Typography variant='subtitle2'>{data.clientId}</Typography>
        </div>
      ),
    },
    {
      label: 'Amount',
      sortKey: 'Amount',
      accessorKey: 'amount',
      render: (data: ITransactionData) => (
        <div>
          <strong>{formatAmount(data.amount)}</strong>
        </div>
      ),
    },
    isUserDetailPage && {
      label: 'Type',
      sortKey: 'PaymentType',
      accessorKey: 'paymentType',
      render: (data: ITransactionData) => <PaymentTypeCell type={data.paymentType} />,
    },
    {
      label: 'Status',
      sortKey: 'Status',
      accessorKey: 'status',
      render: (data: ITransactionData) => <StatusCell status={data.status} />,
    },
    {
      label: '',
      sortKey: '',
      accessorKey: '',
      collapseRowTrigger: true,
      width: '10px',
    },
    {
      label: '',
      sortKey: '',
      accessorKey: '',
      render: (data: ITransactionData) => (
        <KebabMenu
          options={
            [
              !isUserDetailPage && {
                label: 'View User Details',
                onClick: () => navigate(`${PATHS.PEOPLE}/${data.ssoId}`),
              },
              {
                label: 'View Client Details',
                onClick: () =>
                  navigate(
                    `${PATHS.CLIENTS}/${data.ssoId}?companyId=${data.companyId}&companyType=${data.companyType}`,
                  ),
              },
              data.paymentType === 'Scheduled' &&
                data.status === StatusTypes.Received && {
                  label: 'Edit Payment',
                  onClick: () => navigate(`${PATHS.TRANSACTIONS}/${data.ssoId}/${data.paymentID}`),
                },
            ].filter(Boolean) as IOption[]
          }
        />
      ),
      width: '10px',
    },
  ].filter(Boolean);
  const updateFiltersAction = (updateFields: Partial<typeof filters>) => {
    dispatch(updateFilters({ ...filters, ...updateFields }));
  };

  const { handleSort, handlePageChange, handleChangeItemsPerPage } = useFilterHandlers<
    typeof filters
  >(filters, updateFiltersAction);

  useEffect(() => {
    const currentFiltersData = useGetCurrentFilters(
      searchParams,
      defaultTransactionFiltersData,
      formatStringToDate,
    );
    // update to store
    dispatch(updateFilters({ ...currentFiltersData }));
    dispatch(fetchPaymentStatusList());
    // Reset filters
    return () => {
      dispatch(updateFilters(defaultTransactionFiltersData));
    };
  }, []);

  useEffect(() => {
    if (isInitialMount.current) {
      isInitialMount.current = false;
      return;
    }
    const updatedFilters: { [x: string]: any } = {
      ...filters,
      startDate: filters?.startDate ? formatDate(filters?.startDate, FILTER_DATE_FORMAT) : null,
      endDate: filters?.endDate ? formatDate(filters?.endDate, FILTER_DATE_FORMAT) : null,
    };
    // Setting applied values to URL
    const params = new URLSearchParams();
    Object.keys(updatedFilters).forEach((filterKey) => {
      if (updatedFilters[filterKey] && filterKey !== 'userId') {
        params.append(filterKey, updatedFilters[filterKey]);
      }
    });
    if (isTransactionDetailPage) {
      navigate({ search: params.toString() }, { replace: true });
    }
    dispatch(fetchTransactions(updatedFilters as IFilterPayload));
  }, [filters, isInitialMount.current]);

  const isLoading = status === 'loading' || paymentStatusListStatus === 'loading';

  return (
    <>
      {status === 'failed' && (
        <Box mb={theme.spacing(12)}>
          <Alert severity='error' variant='outlined'>
            <Typography variant='paragraph2' component='span' color='error'>
              {error}
            </Typography>
          </Alert>
        </Box>
      )}
      {paymentStatusListStatus === 'failed' && (
        <Box mb={theme.spacing(12)}>
          <Alert severity='error' variant='outlined'>
            <Typography variant='paragraph2' component='span' color='error'>
              {paymentStatusListError}
            </Typography>
          </Alert>
        </Box>
      )}
      <SpectrumTable
        columns={columns as Array<IColumn>}
        data={data?.data}
        totalItems={data?.totalRecords}
        onSort={handleSort}
        orderBy={sortColumn}
        order={sortDirection as Order}
        itemsPerPage={length}
        currentPage={filters.start / filters.length}
        loading={isLoading}
        onPageChange={handlePageChange}
        onItemsPerPageChange={handleChangeItemsPerPage}
        collapseRowContent={TransactionExpandRowContent}
      />
    </>
  );
};

export default TransactionsTable;
