import { format, parseISO } from 'date-fns';

export const getLabelForAvatar = (text: string) => {
  if (!text) {
    return '';
  }
  const matches: any = text.match(/\b(\w)/g) || [];
  return matches.join('');
};
export const getFirstChar = (text: string) => {
  if (!text) {
    return '';
  }
  return text.charAt(0)?.toUpperCase();
};

export const nth = (n: number) => ['st', 'nd', 'rd'][((((n + 90) % 100) - 10) % 10) - 1] || 'th';
export const scrollToTop = () => window.scrollTo(0, 0);

/**
 * Formats a string by inserting a space before each uppercase letter that is not at the start of the string.
 * This function is used for preparing API values for UI display, especially when the values are in camelCase.
 * -
 * EX: 'NeedsReview' => 'Needs Review'
 * EX: 'needsReview' => 'needs Review'. 
*/
export function insertSpacesBeforeCapitals(str: string): string {
  if (/[A-Z].*[A-Z]/.test(str)) {
    return str.replace(/(.)([A-Z])/g, '$1 $2');
  }
  return str;
}

export const formatAmount = (amount: number) =>
  new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
  }).format(amount);

export const formatPhoneNumber = (phoneNumber: string) => {
  const localNumber = phoneNumber.replace(/^\+[0-9]/, '');
  return localNumber.replace(/(\d{3})(\d{3})(\d{4})/, '$1-$2-$3');
};

export const downloadFile = (blobPart: any, filename: string, format: string) => {
  const downloadLink = document.createElement('a');
  downloadLink.textContent = 'download';
  downloadLink.download = `${filename}.${format}`;
  const blob = new Blob(['\ufeff', blobPart]);
  const url = URL.createObjectURL(blob);
  downloadLink.href = url;
  document.body.appendChild(downloadLink);
  downloadLink.click();
  document.body.removeChild(downloadLink);
};
export const formatStringToDate = (date: string) => parseISO(date);
export const formatDate = (date: string, dateFormat: string) => format(new Date(date), dateFormat);
export const formatErrors = (errors: Record<string, string[]>): string => {
  const flattenErrors = (errors: Record<string, any>, prefix = ''): string[] => {
    let errorMessages: string[] = [];
    for (const key in errors) {
      if (typeof errors[key] === 'object') {
        errorMessages = errorMessages.concat(flattenErrors(errors[key], `${prefix}${key}`));
      } else if (Array.isArray(errors[key])) {
        errorMessages.push(`${prefix}: ${errors[key].join(', ')}`);
      } else {
        errorMessages.push(`${prefix}: ${errors[key]}`);
      }
    }
    return errorMessages;
  };
  const errorMessages = flattenErrors(errors);
  return errorMessages.length > 0 ? `Errors:\n${errorMessages.join('\n')}` : 'No errors';
};