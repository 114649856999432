import { Chip } from '@mui/material';
import React from 'react';

const PaymentTypeCell = ({ type }: { type: string }) => {
  const getTypeColor = () => {
    switch (type) {
      case 'Immediate':
        return 'success';
      case 'Recurring':
        return 'primary';
      case 'Scheduled':
        return 'orange';
      default:
        return 'default';
    }
  };

  return <Chip label={type} color={getTypeColor()} variant='rectangle' />;
};

export default PaymentTypeCell;
