// Packages
import React from 'react';
import { BrowserRouter } from 'react-router-dom';
import { Provider as ReduxProvider } from 'react-redux';
import { ThemeProvider } from '@mui/material';
import spectrumTheme from './styles/spectrum-material-theme';
import { Layout } from './components';
import Routes from './routes/Routes';
import { store } from './redux/Store';
import GetNewAccessToken from './pages/Auth/GetNewAccessToken';

// Styles
import './App.css';

const App = () => {
  return (
    <BrowserRouter>
      <ReduxProvider store={store}>
        <ThemeProvider theme={spectrumTheme}>
          <Layout>
            <GetNewAccessToken />
            <Routes />
          </Layout>
        </ThemeProvider>
      </ReduxProvider>
    </BrowserRouter>
  );
};

export default App;
