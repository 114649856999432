import React from 'react';
import { Popover } from '@mui/material';
import { NavLink } from 'react-router-dom';
import { ILink } from '../../../types';
import { StyledIconButton, StyledLinksContainer } from './DrawerStyles';
import MenuIcon from '@mui/icons-material/Menu';

interface IDrawerProps {
  links: Array<ILink>;
}
const DrawerComponent = ({ links }: IDrawerProps) => {
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'nav-popover' : undefined;
  const activeClassName = 'link link-active';
  return (
    <>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
      >
        <StyledLinksContainer>
          <div className='links-container center'>
            {links.map((link, index) => {
              return (
                <NavLink
                  key={index}
                  className={({ isActive }) => (isActive ? activeClassName : 'link')}
                  to={link.link}
                  onClick={handleClose}
                >
                  {link?.label}
                </NavLink>
              );
            })}
          </div>
        </StyledLinksContainer>
      </Popover>
      <StyledIconButton onClick={handleClick}>
        <MenuIcon />
      </StyledIconButton>
    </>
  );
};
export default DrawerComponent;
