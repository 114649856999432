import React, { useState } from 'react';
import { ChevronLeft, ChevronRight, Close, Edit } from '@mui/icons-material';
import { ColorPartial } from '@mui/material/styles/createPalette';
import { Alert, Box, Button, Card, TextField, Typography, useTheme } from '@mui/material';
import { RootState, StoreDispatch } from '../../redux/Store';
import { useDispatch, useSelector } from 'react-redux';
import { ComponentLoader } from '../../components/Loaders/Loader';
import { IFaqs } from '../../redux/contentManager/ContentManagerModel';
import { deleteFaqs, saveFaqs } from '../../redux/contentManager/ContentManagerSlice';
import AddNewQuestion from './AddNewQuestion';

const Faqs = ({ isHighlightBox }: { isHighlightBox: boolean }) => {
  const theme = useTheme();
  const black: ColorPartial = theme.palette.black as ColorPartial;
  const white: ColorPartial = theme.palette.white as ColorPartial;
  const dispatch: StoreDispatch = useDispatch();

  const { status, error, faqs = [] } = useSelector((state: RootState) => state.contentManager);
  const faqsFiltered = isHighlightBox
    ? faqs?.filter((faq) => (isHighlightBox ? !faq.isFaq : faq.isFaq)) || []
    : (faqs?.filter((faq) => (isHighlightBox ? !faq.isFaq : faq.isFaq)) || []).sort(
        (first, second) => first.priority - second.priority,
      );
  const lastFaq = faqsFiltered[faqsFiltered.length + 1];

  const [editableArray, setEditableArray] = useState<number[]>([]);
  const [formData, setFormData] = useState<{ question: string; answer: string }>({
    question: '',
    answer: '',
  });

  const handleEdit = ({ question, answer, id }: IFaqs) => {
    setEditableArray((prvState) => [...prvState, id]);
    setFormData({ question, answer });
  };

  const handleSave = (faq: IFaqs) => {
    dispatch(saveFaqs([{ ...faq, ...formData }]));
    setEditableArray((prvState) => prvState.filter((id) => id !== faq.id));
  };

  const handleCancel = (faq: IFaqs) => {
    const faqIndex = editableArray.indexOf(faq.id);
    const modEditableArray = [...editableArray];
    modEditableArray.splice(faqIndex, 1);
    setEditableArray(modEditableArray);
  };

  const handleRemove = (faq: IFaqs) => {
    dispatch(deleteFaqs(faq));
  };

  const handleFormChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    setFormData((prv) => ({ ...prv, [name]: value }));
  };

  const isEditableCheck = (id: number) => editableArray.includes(id);

  const changePriority = (id: number, increasePriority: boolean) => {
    const selectedIndex = faqsFiltered?.findIndex((faq) => faq.id === id) ?? -1;
    let updatedFaqs: IFaqs[] = [];
    if (selectedIndex !== -1 && faqsFiltered) {
      if (increasePriority) {
        updatedFaqs = [{ ...faqsFiltered[selectedIndex] }, { ...faqsFiltered[selectedIndex - 1] }];
        updatedFaqs[0].priority -= 1;
        updatedFaqs[1].priority += 1;
      } else {
        updatedFaqs = [{ ...faqsFiltered[selectedIndex] }, { ...faqsFiltered[selectedIndex + 1] }];
        updatedFaqs[0].priority += 1;
        updatedFaqs[1].priority -= 1;
      }
      dispatch(saveFaqs(updatedFaqs));
    }
    return;
  };

  const renderEditableElement = () => (
    <>
      <TextField
        sx={{ mt: 8 }}
        type='text'
        label='Question'
        value={formData.question}
        onChange={handleFormChange}
        name='question'
        fullWidth
      />
      <TextField
        sx={{ mt: 8 }}
        label='Answer'
        value={formData.answer}
        onChange={handleFormChange}
        name='answer'
        multiline
        rows={4}
        fullWidth
      />
    </>
  );

  const renderNonEditableElement = (faq: IFaqs, index: number) => (
    <Box display='flex'>
      <Box display='flex' flexDirection='column' flexGrow={1}>
        <Box display='flex' flexDirection='column' gap={5} mt={8}>
          <Typography variant='body2' sx={{ color: black[50] }}>
            Question
          </Typography>
          <Typography variant='body1'>{faq.question}</Typography>
        </Box>
        <Box display='flex' flexDirection='column' gap={5} mt={8}>
          <Typography variant='body2' sx={{ color: black[50] }}>
            Answer
          </Typography>
          <Typography variant='body1'>
            <pre style={{ fontFamily: 'inherit', whiteSpace: 'pre-wrap' }}>{faq.answer}</pre>
          </Typography>
        </Box>
      </Box>
      {!isHighlightBox && (
        <Box display='flex' flexDirection='column' gap={theme.spacing(1)}>
          <Button
            aria-label='chevron'
            size='small'
            sx={{ minWidth: 'unset', padding: theme.spacing(2), borderRadius: theme.spacing(2) }}
            disabled={index === 0}
            variant='contained'
            onClick={() => {
              changePriority(faq.id, true);
            }}
          >
            <ChevronLeft fontSize='inherit' sx={{ color: white[500], rotate: '90deg' }} />
          </Button>
          <Button
            aria-label='chevron'
            size='small'
            sx={{ minWidth: 'unset', padding: theme.spacing(2), borderRadius: theme.spacing(2) }}
            disabled={index === (faqsFiltered?.length ?? 1) - 1}
            variant='contained'
            onClick={() => {
              changePriority(faq.id, false);
            }}
          >
            <ChevronRight fontSize='inherit' sx={{ color: white[500], rotate: '90deg' }} />
          </Button>
        </Box>
      )}
    </Box>
  );

  return (
    <>
      <Card
        variant='outlined'
        sx={{
          p: 8,
          mt: 10,
          display: 'flex',
          flexDirection: 'column',
          boxShadow: theme.shadows[2],
        }}
      >
        <Typography variant='h2'>{isHighlightBox ? 'Highlight Box' : 'FAQs'}</Typography>
        {!isHighlightBox &&
          (status.updateFaqs.includes('failed') || status.getFaqs === 'failed') && (
            <Box mt={theme.spacing(6)}>
              <Alert severity='error' variant='outlined'>
                <Typography variant='paragraph2' component='span' color='error'>
                  {error}
                </Typography>
              </Alert>
            </Box>
          )}
        {status.getFaqs === 'loading' && <ComponentLoader />}
        {status.getFaqs === 'succeeded' &&
          faqsFiltered?.map((faq, i) => (
            <Card
              key={faq.id}
              variant='outlined'
              sx={{
                mt: 10,
                p: 12,
                boxShadow: theme.shadows[2],
              }}
            >
              {status.updateFaqs === `loading__${faq.id}` ? (
                <ComponentLoader />
              ) : (
                <>
                  {isEditableCheck(faq.id)
                    ? renderEditableElement()
                    : renderNonEditableElement(faq, i)}
                  <Box display='flex' gap={theme.spacing(4)}>
                    {isEditableCheck(faq.id) ? (
                      <>
                        <Button
                          variant={'contained'}
                          startIcon={!isEditableCheck(faq.id) && <Edit />}
                          sx={{ borderRadius: theme.spacing(2), mt: 8 }}
                          onClick={() => {
                            handleSave(faq);
                          }}
                        >
                          Save
                        </Button>
                        <Button
                          variant='text'
                          startIcon={<Close />}
                          sx={{ borderRadius: theme.spacing(2), mt: 8, color: black[50] }}
                          onClick={() => {
                            handleCancel(faq);
                          }}
                        >
                          Cancel
                        </Button>
                      </>
                    ) : (
                      <>
                        <Button
                          variant={'outlined'}
                          startIcon={<Edit />}
                          sx={{ borderRadius: theme.spacing(2), mt: 8 }}
                          onClick={() => handleEdit(faq)}
                        >
                          Edit
                        </Button>
                        <Button
                          variant='text'
                          startIcon={<Close />}
                          sx={{ borderRadius: theme.spacing(2), mt: 8, color: black[50] }}
                          onClick={() => {
                            handleRemove(faq);
                          }}
                        >
                          Remove
                        </Button>
                      </>
                    )}
                  </Box>
                </>
              )}
            </Card>
          ))}
        {(!isHighlightBox || !faqsFiltered.length) && (
          <AddNewQuestion isHighlightBox={isHighlightBox} priority={lastFaq?.priority || 0} />
        )}
      </Card>
    </>
  );
};

export default Faqs;
