import React, { ChangeEvent, useEffect, useState, KeyboardEvent } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Grid } from '@mui/material';

import { RootState, StoreDispatch } from '../../redux/Store';
import { updateFilters } from '../../redux/recurringPayments/RecurringPaymentsSlice';

import SpectrumSearch from '../../components/Spectrum/Search/SpectrumSearch';

const ScheduleAutoPaymentsFilters = () => {
  const dispatch: StoreDispatch = useDispatch();
  const { filters } = useSelector((state: RootState) => state.recurringPaymentsData);
  const [filterValues, setFilterValues] = useState(filters);

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    const name = event.target.name;
    const value: any = event.target.value;
    setFilterValues({ ...filterValues, [name]: value, start: 0 });
  };

  const handleKeyDown = (event: KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter') {
      dispatch(updateFilters(filterValues));
    }
  };

  useEffect(() => {
    setFilterValues(filters);
  }, [filters]);

  return (
    <div>
        <Grid item laptop={10}>
          <SpectrumSearch
            placeholder='Search...'
            sx={{ width: '100%' }}
            size='small'
            name='searchValue'
            value={filterValues.searchValue}
            onChange={handleChange}
            onKeyDown={handleKeyDown}
          />
      </Grid>
    </div>
  );
};
export default ScheduleAutoPaymentsFilters;
