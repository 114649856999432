import React from 'react';
import { Route, Routes } from 'react-router-dom';
import { Transactions } from '../pages';
import ProtectedRoute from './ProtectedRoute';
import AutoLogin from '../pages/Auth/AutoLogin';
import AdPortal from '../pages/AdPortal/AdPortal';
import Clients from '../pages/Clients/Clients';
import People from '../pages/People/People';
import PeopleDetails from '../pages/PeopleDetails/PeopleDetails';
import ClientDetails from '../pages/ClientDetails/ClientDetails';
import ClientManager from '../pages/ContentManager/ContentManager';
import ReviewDetails from '../pages/PeopleDetails/ReviewDetails/ReviewDetails';
import EditTransaction from '../pages/Transactions/EditTransaction/EditTransaction';

export const PATHS = {
  TRANSACTIONS: '/transactions',
  CLIENTS: '/clients',
  CLIENT_DETAILS: '/clients/:clientId',
  PEOPLE: '/people',
  REVIEW_PEOPLE: '/people/review',
  PEOPLE_DETAILS: '/people/:userId',
  REVIEW_PEOPLE_DETAILS: '/people/review/:userId',
  AD_PORTAL: '/ad-portal',
  CLIENT_MANAGER: '/client-manager',
};

const PageRoutes = () => (
  <Routes>
    <Route path='/' element={<AutoLogin />} />
    <Route
      path={PATHS.TRANSACTIONS}
      element={
        <ProtectedRoute>
          <Transactions />
        </ProtectedRoute>
      }
    />
    <Route
      path={PATHS.TRANSACTIONS + '/:ssoId/:transactionId'}
      element={
        <ProtectedRoute>
          <EditTransaction />
        </ProtectedRoute>
      }
    />
    <Route
      path={PATHS.AD_PORTAL}
      element={
        <ProtectedRoute>
          <AdPortal />
        </ProtectedRoute>
      }
    />
    <Route
      path={PATHS.CLIENTS}
      element={
        <ProtectedRoute>
          <Clients />
        </ProtectedRoute>
      }
    />
    <Route
      path={PATHS.CLIENT_DETAILS}
      element={
        <ProtectedRoute>
          <ClientDetails />
        </ProtectedRoute>
      }
    />
    <Route
      path={PATHS.PEOPLE}
      element={
        <ProtectedRoute>
          <People />
        </ProtectedRoute>
      }
    />
    <Route
      path={PATHS.PEOPLE_DETAILS}
      element={
        <ProtectedRoute>
          <PeopleDetails />
        </ProtectedRoute>
      }
    />
    <Route
      path={PATHS.REVIEW_PEOPLE_DETAILS}
      element={
        <ProtectedRoute>
          <ReviewDetails />
        </ProtectedRoute>
      }
    />
    <Route
      path={PATHS.CLIENT_MANAGER}
      element={
        <ProtectedRoute>
          <ClientManager />
        </ProtectedRoute>
      }
    />
  </Routes>
);

export default PageRoutes;
