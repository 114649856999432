import React, { ChangeEvent, useEffect, KeyboardEvent, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { MenuItem, Grid } from '@mui/material';
import { insertSpacesBeforeCapitals } from '../../utils/common';

import { RootState, StoreDispatch } from '../../redux/Store';
import { updateFilters } from '../../redux/clients/ClientSlice';

import SpectrumSearch from '../../components/Spectrum/Search/SpectrumSearch';
import SpectrumSelectField from '../../components/Spectrum/Select/SpectrumSelect';

const ClientFilters = () => {
  const dispatch: StoreDispatch = useDispatch();
  const { filters } = useSelector((state: RootState) => state.clientData);
  const { userStatusList } = useSelector((state: RootState) => state.userData);
  const [filterValues, setFilterValues] = useState(filters);

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    const { name } = event.target;
    const value: any = event.target.value;
    if (name === 'status') {
      setFilterValues({ ...filterValues, [name]: value, start: 0 });
      dispatch(updateFilters({ ...filterValues, [name]: value, start: 0 }));
      return;
    }
    setFilterValues((prevFilterValues) => ({ ...prevFilterValues, [name]: value, start: 0 }));
  };

  const handleKeyDown = (event: KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter') {
      dispatch(updateFilters(filterValues));
    }
  };

  useEffect(() => {
    setFilterValues(filters);
  }, [filters]);

  return (
    <div>
      <Grid container spacing={4}>
        <Grid item laptop={9}>
          <SpectrumSearch
            placeholder='Search...'
            sx={{ width: '100%' }}
            size='small'
            name='searchValue'
            value={filterValues.searchValue}
            onChange={handleChange}
            onKeyDown={handleKeyDown}
          />
        </Grid>
        <Grid item laptop={3}>
          <SpectrumSelectField
            variant='outlined'
            size='small'
            name='status'
            value={filterValues.status}
            onChange={handleChange}
          >
            <MenuItem value={0}>All</MenuItem>
            {userStatusList.map((status: any) => (
              <MenuItem key={status.id} value={status.id}>
                {insertSpacesBeforeCapitals(status?.name)}
              </MenuItem>
            ))}
          </SpectrumSelectField>
        </Grid>
      </Grid>
    </div>
  );
};

export default ClientFilters;
